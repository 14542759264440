package com.diyoffer.negotiation.common

import com.diyoffer.negotiation.model.*

actual fun formatCurrency(money: Money?, currency: Currency, locale: String): String =
  jsFormatCurrency(money?.toBigNum(currency)?.toString(), currency.name, locale)

@Suppress("UNUSED_PARAMETER", "MaxLineLength")
private fun jsFormatCurrency(number: String?, currency: String, locale: String): String {
  if (number == null) return ""
  return js(
    "new Intl.NumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)"
  ) as String
}
