package com.diyoffer.negotiation.model

import kotlinx.serialization.Serializable

@Suppress("MaxLineLength")
@Serializable
data class Address(
  val suiteNumber: String? = null,
  val streetNumber: String,
  val street: String,
  val city: String,
  val provinceState: ProvinceState,
  val postalCodeZip: String,
  val country: Country = provinceState.country,
) {
  fun shortestSummary() = "${if (suiteNumber != null) "$suiteNumber - " else ""}$streetNumber $street"
  fun shortSummary() = "${if (suiteNumber != null) "$suiteNumber - " else ""}$streetNumber $street, $city"
  fun standardSummary() = "${if (suiteNumber != null) "$suiteNumber - " else ""}$streetNumber $street, $city, $provinceState, ${country.label}"
  fun longSummary() = "${if (suiteNumber != null) "$suiteNumber - " else ""}$streetNumber $street, $city, $provinceState, ${country.label}, $postalCodeZip"

  fun jurisdiction() = Jurisdiction(country, provinceState)
}

enum class Country(
  val label: String,
  val currency: Currency,
  val provinceStateLabel: String,
  val postalZipLabel: String,
) {
  CA("Canada", Currency.CAD, "Province", "Postal Code"),
  US("United States", Currency.USD, "State", "Zip Code"),
  ;

  companion object {
    fun default() = US
  }

  override fun toString() = this.label
}

enum class ProvinceState(val provinceState: String, val country: Country) {
  AB("Alberta", Country.CA),
  BC("British Columbia", Country.CA),
  MB("Manitoba", Country.CA),
  NB("New Brunswick", Country.CA),
  NL("Newfoundland and Labrador", Country.CA),
  NT("Northwest Territories", Country.CA),
  NS("Nova Scotia", Country.CA),
  NU("Nunavut", Country.CA),
  ON("Ontario", Country.CA),
  PE("Prince Edward Island", Country.CA),
  QC("Quebec", Country.CA),
  SK("Saskatchewan", Country.CA),
  YT("Yukon", Country.CA),

  // United States
  AL("Alabama", Country.US),
  AK("Alaska", Country.US),
  AZ("Arizona", Country.US),
  AR("Arkansas", Country.US),
  CA("California", Country.US),
  CO("Colorado", Country.US),
  CT("Connecticut", Country.US),
  DE("Delaware", Country.US),
  FL("Florida", Country.US),
  GA("Georgia", Country.US),
  HI("Hawaii", Country.US),
  ID("Idaho", Country.US),
  IL("Illinois", Country.US),
  IN("Indiana", Country.US),
  IA("Iowa", Country.US),
  KS("Kansas", Country.US),
  KY("Kentucky", Country.US),
  LA("Louisiana", Country.US),
  ME("Maine", Country.US),
  MD("Maryland", Country.US),
  MA("Massachusetts", Country.US),
  MI("Michigan", Country.US),
  MN("Minnesota", Country.US),
  MS("Mississippi", Country.US),
  MO("Missouri", Country.US),
  MT("Montana", Country.US),
  NE("Nebraska", Country.US),
  NV("Nevada", Country.US),
  NH("New Hampshire", Country.US),
  NJ("New Jersey", Country.US),
  NM("New Mexico", Country.US),
  NY("New York", Country.US),
  NC("North Carolina", Country.US),
  ND("North Dakota", Country.US),
  OH("Ohio", Country.US),
  OK("Oklahoma", Country.US),
  OR("Oregon", Country.US),
  PA("Pennsylvania", Country.US),
  RI("Rhode Island", Country.US),
  SC("South Carolina", Country.US),
  SD("South Dakota", Country.US),
  TN("Tennessee", Country.US),
  TX("Texas", Country.US),
  UT("Utah", Country.US),
  VT("Vermont", Country.US),
  VA("Virginia", Country.US),
  WA("Washington", Country.US),
  WV("West Virginia", Country.US),
  WI("Wisconsin", Country.US),
  WY("Wyoming", Country.US),
}
