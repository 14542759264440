package forms

import androidx.compose.runtime.Composable
import builders.OfferBuyerAgentInformationBuilder
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatPercent
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.offer.ONE_HUNDRED
import common.FlatListContent
import common.InfoMessageInline
import common.PercentField
import common.RadioButton
import common.Row
import common.percentToFormattedText
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLSpanElement
import style.DiyStyleSheet.Colors.green

@Suppress("LongMethod")
@Composable
fun OfferBuyerAgentInformationEdit(
  offer: Offer,
  builder: OfferBuyerAgentInformationBuilder,
  setBuilder: (OfferBuyerAgentInformationBuilder) -> Unit,
) {
  val agentCommission = builder.agentCommissionOrEstimate()

  Row({ style { marginBottom(18.px) } }) {
    RadioButton({
      label = "Do you have your own agent, or are you an agent representing the buyer?"
      value = builder.ownAgent
      onModified = { setBuilder(builder.copy(ownAgent = it)) }
    })
  }

  if (builder.ownAgent == true) {
    Row {
      PercentField(opts = {
        label = "Buyer's Agent Asking"
        value = builder.agentCommissionPercent
        onModified = { setBuilder(builder.copy(agentCommissionPercent = it)) }
      }, trailingIcon = {
          offer.price?.price?.currentValue?.getOrNull()?.let { price ->
            Span({ style { width(400.px) } }) {
              Span({ style { color(green) } }) {
                Text(
                  formatCurrency(
                    price * agentCommission.commission.value / ONE_HUNDRED,
                    offer.currency
                  )
                )
              }
              Span { Text(" Commission Fees${if (agentCommission.estimate) " (Est)" else ""}") }
            }
          }
        })
      InfoMessageInline {
        Text(
          "Please leave blank if you do not know the %. We will automatically fill in the standard " + "${
          formatPercent(builder.jurisdiction.defaultCommission())
          }."
        )
      }
    }

    Row({ style { marginBottom(14.px) } }) {
      RadioButton({
        value = builder.userCertifiedCommissionOverflowResponsibility
        onModified = { setBuilder(builder.copy(userCertifiedCommissionOverflowResponsibility = it)) }
      }) {
        Span { Text("You acknowledge that if the fees due to an agent are greater than ") }
        Span({ style { color(green) } }) { Text(percentToFormattedText(agentCommission.commission)) }
        Span {
          Text(
            " then you will be responsible for these fees. In addition, if the fees are " +
              "less than anticipated, the benefit of the lower fees paid accrues to the paying party (seller)."
          )
        }
      }
    }
  }
}

@Composable
fun OfferBuyerAgentInformationDisplay(item: OfferBuyerAgent) {
  val displayItems = mutableListOf<ContentBuilder<HTMLSpanElement>>({
    CheckOrXMarkText(if (item.ownAgent.bool()) "I have an agent" else "I don't have an agent", item.ownAgent.bool())
  })
  if (item.ownAgent.bool()) {
    item.commission.valueOrNull()?.let { agentCommission ->
      displayItems.add {
        Text(
          "${if (agentCommission.estimate) "Estimated " else ""}Agent's Commission: ${formatPercent(
            agentCommission.commission
          )}"
        )
      }
      displayItems.add {
        CheckOrXMarkText(
          "Acknowledge responsibility of excess fees",
          item.userCertifiedCommissionOverflowResponsibility.bool()
        )
      }
    }
  }
  FlatListContent(displayItems)
}
