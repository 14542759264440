package common

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Img
import org.w3c.dom.HTMLImageElement

@Composable
fun Icon(
  iconName: IconName,
  attrs: AttrBuilderContext<HTMLImageElement>? = null,
) {
  val iconUrl = "images/" + iconName.name.lowercase().replace("_", "-") + ".svg"
  Img(iconUrl) {
    attrs?.invoke(this)
  }
}

enum class IconName {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  BULLET_POINT_BLUE,
  CALENDAR_ICON,
  CANCEL,
  CHECK_CIRCLE_OUTLINE,
  CHECK_LIST,
  CHECK_MARK,
  CHECKED_CIRCLE,
  CIRCLE_GREY,
  CLOCK_GREY,
  CLOCK_YELLOW,
  DOCUMENT,
  DOUBLE_ARROW,
  DOUBLE_RIGHT_ARROW_GREEN,
  DOUBLE_RIGHT_ARROW_RED,
  EQUAL_CIRCLE,
  FACEBOOK_LOGO,
  GAVEL,
  GOOGLE_LOGO,
  HELP,
  HELP_DARK_BLUE,
  INFO,
  LINK,
  LINK_CIRCLE,
  LOCATION_BLUE,
  LOCATION_LIGHT_BLUE,
  LOCATION_YELLOW,
  LOGO,
  MINUS_CIRCLE,
  PERSON,
  REMOVE,
  THUMB_UP,
  TRENDING_DOWN,
  TRENDING_UP,
  UNCHECK_CIRCLE_OUTLINE,
  BELL_RED,
  VERIFY,
  NOT_VERIFY,
}
