@file:Suppress("MissingPackageDeclaration")

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.softwork.routingcompose.BrowserRouter
import app.softwork.routingcompose.Router
import app.softwork.routingcompose.navigate
import auth.authInterceptorModule
import co.touchlab.kermit.Logger
import co.touchlab.kermit.Severity
import com.diyoffer.negotiation.BuildConfig
import com.diyoffer.negotiation.clientJsModule
import com.diyoffer.negotiation.clientModule
import com.diyoffer.negotiation.common.JsJodaTimeZoneModule
import com.diyoffer.negotiation.environment.Env
import com.diyoffer.negotiation.environment.environment
import com.diyoffer.negotiation.environment.environmentModule
import com.diyoffer.negotiation.frontend.frontendSharedModule
import com.diyoffer.negotiation.model.*
import common.Overlay
import components.snackbar.UpdateCheckSnackbar
import environment.jsEnvironmentModule
import forms.auth.ResetPassword
import forms.auth.SignIn
import forms.auth.SignOut
import forms.auth.SignUp
import kotlinx.browser.window
import layout.LoginContainer
import model.RoutingParams
import model.nextActionParam
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.renderComposable
import org.kodein.di.DI
import org.kodein.di.compose.withDI
import pages.BuyerChecklistPage
import pages.HomePage
import pages.ListingCreatePage
import pages.ListingDetailsPage
import pages.ListingLandingPage
import pages.OfferDetailsPage
import pages.Unauthorized
import services.servicesModule
import style.DiyStyleSheet
import style.GridStyleSheet

external fun require(o: String)

fun main() {
  JsJodaTimeZoneModule
  renderComposable("root") {
    App()
  }
}

val di = DI {
  import(jsEnvironmentModule)
  import(environmentModule)
  import(frontendSharedModule)
  import(clientModule)
  import(clientJsModule)
  import(authInterceptorModule)
  import(servicesModule)

  onReady {
    Logger.i { "Running in environment $environment with release ${BuildConfig.GIT_VERSION}" }
    if (environment == Env.PROD) {
      Logger.setMinSeverity(Severity.Warn)
    }
  }
}

@Suppress("LongMethod")
@Composable
fun App() = withDI(di) {
  require("./css/textfield-theme.scss")

  Style(DiyStyleSheet)
  Style(GridStyleSheet)

  UpdateCheckSnackbar()

  Overlay()

  BrowserRouter(initPath = "/home") {
    val router = Router.current
    UserContext { userVm ->
      val userState by userVm.observeStates().collectAsState()

      route("/auth") {
        LoginContainer {
          route("/sign-in") {
            SignIn(parameters?.map?.get(RoutingParams.ParamKeys.nextAction)?.getOrNull(0))
          }

          route("/reset-password") {
            ResetPassword()
          }

          route("/sign-up") {
            SignUp(parameters?.map?.get(RoutingParams.ParamKeys.nextAction)?.getOrNull(0))
          }

          route("/sign-out") {
            SignOut(userVm)
          }

          noMatch {
            LaunchedEffect(Unit) {
              router.navigate("/auth/sign-in")
            }
          }
        }
      }

      route("/home") {
        HomePage(userVm, userState.user)
      }

      route("/dev") {
        TestPage()
      }

      route("/listing") {
        route("create") {
          when (userState.user) {
            is SessionUser.NotLoaded -> Unit
            is SessionUser.AnonUser,
            is SessionUser.SignedUser,
            ->
              router.navigate("/auth/sign-up", nextActionParam("/listing/create"), hide = true)
            is SessionUser.AuthUser -> ListingCreatePage()
          }
        }
        route("edit") {
          uuid {
            when (userState.user) {
              is SessionUser.NotLoaded -> Unit
              is SessionUser.AnonUser,
              is SessionUser.SignedUser,
              -> Unauthorized(userVm, userState.user)
              is SessionUser.AuthUser -> {
                val backRoute = parameters?.map?.get(RoutingParams.ParamKeys.backRoute)?.getOrNull(0)
                ListingDetailsPage(userVm, it.toString().toUid(), userState.user, backRoute)
              }
            }
          }
          noMatch { Unauthorized(userVm, userState.user) }
        }
        string {
          val backRoute = parameters?.map?.get(RoutingParams.ParamKeys.backRoute)?.getOrNull(0)
          ListingLandingPage("${window.location.origin}/listing/$it", userVm, userState.user, backRoute)
        }
      }

      route("/offer") {
        route("edit") {
          uuid {
            val backRoute = parameters?.map?.get(RoutingParams.ParamKeys.backRoute)?.getOrNull(0)
            OfferDetailsPage(userVm, it.toString().toUid(), userState.user, backRoute)
          }
          noMatch { Unauthorized(userVm, userState.user) }
        }
        route("checklist") {
          uuid {
            when (userState.user) {
              is SessionUser.NotLoaded -> Unit
              is SessionUser.AnonUser -> Unauthorized(userVm, userState.user)
              is SessionUser.SignedUser,
              is SessionUser.AuthUser,
              -> BuyerChecklistPage(it.toString().toUid())
            }
          }
          noMatch { Unauthorized(userVm, userState.user) }
        }
        // Signed URL from backend - see linkService.createSignature
        uuid { offerUid ->
          string { random ->
            route("/signed") {
              string { signature ->
                // Redirect to canonical representation with parameters. Use a redirect to reinitialize the state
                window.location.replace("/offer/edit/$offerUid?signature=$signature&random=$random")
              }
            }
          }
        }
      }

      noMatch {
        LaunchedEffect(Unit) {
          router.navigate("/home")
        }
      }
    }
  }
}
