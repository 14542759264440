package com.diyoffer.negotiation.messages

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*

object CommonMessages {
  const val contactAdministrator = "Please try again, and contact support if the error persists."

  fun contactAdministrator(e: Exception?) = when (e) {
    is UnexpectedErrorServiceException -> contactAdministrator(e.errorId)
    else -> contactAdministrator
  }

  fun contactAdministrator(errorId: String) =
    "Please try again, and if the error persists, contact support with error id $errorId."

  const val clickCardToAddMore = "Click on the card to add one."

  fun offerRejectExplanation(party: Party): String {
    val counterParty = when (party) {
      Party.BUYER -> "seller"
      Party.SELLER -> "buyer"
    }

    return "This action rejects the $counterParty's counter-offer and ends the negotiation process. The " +
      "$counterParty will be notified you are no longer interested in moving forward. Another option is to counter " +
      "the offer instead. To do this, select review, counter the desired offer terms, and submit."
  }

  fun offerAcceptExplanation(party: Party): String {
    val counterParty = when (party) {
      Party.BUYER -> "seller"
      Party.SELLER -> "buyer"
    }

    return "This action accepts the $counterParty's offer terms. Congratulations! After accepting, the system will " +
      "walk you through the next steps of obtaining legal sign-off and having both parties sign a binding offer."
  }

  val offerCancelExplanation = Asciidoc(
    "This action informs DIY that binding agreement could not be reached on the offer. " +
      "The reason entered here will be shared by all involved parties, including the buyer and buyer's lawyer. We highly " +
      "recommend the buyer and buyer's lawyer already be aware of this situation and agree that binding agreement cannot " +
      "be reached, otherwise you may be risking legal action.\n" +
      "\n" +
      "Proceeding will unlock the listing, and you will be free to accept other offers."
  )

  val offerCompleteExplanation = Asciidoc(
    "Congratulations! This actions will complete the process and close the listing."
  )
}

enum class MessageKey(val message: String, val key: String) {
  NONE("", "n"),
  MUST_SIGN_IN_OFFER("You must sign in to access this offer.", "o"),
  ;

  companion object {
    fun fromKey(key: String?): MessageKey = values().find { it.key == key } ?: NONE
  }
}
