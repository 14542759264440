package layout

import androidx.compose.runtime.Composable
import common.Icon
import common.IconName
import org.jetbrains.compose.web.css.flexShrink
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet

@Composable
fun Header(content: ContentBuilder<HTMLDivElement>) {
  Div({
    id("diy-top-menu")
    classes(DiyStyleSheet.headerContainer)
  }) {
    Div({
      classes(DiyStyleSheet.headerMenu)
    }) {
      Icon(IconName.LOGO, attrs = {
        style {
          height(39.px)
        }
      })
      Div(attrs = { style { flexShrink(0) } }, content = content)
    }
  }
}
