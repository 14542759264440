package common

import androidx.compose.runtime.Composable
import core.Builder
import dev.petuska.kmdc.core.MDCContent
import dev.petuska.kmdc.textfield.MDCTextArea
import dev.petuska.kmdc.textfield.MDCTextField
import dev.petuska.kmdc.textfield.MDCTextFieldScope
import dev.petuska.kmdc.textfield.MDCTextFieldType
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.padding
import style.DiyStyleSheet.Sizes.paddingXs
import style.DiyStyleSheet.button
import style.DiyStyleSheet.onLightBlue
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.flex
import style.GridStyleSheet.justifyContentEnd

const val TEXT_AREA_ROW: UInt = 3u

@Composable
fun TextField(
  opts: Builder<InputOptions<String>>? = null,
  attrs: AttrBuilderContext<HTMLInputElement>? = null,
  containerAttr: AttrBuilderContext<HTMLDivElement>? = null,
  trailingIcon: MDCContent<MDCTextFieldScope>? = null,
) {
  val options = InputOptions<String>().apply { opts?.invoke(this) }
  Div(attrs = {
    style { flexGrow(1) }
    options.error?.let { classes("error") }
    containerAttr?.invoke(this)
  }) {
    MDCTextField(
      value = options.value ?: "",
      label = options.label,
      type = MDCTextFieldType.Outlined,
      disabled = options.disabled,
      attrs = {
        style {
          width(100.percent)
        }
        onInput { options.onModified(it.value) }
        if (options.disabled) disabled()
        attrs?.invoke(this)
      },
      trailingIcon = trailingIcon
    )
    options.error?.let {
      ValidationMessage(it) {
        style {
          padding(0.pt)
          margin(0.pt)
          marginTop(paddingXs)
        }
      }
    }
  }
}

@Composable
fun TextArea(
  opts: Builder<InputOptions<String>>? = null,
  attrs: AttrBuilderContext<HTMLTextAreaElement>? = null,
  rowSpan: UInt = TEXT_AREA_ROW,
) {
  val options = InputOptions<String>().apply { opts?.invoke(this) }
  MDCTextArea(
    options.value ?: "",
    label = options.label,
    type = MDCTextFieldType.Outlined,
    rows = rowSpan,
    attrs = {
      style {
        width(100.percent)
      }
      onInput { options.onModified(it.value) }
      attrs?.invoke(this)
    }
  )
}

@Composable
fun MultiTextField(
  opts: Builder<InputOptions<List<String>>>? = null,
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val options = InputOptions<List<String>>().apply { opts?.invoke(this) }
  FlexColumn(attrs) {
    options.value?.mapIndexed { idx, value ->
      FlexRow({
        style { marginBottom(padding) }
      }) {
        Div({
          style { flexGrow(1) }
        }) {
          TextInput(value = value) {
            classes("input-custom")
            onInput {
              val currentInputs = (options.value ?: listOf()).toMutableList()
              if (it.target.value.isBlank() && currentInputs.count() > 1) {
                currentInputs.removeAt(idx)
              } else if (it != undefined) {
                currentInputs[idx] = it.target.value
              }
              options.onModified(currentInputs)
            }
          }
        }
        Icon(IconName.REMOVE) {
          style { cursor("pointer") }
          onClick {
            val currentInputs = (options.value ?: listOf()).toMutableList()
            currentInputs.removeAt(idx)
            options.onModified(currentInputs)
          }
        }
      }
    }
    Button({
      classes(onLightBlue, button)
      style {
        width(125.px)
      }
      onClick {
        options.onModified((options.value ?: listOf()) + listOf(""))
      }
    }) {
      Text("+ Add more")
    }
  }
}

@Composable
fun TextFieldPassword(
  opts: Builder<InputOptions<String>>? = null,
  attrs: AttrBuilderContext<HTMLInputElement>? = null,
) {
  val options = InputOptions<String>().apply { opts?.invoke(this) }
  MDCTextField(
    value = options.value ?: "",
    label = options.label,
    type = MDCTextFieldType.Outlined,
    disabled = options.disabled,
    attrs = {
      style {
        width(100.percent)
      }
      onInput { options.onModified(it.value) }
      onKeyUp { if (it.key == "Enter") options.onKeyEnter((it.target as HTMLInputElement).value) }
      type(InputType.Password)
      attrs?.invoke(this)
    }
  )
}

@Composable
fun VerifyTextField(
  opts: Builder<InputOptions<String>>? = null,
  verified: Boolean = false,
  attrs: AttrBuilderContext<HTMLInputElement>? = null,
) {
  val options = InputOptions<String>().apply { opts?.invoke(this) }
  Div({ classes("verify-input") }) {
    MDCTextField(
      value = options.value ?: "",
      label = options.label,
      type = MDCTextFieldType.Outlined,
      disabled = options.disabled || verified, // Do not allow to change a verified contact
      attrs = {
        style {
          width(100.percent)
        }
        onInput { options.onModified(it.value) }
        attrs?.invoke(this)
      },
      trailingIcon = {
        Div({
          classes(flex, justifyContentEnd, alignItemsCenter)
          style {
            width(150.px)
            paddingRight(10.px)
          }
        }) {
          if (verified) {
            Icon(IconName.VERIFY)
            Span({
              style {
                color(DiyStyleSheet.Colors.green)
                marginLeft(5.px)
              }
            }) {
              Text("Verified")
            }
          } else {
            Icon(IconName.NOT_VERIFY)
            Span({
              style {
                color(DiyStyleSheet.Colors.red)
                marginLeft(5.px)
              }
            }) {
              Text("Not verified")
            }
          }
        }
      }
    )
  }
}
