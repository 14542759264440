package components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import builders.NegotiatedTermBuilder
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatDate
import com.diyoffer.negotiation.common.formatDateTime
import com.diyoffer.negotiation.common.toHumanCount
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.repository.user.UserRepository
import common.FlexRow
import common.GroupedRadioButton
import common.Icon
import common.IconName
import common.InlineWithLeadingIcon
import core.EditContent
import dev.petuska.kmdc.dialog.Content
import dev.petuska.kmdc.dialog.Header
import dev.petuska.kmdc.dialog.MDCDialog
import dev.petuska.kmdc.dialog.onClosed
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.css.Color.orange
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textDecoration
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.black
import style.DiyStyleSheet.Colors.green
import style.DiyStyleSheet.Colors.grey
import style.DiyStyleSheet.Colors.red
import style.DiyStyleSheet.Colors.yellow
import style.DiyStyleSheet.Sizes.paddingSm
import style.DiyStyleSheet.fontSize12
import style.DiyStyleSheet.fontSize16
import style.DiyStyleSheet.greyText
import style.GridStyleSheet
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.col1
import style.GridStyleSheet.col3
import style.GridStyleSheet.col4
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.GridStyleSheet.justifyContentBetween

/**
 * This displays the negotiated section with the Accept, Reject, Counter subject to options provided.
 * For the user to counter, you need a form to allow him to input new data which is provided throught the
 * counterView. The counterView builds the element T which will be assigned to the NegociatedTerm.CurrentValue
 * upon success.
 */
@Composable
fun <T : Any> NegotiatedTermEdit(
  builder: NegotiatedTermBuilder<T>,
  setBuilder: (NegotiatedTermBuilder<T>) -> Unit,
  counterView: EditContent<T>? = null,
) {
  val (open, setOpen) = remember { mutableStateOf(false) }

  val userRepo by rememberInstance<UserRepository>()
  val user by userRepo.getUser().collectAsState()
  val baselineState = builder.getBaselineState()

  GroupedRadioButton<NegotiatedTerm.State>(opts = {
    value = builder.state
    items = NEGOTIATED_TERM_STATES.filter { uiState ->
      // Return the list of states that are allowed between the current state and next state using the state model
      builder.stateValidation(builder.negotiationStage).any {
        it.first == baselineState && it.second == uiState.key
      }
    }.mapValues { (key, value) ->
      // In the case of rejecting the rejection, we relabel the action for more clarity. However, only the
      // original author can "refuse to remove"
      if (
        key == NegotiatedTerm.State.REJECTED &&
        baselineState == NegotiatedTerm.State.REJECTED &&
        builder.party == builder.term.changeHistory.first().party
      ) {
        "Refuse to remove"
      } else {
        value
      }
    }
    selectedColors = { it.color() }
    onModified = { setBuilder(builder.copy(state = it)) }
  }) {
    classes(flex, GridStyleSheet.alignItemsStart)
  }
  Div {
    FlexRow {
      Text(
        "counter".toHumanCount(
          (builder.term.changeHistory.count { it.state == NegotiatedTerm.State.COUNTERED })
        )
      )
      A(attrs = {
        onClick { setOpen(!open) }
      }) {
        Text("[Change History]")
      }
      CounterHistoryView(builder, open, user.timeZone(), setOpen)
    }
  }
  if (builder.state == NegotiatedTerm.State.COUNTERED && builder.counterValueBuilder != null) {
    counterView?.let {
      Div {
        it(this, builder.counterValueBuilder!!) { setBuilder(builder.copy(counterValueBuilder = it)) }
      }
    }
  }
}

val NEGOTIATED_TERM_STATES = mapOf(
  NegotiatedTerm.State.ACCEPTED to "Accepted",
  NegotiatedTerm.State.COUNTERED to "Counter",
  NegotiatedTerm.State.REJECTED to "Reject",
  NegotiatedTerm.State.REMOVED to "Remove",
)

fun NegotiatedTerm.State.color() = when (this) {
  NegotiatedTerm.State.ACCEPTED -> green
  NegotiatedTerm.State.COUNTERED -> orange
  NegotiatedTerm.State.REJECTED -> red
  NegotiatedTerm.State.NEW -> yellow
  NegotiatedTerm.State.REMOVED -> black
}

fun NegotiatedTerm.State.icon() = when (this) {
  NegotiatedTerm.State.NEW -> MDCIcon.ErrorOutline
  NegotiatedTerm.State.ACCEPTED -> MDCIcon.ThumbUp
  NegotiatedTerm.State.REJECTED -> MDCIcon.ThumbDown
  NegotiatedTerm.State.COUNTERED -> MDCIcon.Repeat
  NegotiatedTerm.State.REMOVED -> MDCIcon.DeleteForever
}

@Composable
fun NegotiatedTermDisplay(title: String, state: NegotiatedTerm.State) {
  InlineWithLeadingIcon(
    icon = state.icon(),
    iconColor = state.color()
  ) {
    Span({
      classes(greyText)
      if (state == NegotiatedTerm.State.REMOVED) style { textDecoration("line-through") }
    }) {
      Text(title)
    }
  }
}

@Suppress("LongMethod", "ComplexMethod")
@Composable
private fun <T : Any> CounterHistoryView(
  builder: NegotiatedTermBuilder<T>,
  open: Boolean,
  timeZone: TimeZone,
  setOpen: (Boolean) -> Unit,
) {
  MDCDialog(
    open = open,
    attrs = {
      id("counter-history-dialog")
      onClosed {
        // only if the user closed it via scrim or esc key, otherwise we already did setOpen(false)
        if (it.detail.action == "close") {
          setOpen(false)
        }
      }
    }
  ) {
    Div({
      classes(flex, flexColumn)
      style { width(600.px) }
    }) {
      Header {
        DialogHeader(builder.term.changeHistory.size - 1, setOpen)
      }
      Content {
        builder.term.changeHistory.mapIndexed { index, item ->
          val myParty = item.party == builder.party
          val partyStr = if (myParty) "YOU" else item.party
          Div({
            classes(flex, alignItemsCenter, fontSize12)
            style {
              padding(0.px, 10.px)
              height(44.px)
              backgroundColor(if (myParty) DiyStyleSheet.Colors.lightDark else DiyStyleSheet.Colors.white)
            }
          }) {
            Div({ classes(col1) }) {
              MDCIconSpan(
                icon = MDCIcon.DoubleArrow
              ) {
                style { color(item.state.color()) }
              }
            }
            Div({ classes(col4) }) {
              Span({ style { color(DiyStyleSheet.Colors.darkGrey) } }) {
                when {
                  index == 0 && item.party == Party.SELLER -> Text("$partyStr created the listing")
                  index == 0 && item.party == Party.BUYER -> Text("$partyStr added this condition")
                  item.state == NegotiatedTerm.State.COUNTERED -> Text("$partyStr countered")
                  item.state == NegotiatedTerm.State.REJECTED -> Text("$partyStr rejected")
                  item.state == NegotiatedTerm.State.ACCEPTED -> Text("$partyStr accepted")
                  item.state == NegotiatedTerm.State.REMOVED -> Span {
                    Text("$partyStr ")
                    Span({ style { textDecoration("line-through") } }) { Text("removed") }
                    Text(" this item")
                  }

                  else -> Text("$partyStr ${item.state}")
                }
              }
            }
            Div({ classes(col3) }) {
              Span({
                style {
                  color(black)
                  fontWeight(DiyStyleSheet.Weights.darkNormal)
                }
              }) {
                Text(
                  when (val value = item.value.core.value.get()) {
                    is Money -> "${formatCurrency(value, builder.currency)} ${builder.currency.name}"
                    is LocalDate -> formatDate(value)
                    is LocalDateTime -> formatDateTime(value)
                    else -> "" // Need a more generic way of display the objects
                  }
                )
              }
            }
            Div({ classes(col4) }) {
              Span({ style { color(grey) } }) {
                val localDate = item.value.core.timestamp.instant.toLocalDateTime(timeZone)
                Text(formatDateTime(localDate))
              }
            }
          }
        }
      }
    }
  }
}

@Composable
private fun DialogHeader(
  size: Int,
  setOpen: (Boolean) -> Unit,
) {
  Div({
    classes(flex, justifyContentBetween, alignItemsCenter)
    style {
      padding(0.px, 24.px)
    }
  }) {
    Span({
      classes(fontSize16)
      style {
        fontWeight(DiyStyleSheet.Weights.darkNormal)
        color(black)
        marginTop(paddingSm)
        marginBottom(
          paddingSm
        )
      }
    }) {
      Text("Counter History\t\t")
      Span({
        style {
          fontWeight(DiyStyleSheet.Weights.normal)
          color(grey)
        }
      }) {
        Text("counter".toHumanCount(size))
      }
    }
    Icon(IconName.REMOVE) {
      classes("pointer")
      onClick { setOpen(false) }
    }
  }
}
