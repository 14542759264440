@file:Suppress("MissingPackageDeclaration")

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.messages.InfoPopup
import com.diyoffer.negotiation.ui.user.UserContract.Inputs
import com.diyoffer.negotiation.ui.user.UserEventHandler
import components.AsciidocContent
import kotlinx.browser.window
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.ElementScope
import org.kodein.di.compose.rememberFactory
import org.w3c.dom.HTMLDivElement
import vm.login.UserViewModel
import vm.login.UserVmConfig
import vm.login.UserVmConfigParams

// Through the user contract, initialize the user repo so user state is accessible throughout the session
@Composable
fun UserContext(content: @Composable (ElementScope<HTMLDivElement>.(UserViewModel) -> Unit)) {
  val scope = rememberCoroutineScope()
  val router = Router.current
  val vmConfigFactory by rememberFactory<UserVmConfigParams, UserVmConfig>()
  val vm = remember(scope) {
    UserViewModel(
      config = vmConfigFactory(
        UserVmConfigParams(
          initialInputs = { Inputs.InitializeUser(window.location.href) }
        )
      ),
      eventHandler = UserEventHandler(
        onSignOut = { router.navigate("/home") },
        onUserLoaded = { _, url ->
          if (url != null) {
            router.navigate(url)
          }
        }
      ),
      viewModelCoroutineScope = scope
    )
  }
  val state by vm.observeStates().collectAsState()

  Div {
    content(this, vm)
  }

  // User dialog
  common.Dialog(
    open = state.popup != null && state.popup != InfoPopup.NONE,
    title = state.popup?.title ?: "",
    headerImg = state.popup?.img,
    actionLabel = state.popup?.actionLabel ?: "Close",
    onAction = { vm.trySend(Inputs.ClearPopup) },
    onClose = { vm.trySend(Inputs.ClearPopup) }
  ) {
    state.popup?.content?.let { AsciidocContent(it) }
  }
}
