package builders

import com.diyoffer.negotiation.common.POSTAL_CODE_VALIDATION_REGEX
import com.diyoffer.negotiation.common.ZIP_CODE_VALIDATION_REGEX
import com.diyoffer.negotiation.model.*

data class AddressBuilder(
  val suiteNumber: String? = null,
  val streetNumber: String? = null,
  val street: String? = null,
  val city: String? = null,
  val provinceState: ProvinceState? = ProvinceState.CA,
  val postalZipCode: String? = null,
  val country: Country = ProvinceState.CA.country,
) : IBuilder<Address> {

  companion object {
    fun create(item: Address?): IBuilder<Address> {
      return item?.let {
        AddressBuilder(
          suiteNumber = it.suiteNumber,
          streetNumber = it.streetNumber,
          street = it.street,
          city = it.city,
          provinceState = it.provinceState,
          postalZipCode = it.postalCodeZip,
          country = it.country
        )
      } ?: AddressBuilder()
    }
  }

  override fun hydrate(item: Address?) = create(item)

  override fun build(): BuildResult<Address> = validateAndBuild {
    Address(
      suiteNumber = suiteNumber,
      streetNumber = streetNumber!!,
      street = street!!,
      city = city!!,
      provinceState = provinceState!!,
      postalCodeZip = postalZipCode!!,
      country = country,
    )
  }

  private fun validateAndBuild(onValid: () -> Address): BuildResult<Address> {
    val errors = mutableListOf<String>()
    val warnings = mutableListOf<String>()

    if (streetNumber == null) errors.add("You must provide a street number.")
    if (street == null) errors.add("You must provide a street.")
    if (city == null) errors.add("You must provide a city.")
    if (provinceState == null) {
      errors.add("You must provide a province.")
    } else {
      if (provinceState.country != country) {
        errors.add("The province or state belongs to another country.")
      }
    }
    if (postalZipCode == null) {
      errors.add("You must provide a ${country.postalZipLabel}.")
    } else {
      if (country == Country.CA && !POSTAL_CODE_VALIDATION_REGEX.matches(postalZipCode)) {
        errors.add("The postal code is invalid")
      } else if (country == Country.US && !ZIP_CODE_VALIDATION_REGEX.matches(postalZipCode)) {
        errors.add("The zip code is invalid")
      }
    }

    return buildResult(errors, warnings, onValid = onValid)
  }
}
