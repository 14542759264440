package forms

import androidx.compose.runtime.Composable
import builders.ListingExternalLinksBuilder
import com.diyoffer.negotiation.common.LINK_VALIDATION_REGEX
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.FlexColumn
import common.FlexRow
import common.Icon
import common.IconName
import common.InputOptions
import common.MessageInline
import common.Row
import components.LinkDisplay
import core.Builder
import dev.petuska.kmdcx.icons.MDCIcon
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.DiyStyleSheet.greyText

@Composable
fun ListingExternalLinksEdit(
  builder: ListingExternalLinksBuilder,
  setBuilder: (ListingExternalLinksBuilder) -> Unit,
) {
  Row({ classes(greyText) }) {
    MessageInline(icon = MDCIcon.Link, twoToneIconColorFilters = DiyStyleSheet.TwoToneColorFilters.BLUE) {
      Text("If available, provide your listing web links (ie: Kijiji, Facebook, etc.)")
    }
  }
  MultiLinkField(opts = {
    label = ""
    value = builder.links ?: listOf()
    onModified = {
      setBuilder(builder.copy(links = it))
    }
  })
}

@Composable
fun MultiLinkField(
  opts: Builder<InputOptions<List<ListingLinks.Link>>>? = null,
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val options = InputOptions<List<ListingLinks.Link>>().apply { opts?.invoke(this) }
  FlexColumn(attrs) {
    options.value?.mapIndexed { idx, value ->
      FlexRow({
        style { marginBottom(DiyStyleSheet.Sizes.padding) }
      }) {
        Div({
          style { flexGrow(1) }
        }) {
          TextInput(value = value.url) {
            classes(if (LINK_VALIDATION_REGEX.matches(value.url)) "input-custom" else "input-custom-error")
            onInput {
              val currentInputs = (options.value ?: listOf()).toMutableList()
              currentInputs[idx] = value.copy(url = it.target.value)
              options.onModified(currentInputs)
            }
          }
        }
        Icon(IconName.REMOVE) {
          style { cursor("pointer") }
          onClick {
            val currentInputs = (options.value ?: listOf()).toMutableList()
            currentInputs.removeAt(idx)
            options.onModified(currentInputs)
          }
        }
      }
    }

    Button({
      classes(DiyStyleSheet.onLightBlue, DiyStyleSheet.button)
      style {
        width(125.px)
      }
      onClick {
        options.onModified(
          (options.value ?: listOf()) + listOf(ListingLinks.Link(ListingLinks.Link.ListingProvider.OTHER, ""))
        )
      }
    }) {
      Text(if (options.value.isNullOrEmpty()) "+ Add link" else "+ Add more")
    }
  }
}

@Suppress("SpreadOperator")
@Composable
fun ListingExternalLinksDisplay(items: ListingLinks) {
  if (items.links.isEmpty()) {
    FlatList("No link was provided.")
  } else {
    items.links.map {
      LinkDisplay(it.url.split("?")[0], it.url, clip = false, provider = it.provider)
    }
  }
}
