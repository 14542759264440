package com.diyoffer.negotiation.ui.offer

import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatDate
import com.diyoffer.negotiation.common.formatDateTime
import com.diyoffer.negotiation.common.services.TimeService.humanReadableRelativeTo
import com.diyoffer.negotiation.common.toHumanCount
import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

enum class OfferActionType {
  ACCEPT,
  REVIEW,
  REJECT,
  PUBLISH,
}

sealed class NegotiatedSummaryRow(
  val state: NegotiatedTerm.State,
) {
  abstract val label: String

  data class NewRow(
    override val label: String,
    val message: String,
  ) : NegotiatedSummaryRow(state = NegotiatedTerm.State.NEW)

  data class AcceptRow(
    override val label: String,
    val message: String,
  ) : NegotiatedSummaryRow(state = NegotiatedTerm.State.ACCEPTED)

  data class CounterRow(
    override val label: String,
    val currentValue: String,
    val prevValue: String?,
  ) : NegotiatedSummaryRow(state = NegotiatedTerm.State.COUNTERED)

  data class RejectRow(
    override val label: String,
    val itemName: String,
    val rejectCount: Int,
    val totalCount: Int,
  ) : NegotiatedSummaryRow(state = NegotiatedTerm.State.REJECTED)
}

fun Offer.statusSummary(forParty: Party, otherName: String, tz: TimeZone): String? {
  @Suppress("DEPRECATION")
  val lastEvent = events.lastOrNull { it !is OfferDraftSavedEvent && it !is OfferLegalAddedEvent } ?: return null
  val timeStrWithDot = formatDateTime(lastEvent.timestamp.instant.toLocalDateTime(tz)).trim('.') + "."

  fun partyLabel(byParty: Party) = if (byParty == forParty) "You" else otherName
  return when (lastEvent) {
    is OfferLostEvent -> "Seller selected another offer on $timeStrWithDot It is no longer available."
    is OfferCompletedEvent -> "The offer was completed on $timeStrWithDot"
    is OfferAcceptedEvent -> "${partyLabel(lastEvent.by)} accepted this offer on $timeStrWithDot See the checklist for next step."
    is OfferRejectedEvent -> "${partyLabel(lastEvent.by)} rejected this offer on $timeStrWithDot It is no longer available."
    is OfferCanceledEvent -> "The offer was canceled on $timeStrWithDot It is no longer available."
    is OfferExpiredEvent -> "This offer expired on $timeStrWithDot"
    is OfferCounteredEvent -> "${partyLabel(lastEvent.by)} countered on $timeStrWithDot"
    is OfferPublishedEvent -> "${partyLabel(lastEvent.by)} submitted offer on $timeStrWithDot"
    else -> null
  }
}

fun Offer.statusChip(withholdingTime: LocalDateTime?, clock: Clock, timeZone: TimeZone): Pair<String, TagColor> {
  fun OfferEvent.timeStr() = formatDateTime(timestamp.instant.toLocalDateTime(timeZone))
  return when {
    withholdingTime != null && withholdingTime > clock.now().toLocalDateTime(timeZone) ->
      "Withheld until ${formatDateTime(withholdingTime)}" to TagColor.YELLOW
    state == Offer.State.LOST -> "Lost on ${events.last { it is OfferLostEvent }.timeStr()}" to TagColor.RED
    state == Offer.State.EXPIRED -> "Expired on ${events.last { it is OfferExpiredEvent }.timeStr()}" to TagColor.RED
    state == Offer.State.REJECTED -> "Rejected on ${events.last { it is OfferRejectedEvent }.timeStr()}" to TagColor.RED
    state == Offer.State.ACCEPTED -> "Accepted on ${events.last { it is OfferAcceptedEvent }.timeStr()}" to TagColor.LIGHT_GREEN
    state == Offer.State.COMPLETED -> "Completed on ${events.last { it is OfferCompletedEvent }.timeStr()}" to TagColor.GREEN
    state in Offer.State.statesThatCanExpire && expiry?.instant != null ->
      "${expiry!!.instant!!.humanReadableRelativeTo(clock.now(), "Expires in", "Expired")} " +
        "(${formatDateTime(expiry!!.instant!!.instant.toLocalDateTime(timeZone))})" to TagColor.YELLOW
    else -> "Last updated on ${events.last().timeStr()}" to TagColor.LIGHT_GREEN
  }
}

val Offer.isOfferActionDisabled get() = state in Offer.State.statesNoActionAllowed

fun Offer.hasPen(currentParty: Party) =
  state == Offer.State.DRAFT && currentParty == authoredBy ||
    state == Offer.State.PUBLISHED && currentParty != authoredBy ||
    state.isAccepted && currentParty == Party.SELLER

fun OfferContacts.legalContact(party: Party) = when (party) {
  Party.BUYER -> buyerLegal?.name to buyerLegal?.firstEmailOrNull()
  Party.SELLER -> sellerLegal?.name to sellerLegal?.firstEmailOrNull()
}

// This is a useful summary, should consider displaying it on the seller UI too
fun Offer.counterRejectList(): List<NegotiatedSummaryRow> {
  val moneyFormatter: (Money) -> String = { formatCurrency(it, currency) }
  val dateFormatter: (LocalDate) -> String = { formatDate(it) }
  val dayFormatter: (Days) -> String = { "day".toHumanCount(it.value) }

  return listOfNotNull(
    price?.price?.toNegotiatedRow("Offer Price", moneyFormatter),
    price?.deposit?.toNegotiatedRow("Deposit", moneyFormatter),
    closing?.date?.toNegotiatedRow("Closing Date", dateFormatter),
    assumableContracts?.contracts?.toNegotiatedRow("Assumable Contracts", "contract"),
    fixturesExcluded?.fixturesExcluded?.toNegotiatedRow("Fixtures/Exclusions", "exclusion"),
    chattelsIncluded?.chattelsIncluded?.toNegotiatedRow("Chattel/Inclusions", "inclusion"),
    sellerConditions?.conditions?.toNegotiatedRow("Seller Conditions", "condition"),
    buyerConditions?.conditions?.toNegotiatedRow("Buyer Conditions", "condition"),
    additionalRequest?.additionalRequests?.toNegotiatedRow("Buyer's Request", "request"),
    bindingAgreementTerms?.days?.toNegotiatedRow("Binding Agreement Required Days", dayFormatter)
  ).filter {
    it.state in listOf(
      NegotiatedTerm.State.NEW,
      NegotiatedTerm.State.COUNTERED,
      NegotiatedTerm.State.REJECTED,
      NegotiatedTerm.State.REMOVED
    )
  }
}

const val HST: Double = 0.13
const val ONE_HUNDRED = 100.0

data class Summary(
  val offerAmount: Money,
  val buyerCommissionFee: Money,
  val hst: Money,
  val subTotal: Money,
  val effectiveOfferPrice: Money,
)

fun Offer.effectivePrice(jurisdiction: Jurisdiction?): Money {
  val commission = buyerAgent?.commission?.valueOrNull()?.commission?.value?.let { it / ONE_HUNDRED } ?: 0.0
  val price = price?.price?.currentValue?.getOrNull() ?: return Money.ZERO
  return calculateSummaryByOfferAmount(commission, price, jurisdiction).effectiveOfferPrice
}

fun calculateSummaryByOfferAmount(commission: Double, offerAmount: Money, jurisdiction: Jurisdiction?): Summary {
  val buyerCommissionFee = offerAmount * commission
  val hstAmount = if (jurisdiction?.provinceState == ProvinceState.ON) buyerCommissionFee * HST else Money.ZERO
  return Summary(
    offerAmount,
    buyerCommissionFee = buyerCommissionFee,
    hst = hstAmount,
    subTotal = buyerCommissionFee + hstAmount,
    effectiveOfferPrice = offerAmount - buyerCommissionFee - hstAmount
  )
}

fun Offer.getExpiryString(timeZone: TimeZone) = expiry?.instant?.let {
  formatDateTime(it.instant.toLocalDateTime(timeZone))
}

private fun <T : Any> NegotiatedTerm<T>.toNegotiatedRow(label: String, formatter: (T) -> String) = when (state) {
  NegotiatedTerm.State.NEW -> NegotiatedSummaryRow.NewRow(label, formatter(currentValue.get()))
  NegotiatedTerm.State.ACCEPTED -> NegotiatedSummaryRow.AcceptRow(label, formatter(currentValue.get()))
  NegotiatedTerm.State.COUNTERED -> beforeAndCurrent(formatter).let {
    NegotiatedSummaryRow.CounterRow(
      label,
      it.first,
      it.second
    )
  }
  NegotiatedTerm.State.REJECTED -> NegotiatedSummaryRow.RejectRow(label, formatter(currentValue.get()), 1, 1)
  else -> null
}

private fun <T : Any> NegotiatedTerm<T>.beforeAndCurrent(formatter: (T) -> String): Pair<String, String?> =
  formatter(currentValue.get()) to changeHistory.getOrNull(changeHistory.size - 2)?.value?.core?.value?.getOrNull()
    ?.let { formatter(it) }

private fun <T : Any> List<NegotiatedTerm<T>>.toNegotiatedRow(label: String, itemName: String): NegotiatedSummaryRow {
  val newCount = count { it.state == NegotiatedTerm.State.NEW }
  val acceptCount = count { it.state.isAgreed }
  val totalCount = count { it.state != NegotiatedTerm.State.NEW }
  return if (newCount > 0) {
    NegotiatedSummaryRow.NewRow(
      label,
      "There ${"is".toHumanCount(newCount)} new ${itemName.toHumanCount(newCount)} to review"
    )
  } else if (acceptCount == totalCount) {
    NegotiatedSummaryRow.AcceptRow(label, "PARTY accepted all ${itemName.toHumanCount(acceptCount)}")
  } else {
    NegotiatedSummaryRow.RejectRow(label, itemName, totalCount - acceptCount, totalCount)
  }
}

enum class TagColor {
  YELLOW,
  LIGHT_GREEN,
  GREEN,
  RED,
  BLUE,
  DARK_BLUE,
  GREY,
  DARK_GREY,
}
