package common

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import core.Builder
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLButtonElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.padding
import style.DiyStyleSheet.button
import style.DiyStyleSheet.onDarkBlue
import style.DiyStyleSheet.onDarkBlueNoBackground
import style.DiyStyleSheet.onGreen
import style.DiyStyleSheet.onLightBlue
import style.DiyStyleSheet.onLightGrey
import style.DiyStyleSheet.onRed
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.flex
import style.GridStyleSheet.justifyContentCenter

@Composable
fun Button(
  attrs: AttrBuilderContext<HTMLButtonElement>? = null,
  content: ContentBuilder<HTMLButtonElement>? = null,
) {
  Button(attrs = {
    classes(button)
    style {
      paddingLeft(padding)
      paddingRight(padding)
    }
    attrs?.invoke(this)
  }, content = content)
}

@Composable
fun ActionButton(
  disabled: Boolean = false,
  activeClass: String = onGreen,
  attrs: AttrBuilderContext<HTMLButtonElement>? = null,
  content: ContentBuilder<HTMLButtonElement>? = null,
) {
  common.Button({
    classes(if (disabled) onLightGrey else activeClass)
    if (disabled) disabled()
    style {
      property("margin", "auto 0px")
      if (disabled) cursor("not-allowed")
    }
    attrs?.invoke(this)
    // Invalidate onClick handler if disabled
    if (disabled) { onClick { } }
  }, content = content)
}

@Composable
fun LightBlueButton(
  attrs: AttrBuilderContext<HTMLButtonElement>? = null,
  content: ContentBuilder<HTMLButtonElement>? = null,
) {
  Button({
    classes(onLightBlue, button)
    attrs?.invoke(this)
  }, content = content)
}

@Composable
fun DarkBlueButton(
  noBackground: Boolean? = false,
  attrs: AttrBuilderContext<HTMLButtonElement>? = null,
  content: ContentBuilder<HTMLButtonElement>? = null,
) {
  Button({
    classes(if (noBackground == true) onDarkBlueNoBackground else onDarkBlue, button)
    style { padding(14.px, 24.px) }
    attrs?.invoke(this)
  }, content = content)
}

@Composable
fun CancelButton(
  attrs: AttrBuilderContext<HTMLButtonElement>? = null,
  content: ContentBuilder<HTMLButtonElement>? = null,
) {
  Button({
    classes(onRed, button, flex, alignItemsCenter, justifyContentCenter)
    attrs?.invoke(this)
  }, content = content)
}

@Composable
fun TextButton(
  opts: Builder<ButtonOptions>? = null,
  attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  val options = ButtonOptions().apply { opts?.invoke(this) }

  Button({
    classes(button, DiyStyleSheet.buttonLink)
    style { padding(0.px) }
    attrs?.invoke(this)
  }) {
    Span({
      style {
        color(options.color)
        fontWeight(DiyStyleSheet.Weights.semiBold)
      }
    }) { Text(options.label) }
  }
}

@Composable
fun GoBackButton(
  label: String = "Go back",
  path: String = "/home",
  attrs: AttrBuilderContext<HTMLButtonElement>? = null,
) {
  val router = Router.current
  Button({
    classes(button, DiyStyleSheet.headerButtonLink)
    style {
      color(DiyStyleSheet.Colors.green)
      fontWeight(DiyStyleSheet.Weights.darkNormal)
      padding(20.px)
    }
    onClick { router.navigate(path) }
    attrs?.invoke(this)
  }) {
    Icon(IconName.ARROW_LEFT) {
      style { marginRight(10.px) }
    }
    Text(label)
  }
}

open class ButtonOptions(
  open var label: String = "",
  open var color: CSSColorValue = DiyStyleSheet.Colors.green,
)
