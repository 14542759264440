package components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.common.formatDateTime
import com.diyoffer.negotiation.common.removeAt
import com.diyoffer.negotiation.common.replaceAt
import com.diyoffer.negotiation.messages.DiyImage
import com.diyoffer.negotiation.messages.YoutubeVideo
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.landing.YourInformationContract
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Fields
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Inputs
import com.diyoffer.negotiation.ui.landing.YourInformationContract.State
import com.diyoffer.negotiation.ui.landing.YourInformationEventHandler
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract
import com.diyoffer.negotiation.ui.state.LoadingState
import common.ActionButton
import common.Button
import common.DarkBlueButton
import common.DateTimePicker
import common.Dialog
import common.FlexColumn
import common.FlexRow
import common.HelpChip
import common.Icon
import common.IconName
import common.MessageInline
import common.TextField
import common.displayOverlay
import components.lightbox.YoutubeLink
import components.snackbar.Snackbar
import dev.petuska.kmdcx.icons.MDCIcon
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.lineHeight
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.minWidth
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberFactory
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.DiyStyleSheet.largeGreenText
import style.GridStyleSheet
import vm.landing.YourInformationViewModel
import vm.landing.YourInformationVmConfig
import vm.landing.YourInformationVmConfigParams

@Composable
fun YourInformationSidebar(state: ListingLandingScreenContract.State) {
  Div({
    classes(GridStyleSheet.flexColumn)
    style {
      gap(16.px)
      minWidth(336.px)
      maxWidth(480.px)
    }
  }) {
    if (!state.isCompleted) {
      YourInformationFormArea(state)
      FlexColumn({
        style {
          textAlign("center")
          fontSize(16.px)
          informationSection()
        }
      }) {
        Span({
          style {
            color(DiyStyleSheet.Colors.red)
            fontWeight(DiyStyleSheet.Weights.semiBold)
            lineHeight(23.px)
          }
        }) {
          Text("Quick, this is a rare find!\n")
        }
        Span({ style { fontWeight("bold") } }) {
          Text(
            when {
              state.offerCount <= 0 -> ""
              state.offerCount == 1 -> "There is already an offer on this listing."
              else -> "There are ${state.offerCount} offers on this listing."
            }
          )
        }
      }
    } else {
      FlexColumn({
        style {
          gap(DiyStyleSheet.Sizes.padding)
          justifyContent(JustifyContent.Center)
        }
      }) {
        Img(DiyImage.SuccessDialogHeader.src)
        Span({ classes(largeGreenText) }) {
          Text("This listing has been completed")
          Br()
          Text("with DIYoffer!")
        }
      }
    }
  }
}

@Suppress("LongMethod")
@Composable
private fun YourInformationFormArea(listingState: ListingLandingScreenContract.State) {
  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfigFactory by rememberFactory<YourInformationVmConfigParams, YourInformationVmConfig>()
  val vm = remember(scope) {
    YourInformationViewModel(
      scope,
      vmConfigFactory(
        YourInformationVmConfigParams(
          initialInputs = listingState.id?.let { { Inputs.Initialize(it) } }
        )
      ),
      YourInformationEventHandler(onNavigateToOffer = { router.navigate("/offer/edit/$it") })
    )
  }

  val state by vm.observeStates().collectAsState()

  InformationSuccessDialog(state, vm, state.sessionUser.timeZone())

  WithLabeledLoadingOverlay(
    if (state.loadingState == LoadingState.FETCHING) {
      "Preparing Offer"
    } else {
      null
    }
  ) {
    Div({
      classes(GridStyleSheet.flexColumn)
      style {
        informationSection()
        gap(20.px)
        backgroundColor(DiyStyleSheet.Colors.white)
        property("z-index", DiyStyleSheet.ZIndex.FORM)
      }
    }) {
      Span({
        classes(DiyStyleSheet.fontSize16, DiyStyleSheet.fontWeightSemiBold)
        style {
          color(DiyStyleSheet.Colors.darkBlue)
          marginBottom(4.px)
        }
      }) {
        Text("Your information")
      }

      YourInformationEdit(listingState, state, vm)

      FlexColumn({
        style {
          color(DiyStyleSheet.Colors.darkGrey)
          textAlign("center")
          lineHeight(20.px)
        }
      }) {
        Span {
          Span { Text("Not sure what to do? Click ") }
          Icon(IconName.HELP_DARK_BLUE) { style { color(DiyStyleSheet.Colors.darkGrey) } }
          Span { Text(" for tips.") }
        }
        Span {
          Text("Or you can watch our ")
          YoutubeLink(YoutubeVideo.BUYER_OFFER, "YouTube video")
          Text(".")
        }
      }
    }
  }
}

@Suppress("LongMethod")
@Composable
private fun YourInformationEdit(
  listingState: ListingLandingScreenContract.State,
  state: State,
  vm: YourInformationViewModel,
) {
  TextField({
    label = "Name"
    value = state.name
    error = state.errors[Fields.NAME]
    onModified = { vm.trySend(Inputs.UpdateName(it)) }
  })
  TextField({
    label = "Email"
    value = state.email
    error = state.errors[Fields.EMAIL]
    onModified = { vm.trySend(Inputs.UpdateEmail(it)) }
  })
  TextField({
    label = "Mobile"
    value = state.mobile
    error = state.errors[Fields.MOBILE]
    onModified = { vm.trySend(Inputs.UpdateMobile(it)) }
  })
  state.error?.let {
    Snackbar(it) { vm.trySend(Inputs.ClearErrors) }
  }
  state.successMessage?.let {
    Snackbar(it, MDCIcon.ThumbUp) { vm.trySend(Inputs.SetSuccessMessage(null)) }
  }

  listingState.withheldUntil?.let {
    FlexRow({ style { marginBottom(0.px) } }) {
      MessageInline(
        attrs = {
          style {
            marginBottom(0.px)
            marginTop(0.px)
          }
        },
        icon = MDCIcon.CalendarToday,
        twoToneIconColorFilters = DiyStyleSheet.TwoToneColorFilters.YELLOW
      ) {
        Span({ style { color(DiyStyleSheet.Colors.darkGrey) } }) {
          Text(
            "You may submit an offer now, but the seller will not be able to negotiate " +
              "with you, or any other buyer, until after ${formatDateTime(it)}."
          )
        }
      }
      HelpChip("offer-withholding-offers-date", 24.px)
    }
  }

  FlexRow({ style { marginBottom(0.px) } }) {
    ActionButton(
      attrs = {
        style {
          height(44.px)
          flexGrow(1)
        }
        onClick {
          vm.trySend(Inputs.InitiateOfferProcess)
        }
      }
    ) {
      Span({ classes(GridStyleSheet.flex, GridStyleSheet.alignItemsCenter, GridStyleSheet.justifyContentCenter) }) {
        Text("Walk me through the offer")
      }
    }
    HelpChip("listing-landing-create-offer", 24.px)
  }

  if (!state.bookViewingEmailSent) {
    FlexRow({ style { marginBottom(0.px) } }) {
      DarkBlueButton(
        true,
        attrs = {
          style {
            height(44.px)
            flexGrow(1)
          }
          onClick { vm.trySend(Inputs.BookAViewing) }
        }
      ) {
        Span(
          { classes(GridStyleSheet.flex, GridStyleSheet.alignItemsCenter, GridStyleSheet.justifyContentCenter) }
        ) {
          Text("Book a viewing")
        }
      }
      HelpChip("listing-landing-book-viewing", 24.px)
    }
  } else {
    MessageInline(null, MDCIcon.Info, DiyStyleSheet.TwoToneColorFilters.BLUE) {
      Span { Text("Thank you! Your booking request has been sent.") }
    }
  }
}

private fun StyleScope.informationSection() {
  padding(24.px)
  border {
    width(1.px)
    style(LineStyle.Solid)
    color(DiyStyleSheet.Colors.lightGrey)
  }
}

@Composable
private fun InformationSuccessDialog(state: State, vm: YourInformationViewModel, timeZone: TimeZone) {
  Dialog(
    open = state.popupVisible != null,
    title = "Congratulations on deciding to ${when (state.popupVisible) {
      YourInformationContract.Action.BOOK_A_VIEWING -> "book a viewing."
      YourInformationContract.Action.WALK_ME_THROUGH_THE_OFFER -> "make an offer."
      else -> ""
    }}",
    headerImg = DiyImage.OfferLinkSentDialogHeader,
    actionLabel = when (state.popupVisible) {
      YourInformationContract.Action.BOOK_A_VIEWING -> "Book a visit"
      YourInformationContract.Action.WALK_ME_THROUGH_THE_OFFER -> "OK, I'm Ready"
      else -> ""
    },
    onAction = {
      displayOverlay(false)
      vm.trySend(Inputs.OkSuccessPopup)
    },
    onClose = { vm.trySend(Inputs.DismissSuccessPopup) }
  ) {
    when (state.popupVisible) {
      YourInformationContract.Action.WALK_ME_THROUGH_THE_OFFER -> YourInformationDialogMakeAnOfferBody()
      YourInformationContract.Action.BOOK_A_VIEWING -> YourInformationDialogBookAViewingBody(state, vm, timeZone)
      else -> Unit
    }
  }
}

@Suppress("MaxLineLength")
@Composable
private fun YourInformationDialogMakeAnOfferBody() {
  AsciidocContent(
    Asciidoc(
      "Here is what is going to happen next.\n\n" +
        "We’re going to lead you step-by-step through the process of making your offer. Each section will have help icons, on which you can hover or click for useful information.\n\n" +
        "In the first section, you will verify your email, and the email of anyone else you want to have access to the offer. We will send a secure link to each verified email. The link can always be used to pick up where you left off, if you can't finish the entire offer all at once.\n\n" +
        "For each section, you will be presented with the Seller’s desired values; you will have the option to:\n\n" +
        "* *accept* the seller’s value as-is\n" +
        "* *counter* the seller’s value with your own proposal,\n" +
        "* and in some cases, *reject* the seller’s proposal.\n\n" +
        "At all times, consider your own needs, but also consider what would be attractive to the seller — you want them to choose your offer over others they may have on the table.\n\n" +
        "If you need technical assistance at any time, our support support@diyoffer.ca will always be easily accessible to you."
    )
  )
}

@Composable
private fun YourInformationDialogBookAViewingBody(state: State, vm: YourInformationViewModel, timeZone: TimeZone) {
  val clock by rememberInstance<Clock>()

  FlexColumn {
    P { Text("Propose some times to the seller:") }
    state.proposedBookingTimes.mapIndexed { idx, value ->
      FlexRow({
        style { marginBottom(DiyStyleSheet.Sizes.padding) }
      }) {
        Div({
          style { flexGrow(1) }
        }) {
          DateTimePicker({
            label = "Date Time Option ${idx + 1}"
            this.value = value
            onModified = {
              vm.trySend(
                Inputs.UpdateProposedBookingTime(state.proposedBookingTimes.replaceAt(idx, it!!))
              )
            }
          })
        }
        Icon(IconName.REMOVE) {
          style { cursor("pointer") }
          onClick {
            vm.trySend(Inputs.UpdateProposedBookingTime(state.proposedBookingTimes.removeAt(idx)))
          }
        }
      }
    }

    Button({
      classes(DiyStyleSheet.onLightBlue, DiyStyleSheet.button)
      style { width(200.px) }
      onClick {
        vm.trySend(
          Inputs.UpdateProposedBookingTime(
            state.proposedBookingTimes + clock.now().toLocalDateTime(timeZone)
          )
        )
      }
    }) {
      Text(if (state.proposedBookingTimes.isEmpty()) "+ Pick a date time" else "+ Add more options")
    }
  }
}
