@file:Suppress("MagicNumber")

package pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import app.softwork.routingcompose.navigate
import com.diyoffer.negotiation.common.formatDate
import com.diyoffer.negotiation.common.services.listings.addressStandardSummary
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.Inputs
import com.diyoffer.negotiation.ui.offer.SellerOfferTabEventHandler
import com.diyoffer.negotiation.ui.offer.TagColor
import com.diyoffer.negotiation.ui.offer.statusChip
import common.FlexColumn
import common.FlexRow
import common.HelpChip
import common.IconName
import common.MessageInline
import common.Row
import common.Tag
import common.TagText
import components.CreateEditTermsActionButton
import components.LinkDisplay
import components.Loading
import components.NoItemDisplay
import components.PriceDisplayColumn
import components.offer.OfferActionButtons
import components.offer.OfferNegotiationSummary
import components.snackbar.Snackbar
import dev.petuska.kmdcx.icons.MDCIcon
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import model.RoutingParams
import model.backRouteParam
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.paddingMd
import style.DiyStyleSheet.Sizes.paddingSm
import style.DiyStyleSheet.Sizes.paddingXs
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.GridStyleSheet.justifyContentBetween
import style.GridStyleSheet.justifyContentEnd
import style.OfferListStyleSheet
import style.WhatTodoStyleSheet
import vm.login.UserViewModel
import vm.offer.SellerOfferTabViewModel
import vm.offer.SellerOfferTabViewModelConfiguration

@Composable
fun OfferListPage(userVm: UserViewModel) {
  Style(OfferListStyleSheet)
  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<SellerOfferTabViewModelConfiguration>()
  val vm = remember(scope) {
    SellerOfferTabViewModel(
      config = vmConfig,
      viewModelCoroutineScope = scope,
      eventHandler = SellerOfferTabEventHandler(
        onNavigate = { router.navigate(it, RoutingParams.BackRoutes.offers.backRouteParam()) }
      )
    )
  }
  val state by vm.observeStates().collectAsState()

  val userState by userVm.observeStates().collectAsState()

  LaunchedEffect(Unit) {
    vm.send(Inputs.Fetch)
  }

  state.error?.let { Snackbar(it) { vm.trySend(Inputs.SetError(null)) } }

  if (state.loadingState.isLoading()) {
    Loading("Fetching Offers")
  } else if (state.offers.isNullOrEmpty()) {
    NoItemDisplay(
      title = "There are no offers yet",
    ) {
      val publishedListings = state.listings.values.map { it.listing }.filterIsInstance<Listing.Published>()
      if (publishedListings.isEmpty()) {
        NoOffersNoListings(userState.draftListing, router)
      } else {
        publishedListings.map {
          NoOffersWithPublishedListing(it)
        }
      }
    }
  } else {
    // Group the offers by listingNames and create one section for each
    Div({
      classes(DiyStyleSheet.mainForm)
    }) {
      state.offers?.groupBy { it.listingName }?.map {
        it.key?.let { listingName -> OffersByListing(vm, listingName, it.value) }
      }
    }
  }
}

@Composable
private fun NoOffersNoListings(draftListing: Listing.Draft?, router: Router) {
  Span({ classes(WhatTodoStyleSheet.noActivityDescription) }) {
    Text("You have no listing terms published at the moment.")
  }
  Div(attrs = { style { marginTop(18.px) } }) {
    CreateEditTermsActionButton(draftListing, router)
  }
}

@Composable
private fun NoOffersWithPublishedListing(listing: Listing.Published) {
  Div({ classes(WhatTodoStyleSheet.noActivityDescription) }) {
    H3 { Text("Tips for ${listing.addressStandardSummary()}") }
    val links = listing.externalLinks.links
    if (links.isEmpty()) {
      P { Text("Add links to your published DIYoffer terms page on external listing services, for example:") }
      Ul {
        ListingLinks.Link.ListingProvider.values().filter { it.createListingUrl != null }.map {
          Li {
            LinkDisplay(it.friendlyName, it.createListingUrl!!, false, it)
          }
        }
      }
    } else {
      P { Text("In Section 9 of your published terms, you added links to:") }
      Ul {
        links.map {
          Li {
            LinkDisplay(it.provider.friendlyName, it.url, false, it.provider)
          }
        }
      }
      val missing = ListingLinks.Link.ListingProvider.values().filter { it.createListingUrl != null }.toSet() -
        links.map { it.provider }.toSet()
      if (missing.isNotEmpty()) {
        P { Text("Consider also listing on the following sites and adding the listing to Section 9:") }
        Ul {
          missing.map {
            Li {
              LinkDisplay(it.friendlyName, it.createListingUrl!!, false, it)
            }
          }
        }
      }
    }

    P {
      Text(
        "On each of these, you should add the property link from DIYoffer Negotiations into the " +
          "description of your home. So it could look like this:"
      )
    }
    Hr()
    Div({ classes(DiyStyleSheet.fontWeightBold) }) {
      P { Text("4 bedroom 2.5 bath house for sale in a great neighbourhood, close to parks and lots of amenities.") }
      P { Text("To quickly and easily request a viewing or to submit an offer online go to:") }
      P { Text(listing.permalink) }
    }
  }
}

@Composable
private fun OffersByListing(vm: SellerOfferTabViewModel, listingName: String, offers: List<OfferUI>) {
  val activeOffers = offers.filter { it.active }
  Div({
    classes(flex, justifyContentBetween)
    style { paddingBottom(paddingMd) }
  }) {
    Title(listingName)
    Showing(offers.size)
  }
  DisplayOffer(vm, activeOffers)
  DisplayOffer(vm, offers - activeOffers.toSet(), false)
}

@Composable
private fun Title(listingName: String) {
  Div({
    classes(OfferListStyleSheet.pageTitle)
  }) {
    Img("images/bullet-point-blue.svg") {
      style { width(23.px) }
    }
    Span({
      classes(OfferListStyleSheet.textTitle)
    }) {
      Text("All offers for $listingName")
    }
  }
}

@Composable
private fun Showing(total: Int) {
  Div({
    classes(flex, OfferListStyleSheet.showing)
  }) {
    Text("Showing ")
    Span({
      classes(OfferListStyleSheet.blackText)
    }) {
      Text(total.toString())
    }
    Text("Offers")
  }
}

@Composable
private fun DisplayOffer(vm: SellerOfferTabViewModel, offers: List<OfferUI>, active: Boolean = true) {
  Div({
    classes(flexColumn)
    style { marginBottom(24.px) }
  }) {
    Div({
      classes(flex, alignItemsCenter)
      style { gap(8.px) }
    }) {
      Img("images/double-arrow.svg")
      Span({
        classes(OfferListStyleSheet.greyText)
      }) {
        Text(if (active) "ACTIVE OFFERS" else "INACTIVE OFFERS")
      }
      Span({
        style { color(if (active) DiyStyleSheet.Colors.green else DiyStyleSheet.Colors.red) }
      }) {
        Text(offers.count().toString())
      }
    }
    offers.forEach { offerUI ->
      OfferItem(vm, offerUI, active)
    }
  }
}

@Composable
private fun OfferItem(vm: SellerOfferTabViewModel, offer: OfferUI, active: Boolean = true) {
  val state = vm.observeStates().collectAsState()

  Div({
    classes(flexColumn, OfferListStyleSheet.offerItemContainer)
    if (offer.offer.state.isAccepted) classes(OfferListStyleSheet.accepted)
    style {
      if (!active) backgroundColor(DiyStyleSheet.Colors.lightestGrey)
    }
  }) {
    NoticeRow(offer, active)
    InfoRow(offer, state.value.sessionUser.timeZone(), active)
    ActionRow(vm, offer, active)
    if (active && offer.counterRejectList.isNotEmpty()) {
      Hr { style { width(100.percent) } }
      OfferNegotiationSummary(offer.counterRejectList)
    }
  }
}

@Composable
private fun NoticeRow(offerUI: OfferUI, active: Boolean) {
  Div({ classes(flex, justifyContentBetween) }) {
    FlexColumn {
      if (offerUI.hasAcceptedOtherOffer && active) {
        Row({ style { marginBottom(0.px) } }) {
          MessageInline(icon = MDCIcon.Info, twoToneIconColorFilters = DiyStyleSheet.TwoToneColorFilters.BLUE) {
            Span { Text("Offer cannot be accepted because another offer has been accepted") }
          }
        }
      }

      offerUI.cardInfoSummary?.let {
        Row({ style { marginBottom(0.px) } }) {
          val (icon, color) = offerUI.offer.state.icon()
          MessageInline(icon = icon, twoToneIconColorFilters = color) { Span { Text(it) } }
        }
      }
    }
    if (offerUI.offer.state == Offer.State.ACCEPTED_PENDING) {
      Tag(
        "Firm Offer",
        IconName.THUMB_UP,
        TagColor.LIGHT_GREEN
      )
    }
  }
}

@Composable
private fun InfoRow(offer: OfferUI, timeZone: TimeZone, active: Boolean) {
  Div({
    classes(flex, justifyContentBetween)
    style { marginTop(10.px) }
  }) {
    InfoLeft(offer, timeZone, active)
    InfoRight(offer, active)
  }
}

@Suppress("ComplexMethod")
@Composable
private fun InfoLeft(offerUI: OfferUI, timeZone: TimeZone, active: Boolean) {
  val clock by rememberInstance<Clock>()

  Div({
    classes(flexColumn)
  }) {
    Div({
      classes(flex, alignItemsCenter)
    }) {
      Span({
        style {
          fontWeight(DiyStyleSheet.Weights.darkNormal)
          fontSize(22.px)
          marginRight(8.px)
          if (!active) color(DiyStyleSheet.Colors.grey)
        }
      }) {
        Text("Offer No.${offerUI.orderNumber}")
      }
      when (offerUI.trending) {
        Trending.UP -> Img("images/trending-up.svg")
        Trending.DOWN -> Img("images/trending-down.svg")
        else -> Unit
      }
      if (offerUI.highest == true || offerUI.lowest == true) {
        Span({ classes(OfferListStyleSheet.trending) }) {
          Text(if (offerUI.lowest == true) "Lowest offer" else "Highest Offer")
        }
      }
    }

    offerUI.offer.statusChip(offerUI.withheldUntil, clock, timeZone).let {
      Tag(
        it.first,
        color = if (active) it.second else TagColor.GREY
      )
    }
  }
}

@Composable
private fun InfoRight(offerUI: OfferUI, active: Boolean) {
  Div({
    classes(flexColumn)
  }) {
    Div({
      classes(flex, justifyContentEnd)
    }) {
      val label = if (offerUI.ownAgent) "Effective Offer Price" else "Offer Price"
      val color = when {
        active && offerUI.ownAgent -> TagColor.GREEN
        active && !offerUI.ownAgent -> TagColor.DARK_BLUE
        else -> TagColor.GREY
      }

      FlexRow({
        style {
          marginBottom(4.px)
          gap(paddingXs)
        }
      }) {
        TagText(label, color) { style { fontSize(10.px) } }
        if (offerUI.ownAgent) HelpChip("seller-offer-review-effective-price")
      }
    }
    PriceDisplayColumn(price = offerUI.effectivePrice, currency = offerUI.offer.currency) {
      style {
        if (!active) color(DiyStyleSheet.Colors.grey)
        fontSize(24.px)
        textAlign("right")
      }
    }
  }
}

@Composable
private fun ActionRow(vm: SellerOfferTabViewModel, offerUI: OfferUI, active: Boolean) {
  Div({
    classes(flex, justifyContentBetween)
    style { marginTop(16.px) }
  }) {
    Div({
      classes(flex)
      style { gap(24.px) }
    }) {
      Label("Buyer", offerUI.buyerName, active)
      Label("Offer Date", formatDate(offerUI.updateTime.date), active)
      Label("Closing Date", formatDate(offerUI.closingDate), active)
    }
    if (active) {
      Div({
        classes(flex)
        style { gap(paddingSm) }
      }) {
        OfferActionButtons(
          Party.SELLER,
          offerUI.listing,
          offerUI.offer,
          offerUI.counterRejectList.isEmpty(),
          canSubmit = !offerUI.hasAcceptedOtherOffer && !offerUI.withheld,
          rejectVisible = !offerUI.withheld,
          hasAcceptedOtherOffer = offerUI.hasAcceptedOtherOffer
        ) {
          vm.trySend(Inputs.OfferActionClicked(offerUI.offer._id, it))
        }
      }
    }
  }
}

@Composable
private fun Label(text: String, description: String, active: Boolean) {
  Div({
    classes(flexColumn)
  }) {
    Span({
      classes(if (active) OfferListStyleSheet.yellowText else OfferListStyleSheet.greyText)
      style {
        fontSize(10.px)
      }
    }) {
      Text(text)
    }
    Span({
      style { color(if (active) DiyStyleSheet.Colors.darkGrey else DiyStyleSheet.Colors.grey) }
    }) {
      Text(description)
    }
  }
}

@Composable
fun Offer.State.icon() = when (this) {
  Offer.State.REJECTED -> MDCIcon.ThumbDown to DiyStyleSheet.TwoToneColorFilters.RED
  Offer.State.ACCEPTED -> MDCIcon.ThumbUp to DiyStyleSheet.TwoToneColorFilters.GREEN
  Offer.State.PUBLISHED -> MDCIcon.Repeat to DiyStyleSheet.TwoToneColorFilters.YELLOW
  else -> MDCIcon.Info to DiyStyleSheet.TwoToneColorFilters.BLUE
}
