package components.offer

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.common.services.offers.negotiationStageOf
import com.diyoffer.negotiation.common.toHumanCount
import com.diyoffer.negotiation.messages.CommonMessages.offerAcceptExplanation
import com.diyoffer.negotiation.messages.CommonMessages.offerRejectExplanation
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.Offer.State.Companion.statesAllowingAcceptsRejects
import com.diyoffer.negotiation.model.Offer.State.Companion.statesAllowingCompleteCancel
import com.diyoffer.negotiation.ui.offer.NegotiatedSummaryRow
import com.diyoffer.negotiation.ui.offer.OfferActionType
import com.diyoffer.negotiation.ui.offer.hasPen
import com.diyoffer.negotiation.ui.offer.isOfferActionDisabled
import common.ActionButton
import common.Button
import common.ConfirmDialog
import common.MessageInline
import dev.petuska.kmdcx.icons.MDCIcon
import kotlinx.browser.window
import org.jetbrains.compose.web.css.borderWidth
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.lineHeight
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textDecoration
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.green
import style.DiyStyleSheet.Colors.red
import style.DiyStyleSheet.fontWeightSemiBold
import style.DiyStyleSheet.onLightGrey
import style.DiyStyleSheet.onLightRed
import style.DiyStyleSheet.onLightYellow

@Suppress("LongParameterList", "ComplexMethod", "NestedBlockDepth", "LongMethod")
@Composable
fun OfferActionButtons(
  party: Party,
  listing: Listing.Published,
  offer: Offer,
  isOfferAllAgreed: Boolean,
  reviewMode: Boolean = true,
  canSubmit: Boolean? = null,
  rejectVisible: Boolean = true,
  hasAcceptedOtherOffer: Boolean = false,
  onClick: (OfferActionType) -> Unit,
) {
  val router = Router.current
  val (dialogAction, setDialogAction) = remember { mutableStateOf<OfferActionType?>(null) }
  val stage = negotiationStageOf(offer)

  if (!offer.hasPen(party) && offer.state in statesAllowingAcceptsRejects) {
    WaitButton("Waiting for ${party.counterParty().label}…")
  } else {
    if (offer.state in statesAllowingCompleteCancel) {
      // This is currently managed in the checklist so navigate user there
      OfferChecklistButton {
        when (party) {
          Party.SELLER -> router.navigate("/home/checklist?select=${offer._id}")
          Party.BUYER -> {
            val url = "/offer/checklist/${offer._id}"
            if (window.location.href.contains(url)) {
              window.scrollTo(0.0, 0.0)
            } else {
              router.navigate("/offer/checklist/${offer._id}?select=${offer._id}")
            }
          }
        }
      }
    } else {
      val allowAccept = listing.state in Listing.State.statesAllowingOfferAcceptance &&
        offer.state in statesAllowingAcceptsRejects &&
        stage != NegotiationStage.BUYER_DRAFTING_OFFER &&
        !hasAcceptedOtherOffer

      if (allowAccept) {
        OfferAcceptButton(!isOfferAllAgreed || offer.isOfferActionDisabled || canSubmit == false) {
          setDialogAction(OfferActionType.ACCEPT)
        }
      }
    }

    if (reviewMode) {
      val canContinueDraft = offer.state == Offer.State.DRAFT &&
        offer.authoredBy == party &&
        listing.state in Listing.State.statesAllowingOfferAcceptance

      OfferReviewButton(false, party, if (canContinueDraft) "Continue" else "Review", onClick)
    } else {
      if (stage in listOf(NegotiationStage.SELLER_COUNTERING, NegotiationStage.BUYER_COUNTERING) && !isOfferAllAgreed) {
        OfferCounterButton(offer.isOfferActionDisabled || canSubmit == false, party, onClick)
      } else if (stage == NegotiationStage.BUYER_DRAFTING_OFFER) {
        canSubmit?.let {
          OfferSubmitButton(canSubmit == false, onClick)
        }
      }
    }
    if (rejectVisible && offer.state in statesAllowingAcceptsRejects && stage != NegotiationStage.BUYER_DRAFTING_OFFER) {
      OfferRejectButton(offer.isOfferActionDisabled, party) { setDialogAction(it) }
    }

    val (dialogTitle, dialogContent) = when (dialogAction) {
      OfferActionType.REJECT -> "Offer Rejection" to offerRejectExplanation(party)
      OfferActionType.ACCEPT -> "Offer Acceptance" to offerAcceptExplanation(party)
      else -> null to ""
    }

    dialogTitle?.let {
      ConfirmDialog(
        opened = dialogTitle.isNotBlank(),
        title = dialogTitle,
        onConfirm = {
          onClick(dialogAction!!)
          setDialogAction(null)
        },
        onCancel = { setDialogAction(null) }
      ) {
        P { Text(dialogContent) }
      }
    }
  }
}

@Composable
private fun OfferAcceptButton(disabled: Boolean, onClick: (OfferActionType) -> Unit) {
  ActionButton(
    disabled = disabled,
    attrs = {
      if (!disabled) {
        title("PARTIES must agree to all terms of the offer before starting the closing process.")
      }
      style { padding(8.px, 12.px) }
      onClick { onClick(OfferActionType.ACCEPT) }
    }
  ) {
    Text("Accept")
  }
}

@Composable
private fun OfferReviewButton(disabled: Boolean, party: Party, text: String, onClick: (OfferActionType) -> Unit) {
  ActionButton(
    disabled = disabled,
    activeClass = onLightYellow,
    attrs = {
      title("Review the ${party.counterParty()}'s counter term by term.")
      style { padding(8.px, 12.px) }
      onClick { onClick(OfferActionType.REVIEW) }
    }
  ) {
    Span {
      Text(text)
    }
  }
}

@Composable
private fun OfferCounterButton(disabled: Boolean, party: Party, onClick: (OfferActionType) -> Unit) {
  ActionButton(
    disabled = disabled,
    activeClass = onLightYellow,
    attrs = {
      title("Submit a counter offer to ${party.counterParty().label}")
      style { padding(8.px, 12.px) }
      onClick { onClick(OfferActionType.PUBLISH) }
    }
  ) {
    Span {
      Text("Submit Counter")
    }
  }
}

@Composable
private fun OfferSubmitButton(disabled: Boolean, onClick: (OfferActionType) -> Unit) {
  ActionButton(
    disabled = disabled,
    attrs = {
      title(
        if (disabled) {
          "You must fill all sections before submitting your offer"
        } else {
          "Submit your offer"
        }
      )
      style { padding(8.px, 12.px) }
      onClick { onClick(OfferActionType.PUBLISH) }
    }
  ) {
    Span {
      Text("Submit")
    }
  }
}

@Composable
private fun OfferRejectButton(disabled: Boolean, party: Party, onClick: (OfferActionType) -> Unit) {
  ActionButton(
    disabled = disabled,
    activeClass = onLightRed,
    attrs = {
      title(offerRejectExplanation(party))
      style { padding(8.px, 12.px) }
      onClick { onClick(OfferActionType.REJECT) }
    }
  ) {
    Span {
      Text("Reject")
    }
  }
}

@Composable
private fun OfferChecklistButton(onClick: () -> Unit) {
  ActionButton(
    attrs = {
      title("Navigate to the checklist to learn what's next.")
      style { padding(8.px, 12.px) }
      onClick { onClick() }
    }
  ) {
    Text("Finalize Offer")
  }
}

@Composable
private fun WaitButton(label: String) {
  Button({
    classes(onLightGrey, DiyStyleSheet.button)
    style {
      borderWidth(0.px)
      cursor("not-allowed")
      padding(2.px, 8.px)
    }
  }) {
    MessageInline(icon = MDCIcon.Schedule, twoToneIconColorFilters = DiyStyleSheet.TwoToneColorFilters.GREY) {
      Span { Text(label) }
    }
  }
}

@Composable
fun OfferNegotiationSummary(counterRejectList: List<NegotiatedSummaryRow>) {
  OfferCounterRejectSection(
    "New",
    counterRejectList.filter { it.state == NegotiatedTerm.State.NEW }
  )
  OfferCounterRejectSection(
    "Counters",
    counterRejectList.filter { it.state == NegotiatedTerm.State.COUNTERED }
  )
  OfferCounterRejectSection(
    "Rejections",
    counterRejectList.filter { it.state == NegotiatedTerm.State.REJECTED }
  )
}

@Composable
private fun OfferCounterRejectSection(
  sectionLabel: String,
  counterRejectList: List<NegotiatedSummaryRow>,
) {
  if (counterRejectList.isEmpty()) return

  Span({
    classes(fontWeightSemiBold)
    style { color(DiyStyleSheet.Colors.black) }
  }) {
    Text(sectionLabel)
  }

  Ul({
    style {
      paddingLeft(13.px)
      lineHeight(30.px)
    }
  }) {
    counterRejectList.map {
      Li({
        style { color(DiyStyleSheet.Colors.darkGrey) }
      }) {
        Span { Text("${it.label}: ") }
        OfferCounterItem(it)
      }
    }
  }
}

@Composable
private fun OfferCounterItem(uiRow: NegotiatedSummaryRow) {
  when (uiRow) {
    is NegotiatedSummaryRow.NewRow -> {
      Span { Text(uiRow.message) }
    }

    is NegotiatedSummaryRow.CounterRow -> {
      uiRow.prevValue?.let {
        Span({
          style {
            color(red)
            textDecoration("line-through")
          }
        }) {
          Text("${uiRow.prevValue}")
        }
        Span { Text(" → ") }
      }

      Span({
        style { color(green) }
      }) { Text(uiRow.currentValue) }
    }

    is NegotiatedSummaryRow.RejectRow -> {
      if (uiRow.rejectCount >= 1 && uiRow.totalCount >= 1) {
        Span({ style { color(red) } }) { Text(uiRow.rejectCount.toString()) }
        Span {
          Text(" out of ${uiRow.itemName.toHumanCount(uiRow.totalCount)} are currently not agreed")
        }
      }
    }

    is NegotiatedSummaryRow.AcceptRow -> {
      Span({ style { color(green) } }) { Text(uiRow.message) }
    }
  }
}
