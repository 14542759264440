package common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import core.Builder
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.RadioInput
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.green
import style.GridStyleSheet

@Composable
fun <T> GroupedRadioButton(
  opts: Builder<SelectableOptions<T>>,
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val options = SelectableOptions<T>().apply { opts(this) }
  val (selectedValue, setSelectedValue) = remember { mutableStateOf(options.value) }

  LaunchedEffect(options.value) {
    setSelectedValue(options.value)
  }

  Div({
    classes(GridStyleSheet.row)
    attrs?.invoke(this)
  }) {
    Div({
      classes(GridStyleSheet.col12, GridStyleSheet.flex, GridStyleSheet.alignItemsCenter)
      style { gap(5.px) }
    }) {
      options.items.forEach { item ->
        val (key, label) = item
        Div({
          classes(GridStyleSheet.flex, GridStyleSheet.alignItemsCenter, "pointer")
          style {
            marginRight(10.px)
            gap(5.px)
          }
          onClick {
            setSelectedValue(key)
            options.onModified(key)
          }
        }) {
          RadioInput {
            classes("pointer")
            checked(selectedValue == key)
            onChange {
              val selection = if (it.value) key else null
              setSelectedValue(selection)
              options.onModified(selection)
            }
            style {
              margin(0.px)
              options.selectedColors?.let {
                property("accent-color", it(key))
              }
            }
          }
          Span({
            style {
              if (selectedValue == key) {
                options.selectedColors?.invoke(key)?.let { color(it) } ?: color(green)
                fontWeight(DiyStyleSheet.Weights.semiBold)
              }
            }
          }) {
            Text(label)
          }
        }
      }
    }
  }
}
