@file:Suppress("MagicNumber")

package pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import app.softwork.routingcompose.navigate
import builders.ListingPropertyDetailsBuilder
import builders.OfferAdditionalRequestBuilder
import builders.OfferAssumableContractsBuilder
import builders.OfferBindingAgreementTermsBuilder
import builders.OfferBuyerAgentInformationBuilder
import builders.OfferBuyerConfirmationBuilder
import builders.OfferBuyerInformationBuilder
import builders.OfferChattelsIncludedListBuilder
import builders.OfferClosingBuilder
import builders.OfferExpirationBuilder
import builders.OfferFixtureExcludedListBuilder
import builders.OfferPriceBuilder
import builders.ViewModelBuilder
import com.diyoffer.negotiation.common.formatDateTime
import com.diyoffer.negotiation.common.services.listings.buildConditionFilters
import com.diyoffer.negotiation.common.services.offers.negotiationStageOf
import com.diyoffer.negotiation.messages.MessageKey
import com.diyoffer.negotiation.messages.YoutubeVideo
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.ui.condition.ConditionListContract
import com.diyoffer.negotiation.ui.condition.getTitle
import com.diyoffer.negotiation.ui.offer.OfferContactsContract
import com.diyoffer.negotiation.ui.offer.OfferContactsEventHandler
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract.Inputs
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract.State
import com.diyoffer.negotiation.ui.offer.OfferEditScreenEventHandler
import com.diyoffer.negotiation.ui.offer.counterRejectList
import com.diyoffer.negotiation.ui.offer.navButton
import com.diyoffer.negotiation.ui.state.LoadingState
import common.ActionButton
import common.DarkBlueButton
import common.FlexRow
import components.ChangeLoginStateButton
import components.DetailContainer
import components.DetailStatus
import components.lightbox.YoutubeLink
import components.offer.OfferActionButtons
import components.snackbar.Snackbar
import dev.petuska.kmdc.circular.progress.MDCCircularProgress
import dev.petuska.kmdcx.icons.MDCIcon
import forms.ChattelsIncludedListDisplay
import forms.ConditionListEdit
import forms.ConditionsDisplay
import forms.ListingPropertyDetailsDisplay
import forms.ListingPropertyDetailsEdit
import forms.OfferAdditionalRequestDisplay
import forms.OfferAdditionalRequestEdit
import forms.OfferAssumableContractListDisplay
import forms.OfferAssumableContractListEdit
import forms.OfferBindingAgreementTermEdit
import forms.OfferBindingAgreementTermsDisplay
import forms.OfferBuyerAgentInformationDisplay
import forms.OfferBuyerAgentInformationEdit
import forms.OfferBuyerConfirmationDisplay
import forms.OfferBuyerConfirmationEdit
import forms.OfferBuyerInformationDisplay
import forms.OfferBuyerInformationEdit
import forms.OfferBuyersDisplay
import forms.OfferBuyersEdit
import forms.OfferChattelsIncludedListEdit
import forms.OfferClosingDateDisplay
import forms.OfferClosingDateEdit
import forms.OfferConditionListNegotiate
import forms.OfferExpirationDisplay
import forms.OfferExpirationEdit
import forms.OfferFixturesExcludedListDisplay
import forms.OfferFixturesExcludedListEdit
import forms.OfferPriceDisplay
import forms.OfferPriceEdit
import forms.WithNegotiatedConditionVM
import forms.toUI
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import layout.FormContainer
import layout.Header
import layout.InformationBar
import layout.SubmitOrGoBackFooter
import model.RoutingParams
import model.backRouteParam
import model.nextActionParam
import model.returnMetadataFromBackRoute
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.red
import style.DiyStyleSheet.Colors.yellow
import style.GridStyleSheet
import vm.login.UserViewModel
import vm.offer.OfferContactsViewModel
import vm.offer.OfferContactsViewModelConfiguration
import vm.offer.OfferEditScreenViewModel
import vm.offer.OfferEditScreenViewModelConfiguration

/**
 * This is the form where buyer edits his draft offer. The same form is used by the seller to prepare the counter.
 */
@Suppress("LongMethod", "ComplexMethod")
@Composable
fun OfferDetailsPage(userVm: UserViewModel, offerId: Uid<Offer>, user: SessionUser, backRoute: String?) {
  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<OfferEditScreenViewModelConfiguration>()
  val vm = remember(scope) {
    OfferEditScreenViewModel(
      scope,
      vmConfig,
      OfferEditScreenEventHandler(onNavigate = {
        router.navigate(
          it,
          RoutingParams.BackRoutes.offers.backRouteParam()
        )
      })
    )
  }

  val state by vm.observeStates().collectAsState()

  LaunchedEffect(Unit) {
    vm.send(Inputs.FetchEditOffer(offerId))
  }

  if (state.loadingState == LoadingState.UNAUTHORIZED) {
    if ((user as? SessionUser.AnonUser)?.inferredParty == Party.SELLER) {
      router.navigate("/auth/sign-in", nextActionParam(router.currentPath.path, MessageKey.MUST_SIGN_IN_OFFER))
    } else {
      Unauthorized(userVm, user) { SendNewLink(vm, state) }
    }
    return
  }

  // This will need further thinking, but Buyer Checklist url doesn't have the same structure as seller checklist
  // and the offer id is required /offer/checklist/{offerId} so the back route isn't sufficiently qualified ATM
  val returnRoute = returnMetadataFromBackRoute(backRoute)

  val party = state.party
  val offer = state.offer
  val relatedListing = state.relatedListing

  val returnMetadata = if (returnRoute != null && party == Party.SELLER) returnRoute else state.navButton

  val allAgreed = if (state.negotiationStage == NegotiationStage.BUYER_DRAFTING_OFFER) {
    false
  } else {
    offer?.counterRejectList()?.isEmpty() ?: false
  }

  Snackbar(state.error) { vm.trySend(Inputs.SetError(null)) }

  OfferHeader(
    userVm,
    user,
    state,
    allAgreed,
    vm,
    returnMetadata,
  )

  if (state.loadingState == LoadingState.NOT_LOADED) return

  when {
    offer?.state == Offer.State.DRAFT && party == Party.BUYER -> {
      InformationBar {
        Span {
          Text("Lets make an offer. Watch our ")
          YoutubeLink(YoutubeVideo.BUYER_OFFER, "YouTube video")
          Text(".")
        }
      }
    }

    offer?.state in listOf(Offer.State.REJECTED, Offer.State.CANCELED) -> {
      InformationBar("The offer has been ${offer?.state}.", MDCIcon.Cancel, red)
    }

    offer?.state in listOf(Offer.State.ACCEPTED, Offer.State.COMPLETED) -> {
      InformationBar("The offer has been ${offer?.state} and cannot be modified.")
    }

    offer?.state == Offer.State.EXPIRED -> {
      InformationBar("This offer has expired and can no longer be edited.", MDCIcon.LockClock, red)
    }

    state.offerWithheld -> {
      InformationBar(
        "Seller is withholding offers and cannot respond until " +
          "${formatDateTime(state.relatedListingWithholdingDateTime!!)}. Buyers can submit offers now.",
        MDCIcon.Warning,
        yellow
      )
    }

    state.hasAcceptedOtherOffer() && party == Party.SELLER -> {
      InformationBar(
        "Another offer has been accepted for this listing. This offer cannot be countered or accepted.",
        MDCIcon.Cancel,
        red
      )
    }

    !state.hasPen && state.loadingState == LoadingState.READY -> {
      InformationBar("The offer cannot be edited as it's being reviewed by the ${state.otherPartyStr()}.")
    }

    allAgreed -> {
      InformationBar("Parties agree on all terms. You can now Accept the offer.")
    }

    party == Party.SELLER && state.loadingState == LoadingState.READY ->
      InformationBar {
        Span {
          Text("Lets review this offer and potentially accept, reject, or counter it. Watch our ")
          YoutubeLink(YoutubeVideo.SELLER_NEGOTIATING, "YouTube video")
          Text(".")
        }
      }
  }

  FormContainer {
    OfferDetailsPageView(vm, state)

    FlexRow({ style { marginBottom(36.px) } }) {
      SubmitOrGoBackFooter(
        goBackLabel = returnMetadata.label,
        submitState = SubmitState.HIDDEN, // We use the offer action button below instead
        warningMessage = "All sections must be filled before you can submit your offer.",
        goBack = { router.navigate(returnMetadata.data.route) }
      ) { }

      if (party != null && offer != null && relatedListing != null) {
        OfferActionButtons(
          party,
          relatedListing,
          offer,
          allAgreed,
          reviewMode = false,
          canSubmit = state.canSubmit,
          hasAcceptedOtherOffer = state.hasAcceptedOtherOffer()
        ) {
          vm.trySend(Inputs.OfferActionClicked(it))
        }
      }
    }
  }
}

@Suppress("LongParameterList")
@Composable
private fun OfferHeader(
  userVm: UserViewModel,
  user: SessionUser,
  state: State,
  allAgreed: Boolean,
  vm: OfferEditScreenViewModel,
  returnMetadata: NavButtonMetadata,
) {
  val router = Router.current
  val progressText = if (state.loadingState.isLoading()) "Loading" else null

  val party = state.party
  val offer = state.offer
  val relatedListing = state.relatedListing

  Header {
    Div({
      classes(GridStyleSheet.flex, GridStyleSheet.alignItemsCenter)
      style { gap(12.px) }
    }) {
      if (progressText != null) {
        MDCCircularProgress()
        Text(progressText)
      } else {
        ChangeLoginStateButton(userVm, user)

        DarkBlueButton(
          attrs = {
            style {
              padding(8.px, 12.px)
              backgroundColor(DiyStyleSheet.Colors.white)
            }
            onClick {
              router.navigate(returnMetadata.data.route)
            }
          },
          noBackground = true
        ) {
          Text(returnMetadata.label)
        }
        if (party != null && offer != null && relatedListing != null) {
          OfferActionButtons(
            party,
            relatedListing,
            offer,
            allAgreed,
            reviewMode = false,
            canSubmit = state.canSubmit,
            hasAcceptedOtherOffer = state.hasAcceptedOtherOffer()
          ) {
            vm.trySend(Inputs.OfferActionClicked(it))
          }
        }
      }
    }
  }
}

@Composable
private fun OfferDetailsPageView(vm: OfferEditScreenViewModel, state: State) {
  val clock by rememberInstance<Clock>()
  var sectionNumber = 1
  val saveOffer: (Offer) -> Unit = { vm.trySend(Inputs.SaveOffer(it)) }
  val timeZone = state.sessionUser.timeZone()

  Div({
    classes(DiyStyleSheet.mainForm)
  }) {
    H2 {
      Text(
        "Offer Details" + (state.relatedListing?.propertyDetails?.address?.standardSummary()?.let { " ($it)" } ?: "")
      )
    }

    state.offer?.let {
      OfferDetailsBuyers(sectionNumber++, state)
      OfferBuyersConfirmation(sectionNumber++, state, saveOffer)
      OfferListingDetailsConfirmation(sectionNumber++, state, saveOffer, clock, timeZone)
      OfferBuyerAgentInformation(sectionNumber++, state, saveOffer, clock)
      OfferPrice(sectionNumber++, state, saveOffer)
      OfferClosingDate(sectionNumber++, state, saveOffer, clock)
      OfferAssumableContracts(sectionNumber++, state, saveOffer)
      OfferFixturesExcluded(sectionNumber++, state, saveOffer)
      OfferChattelsIncluded(sectionNumber++, state, saveOffer)
      OfferSellerConditions(sectionNumber++, state, saveOffer)
      OfferBuyerConditions(sectionNumber++, state, saveOffer)
      OfferBuyerInformation(sectionNumber++, state, saveOffer, clock)
      OfferAdditionalRequest(sectionNumber++, state, saveOffer, clock)
      OfferBindingAgreementTerms(sectionNumber++, state, saveOffer, clock)
      OfferExpiry(sectionNumber++, state, saveOffer, clock, timeZone)
    }
  }
}

@Composable
private fun OfferDetailsBuyers(section: Int, state: State) {
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<OfferContactsViewModelConfiguration>()
  val vm = remember(scope) {
    OfferContactsViewModel(
      scope,
      vmConfig,
      OfferContactsEventHandler()
    )
  }

  // Initial expansion state is based on official offer's contact state
  val (expanded, setExpanded) = remember {
    mutableStateOf(
      state.contacts == null ||
        state.contacts!!.buyers.contacts.isEmpty() ||
        state.contacts!!.buyers.contacts.any { !it.anyVerifiedEmailMethods() }
    )
  }

  val contactState by vm.observeStates().collectAsState()

  LaunchedEffect(Unit) {
    vm.send(OfferContactsContract.Inputs.FetchContacts(state.offerId!!, state.party!!))
  }

  DetailContainer(
    itemBuilder = ViewModelBuilder(vm) { contactState },
    opts = {
      value = contactState.offerContacts
      sectionNumber = section
      title = "Name of Buyer(s)"
      popupRef = "offer-buyer-names"
      this.expanded = expanded
      canExpand = state.hasPen && state.party == Party.BUYER && state.offer?.state?.isLocked == false
      onExpand = { setExpanded(it) }
      status = if (
        contactState.offerContacts == null ||
        contactState.offerContacts!!.buyers.contacts.none { it.anyVerifiedEmailMethods() }
      ) {
        DetailStatus.NEW
      } else {
        null
      }
      onSave = {
        vm.trySend(OfferContactsContract.Inputs.SaveContacts)
        setExpanded(false)
      }
      onCancel = {
        // Restore contacts to pre-edit of ContactUI stage. A note, this won't cancel the verification of
        // contacts
        vm.trySend(OfferContactsContract.Inputs.ContactsUpdated(contactState.offerContacts))
        setExpanded(false)
      }
    },
    displayContent = { OfferBuyersDisplay(contactState.offerContacts) },
    editContent = { _, _ -> OfferBuyersEdit(vm, contactState) }
  )
}

@Composable
private fun OfferBuyersConfirmation(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
) {
  val offer = state.offer!!
  DetailContainer(
    itemBuilder = OfferBuyerConfirmationBuilder(),
    opts = {
      value = offer.buyerConfirmation
      sectionNumber = section
      title = "Confirmation of Buyer(s)"
      popupRef = "offer-confirmation-of-buyers"
      expanded = offer.buyerConfirmation == null
      canExpand = state.hasPen && state.party == Party.BUYER && state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(buyerConfirmation = it)) }
    },
    displayContent = { offer.buyerConfirmation?.let { OfferBuyerConfirmationDisplay(it) } },
    editContent = { b, sb ->
      OfferBuyerConfirmationEdit(
        b as OfferBuyerConfirmationBuilder,
        sb
      )
    }
  )
}

@Composable
private fun OfferListingDetailsConfirmation(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
  clock: Clock,
  timeZone: TimeZone,
) {
  val offer = state.offer!!
  state.relatedListing?.let { listing ->
    DetailContainer(
      itemBuilder = ListingPropertyDetailsBuilder(
        listing.jurisdictionOrDefaultForUser(state.sessionUser),
        clock,
        timeZone,
      ),
      opts = {
        value = listing.propertyDetails
        sectionNumber = section
        title = "Acknowledgement of Property Details"
        popupRef = "offer-acknowledgement"
        expanded = offer.listingDetailsAcknowledged != true
        canExpand = state.party == Party.BUYER && state.offer?.state?.isLocked == false
        saveLabel = "Acknowledge"
        status = if (!offer.listingDetailsAcknowledged) DetailStatus.NEW else DetailStatus.COMPLETED
        onSave = { setOffer(offer.baseCopy(listingDetailsAcknowledged = true)) }
      },
      displayContent = { ListingPropertyDetailsDisplay(listing.propertyDetails, listing.currency, Party.BUYER) },
      editContent = { b, sb -> ListingPropertyDetailsEdit(true, b as ListingPropertyDetailsBuilder, sb) }
    )
  }
}

@Composable
private fun OfferBuyerAgentInformation(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
  clock: Clock,
) {
  val offer = state.offer!!
  DetailContainer(
    itemBuilder = OfferBuyerAgentInformationBuilder(
      clock = clock,
      jurisdiction = state.relatedListing.jurisdictionOrDefaultForUser(state.sessionUser)
    ),
    opts = {
      value = offer.buyerAgent
      sectionNumber = section
      title = "Agent Information"
      popupRef = "offer-commission-fees"
      expanded = offer.buyerAgent == null
      canExpand = state.hasPen && state.party == Party.BUYER && state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(buyerAgent = it)) }
    },
    displayContent = { offer.buyerAgent?.let { OfferBuyerAgentInformationDisplay(it) } },
    editContent = { b, sb ->
      OfferBuyerAgentInformationEdit(
        offer,
        b as OfferBuyerAgentInformationBuilder,
        sb
      )
    }
  )
}

@Composable
private fun OfferPrice(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
) {
  val offer = state.offer!!
  DetailContainer(
    itemBuilder = OfferPriceBuilder(state.party!!, state.negotiationStage!!, offer.currency),
    opts = {
      value = offer.price
      sectionNumber = section
      title = "Offer Price"
      popupRef = "offer-initial-offer-price"
      expanded = offer.price == null
      canExpand = state.hasPen &&
        (state.party == Party.BUYER || !state.hasAcceptedOtherOffer()) &&
        state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(price = it)) }
    },
    displayContent = { OfferPriceDisplay(offer, state.relatedListing.jurisdictionOrDefaultForUser(state.sessionUser)) },
    editContent = { b, sb ->
      OfferPriceEdit(
        offer,
        state.relatedListing.jurisdictionOrDefaultForUser(state.sessionUser),
        b as OfferPriceBuilder,
        sb
      )
    }
  )
}

@Composable
private fun OfferClosingDate(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
  clock: Clock,
) {
  val offer = state.offer!!
  val timeZone = state.sessionUser.timeZone()

  DetailContainer(
    itemBuilder = OfferClosingBuilder(state.party!!, state.negotiationStage!!, offer.currency, clock, timeZone),
    opts = {
      value = offer.closing
      sectionNumber = section
      title = "Property Closing Date"
      popupRef = "offer-closing-date"
      expanded = offer.closing == null
      canExpand = state.hasPen &&
        (state.party == Party.BUYER || !state.hasAcceptedOtherOffer()) &&
        state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(closing = it)) }
    },
    displayContent = { OfferClosingDateDisplay(offer.closing!!) },
    editContent = { b, sb ->
      OfferClosingDateEdit(
        b as OfferClosingBuilder,
        sb,
        clock,
        timeZone,
      )
    }
  )
}

@Composable
private fun OfferAssumableContracts(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
) {
  val offer = state.offer!!
  DetailContainer(
    itemBuilder = OfferAssumableContractsBuilder(state.party!!, state.negotiationStage!!, offer.currency),
    opts = {
      value = offer.assumableContracts
      sectionNumber = section
      title = "Assumable Contracts"
      popupRef = "offer-assumable-contracts"
      expanded = offer.assumableContracts == null
      canExpand = state.hasPen && state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(assumableContracts = it)) }
    },
    displayContent = { offer.assumableContracts?.let { OfferAssumableContractListDisplay(it, offer.currency) } },
    editContent = { b, sb ->
      OfferAssumableContractListEdit(
        b as OfferAssumableContractsBuilder,
        sb
      )
    }
  )
}

@Composable
private fun OfferFixturesExcluded(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
) {
  val offer = state.offer!!
  DetailContainer(
    itemBuilder = OfferFixtureExcludedListBuilder(state.party!!, state.negotiationStage!!, offer.currency),
    opts = {
      value = offer.fixturesExcluded
      sectionNumber = section
      title = "Fixtures / Exclusions"
      popupRef = "offer-fixtures"
      expanded = offer.assumableContracts == null
      canExpand = state.hasPen &&
        (state.party == Party.BUYER || !state.hasAcceptedOtherOffer()) &&
        state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(fixturesExcluded = it)) }
    },
    displayContent = { offer.fixturesExcluded?.let { OfferFixturesExcludedListDisplay(it) } },
    editContent = { b, sb ->
      OfferFixturesExcludedListEdit(
        b as OfferFixtureExcludedListBuilder,
        sb
      )
    }
  )
}

@Composable
private fun OfferChattelsIncluded(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
) {
  val offer = state.offer!!
  DetailContainer(
    itemBuilder = OfferChattelsIncludedListBuilder(state.party!!, state.negotiationStage!!, offer.currency),
    opts = {
      value = offer.chattelsIncluded
      sectionNumber = section
      title = "Chattels / Inclusions"
      popupRef = "offer-chattels"
      expanded = offer.assumableContracts == null
      canExpand = state.hasPen &&
        (state.party == Party.BUYER || !state.hasAcceptedOtherOffer()) &&
        state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(chattelsIncluded = it)) }
    },
    displayContent = { offer.chattelsIncluded?.let { ChattelsIncludedListDisplay(it) } },
    editContent = { b, sb ->
      OfferChattelsIncludedListEdit(
        b as OfferChattelsIncludedListBuilder,
        sb
      )
    }
  )
}

@Composable
private fun OfferSellerConditions(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
) {
  state.offer?.let { offer ->
    WithNegotiatedConditionVM { vm, conditionState ->
      fun loadInitialConditions() = vm.trySend(
        ConditionListContract.Inputs.LoadInitialConditions(
          state.party!!,
          ConditionFilters(), //  In draft mode = false, default conditions are not required
          false,
          negotiationState = ConditionListContract.NegotiationState(
            Party.SELLER,
            offer.currency,
            state.negotiationStage!!,
            offer.sellerConditions?.conditions ?: emptyList(),
          )
        )
      )

      LaunchedEffect(offer.sellerConditions?.conditions?.map { it.currentValue.get().optionKey }) {
        loadInitialConditions()
      }

      if (conditionState.loadingState == LoadingState.NOT_LOADED) return@WithNegotiatedConditionVM

      val negotiatedConditionVmState by vm.observeStates().collectAsState()

      DetailContainer(
        itemBuilder = ViewModelBuilder(vm) { negotiatedConditionVmState },
        opts = {
          value = offer.sellerConditions?.conditions
          sectionNumber = section
          title = "Seller Conditions"
          popupRef = "offer-conditions-seller"
          expanded = offer.sellerConditions == null
          canExpand = state.hasPen &&
            (state.party == Party.BUYER || !state.hasAcceptedOtherOffer()) &&
            state.offer?.state?.isLocked == false
          onSave = { setOffer(offer.baseCopy(sellerConditions = OfferSellerConditions(it ?: emptyList()))) }
          onCancel = { loadInitialConditions() }
        },
        displayContent = {
          offer.sellerConditions?.let {
            ConditionsDisplay(
              it.conditions.map { c -> c.toUI(conditionState.getTitle(c.currentValue.get())) },
              editMode = false
            )
          }
        },
        editContent = { _, _ ->
          OfferConditionListNegotiate(conditionState) { vm.trySend(it) }
        }
      )
    }
  }
}

@Suppress("LongMethod")
@Composable
private fun OfferBuyerConditions(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
) {
  state.offer?.let { offer ->
    val stage = state.negotiationStage!!
    val draftMode = stage == NegotiationStage.BUYER_DRAFTING_OFFER

    WithNegotiatedConditionVM { vm, conditionState ->
      fun loadInitialConditions() = vm.trySend(
        ConditionListContract.Inputs.LoadInitialConditions(
          state.party!!,
          state.relatedListing?.buildConditionFilters() ?: ConditionFilters(listOf(ConditionDefaultCriteria.ALWAYS)),
          draftMode,
          negotiationState = ConditionListContract.NegotiationState(
            Party.BUYER,
            offer.currency,
            state.negotiationStage!!,
            offer.buyerConditions?.conditions ?: emptyList(),
          )
        )
      )

      LaunchedEffect(
        offer.buyerConditions?.conditions?.map { it.currentValue.get().optionKey },
        state.relatedListing?.buildConditionFilters()
      ) {
        loadInitialConditions()
      }

      if (conditionState.loadingState == LoadingState.NOT_LOADED) return@WithNegotiatedConditionVM

      val negotiatedConditionVmState by vm.observeStates().collectAsState()

      DetailContainer(
        itemBuilder = ViewModelBuilder(vm) { negotiatedConditionVmState },
        opts = {
          value = offer.buyerConditions?.conditions
          sectionNumber = section
          title = "Buyer Conditions"
          popupRef = "offer-conditions-buyer"
          expanded = offer.buyerConditions == null || (offer.buyerConditions?.conditions?.isEmpty() ?: false)
          canExpand = state.hasPen &&
            (state.party == Party.BUYER || !state.hasAcceptedOtherOffer()) &&
            state.offer?.state?.isLocked == false
          onSave = {
            setOffer(
              offer.baseCopy(buyerConditions = OfferBuyerConditions(it ?: emptyList()))
            )
          }
          onCancel = { loadInitialConditions() }
        },
        displayContent = {
          ConditionsDisplay(
            offer.buyerConditions?.conditions?.map {
              it.toUI(conditionState.getTitle(it.currentValue.get()), draftMode)
            } ?: emptyList(),
            editMode = false
          )
        },
        editContent = { _, _ ->
          if (draftMode) {
            ConditionListEdit(conditionState) { vm.trySend(it) }
          } else {
            OfferConditionListNegotiate(conditionState) { vm.trySend(it) }
          }
        }
      )
    }
  }
}

@Composable
private fun OfferBuyerInformation(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
  clock: Clock,
) {
  val offer = state.offer!!
  DetailContainer(
    itemBuilder = OfferBuyerInformationBuilder(clock = clock),
    opts = {
      value = offer.buyerInformation
      sectionNumber = section
      title = "Information about the Buyer(s)"
      popupRef = when (state.party) {
        Party.BUYER -> "offer-information-about-buyers"
        Party.SELLER -> "offer-information-about-buyers-as-seller"
        else -> null
      }
      expanded = offer.buyerInformation == null
      canExpand = state.hasPen && state.party == Party.BUYER && state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(buyerInformation = it)) }
    },
    displayContent = { offer.buyerInformation?.let { OfferBuyerInformationDisplay(it) } },
    editContent = { b, sb ->
      OfferBuyerInformationEdit(b as OfferBuyerInformationBuilder, sb)
    }
  )
}

@Composable
private fun OfferAdditionalRequest(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
  clock: Clock,
) {
  val offer = state.offer!!
  DetailContainer(
    itemBuilder = OfferAdditionalRequestBuilder(state.party!!, state.negotiationStage!!, offer.currency, clock),
    opts = {
      value = offer.additionalRequest
      sectionNumber = section
      title = "Additional Request to the Seller"
      popupRef = when (state.party) {
        Party.BUYER -> "offer-additional-request"
        Party.SELLER -> "offer-additional-request-as-seller"
        else -> null
      }
      expanded = offer.additionalRequest == null
      canExpand = state.hasPen &&
        (state.party == Party.BUYER || offer.additionalRequest?.additionalRequests?.isNotEmpty() == true) &&
        state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(additionalRequest = it)) }
    },
    displayContent = { offer.additionalRequest?.let { OfferAdditionalRequestDisplay(it, state.negotiationStage!!) } },
    editContent = { b, sb ->
      OfferAdditionalRequestEdit(
        b as OfferAdditionalRequestBuilder,
        sb
      )
    }
  )
}

@Composable
private fun OfferBindingAgreementTerms(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
  clock: Clock,
) {
  val offer = state.offer!!
  DetailContainer(
    itemBuilder = OfferBindingAgreementTermsBuilder(
      state.party!!,
      negotiationStageOf(offer),
      offer.currency,
      clock
    ),
    opts = {
      value = offer.bindingAgreementTerms
      sectionNumber = section
      title = "Binding Agreement Required Days"
      popupRef = when (state.party!!) {
        Party.SELLER -> "listing-binding-agreement-days"
        Party.BUYER -> "offer-binding-agreement"
      }
      expanded = offer.bindingAgreementTerms == null
      canExpand = state.hasPen &&
        (state.party == Party.BUYER || !state.hasAcceptedOtherOffer()) &&
        state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(bindingAgreementTerms = it)) }
    },
    displayContent = { offer.bindingAgreementTerms?.let { OfferBindingAgreementTermsDisplay(it) } },
    editContent = { b, sb -> OfferBindingAgreementTermEdit(b as OfferBindingAgreementTermsBuilder, sb) }
  )
}

/**
 * This one needs a bit of customizations because buyer and seller can go back and forth. On counter, the user
 * can choose to either keep the current expiry or change the number of days. This state is not currently stored
 * on the offer data structure, so we'll manage it locally
 */
@Composable
private fun OfferExpiry(
  section: Int,
  state: State,
  setOffer: (Offer) -> Unit,
  clock: Clock,
  timeZone: TimeZone,
) {
  val offer = state.offer!!
  val stage = negotiationStageOf(offer)
  val expirySetByUs = offer.expiry != null && offer.expiry?.core?.setBy == state.party
  DetailContainer(
    itemBuilder = OfferExpirationBuilder(
      state.party!!,
      clock,
      timeZone,
      otherActiveOfferExpiry = state.otherAcceptedOfferExpiryTime
    ),
    opts = {
      value = offer.expiry
      sectionNumber = section
      title = "${if (stage != NegotiationStage.BUYER_DRAFTING_OFFER) "Counter Offer" else "Offer"} Expiration Period"
      popupRef = "offer-expiration"
      expanded = !expirySetByUs
      status = if (!expirySetByUs) DetailStatus.NEW else null
      canExpand = state.hasPen &&
        (state.party == Party.BUYER || !state.hasAcceptedOtherOffer()) &&
        state.offer?.state?.isLocked == false
      onSave = { setOffer(offer.baseCopy(expiry = it)) }
    },
    displayContent = { OfferExpirationDisplay(state, clock, timeZone) },
    editContent = { b, sb ->
      OfferExpirationEdit(
        state,
        b as OfferExpirationBuilder,
        sb
      )
    }
  )
}

@Composable
private fun SendNewLink(vm: OfferEditScreenViewModel, state: State) {
  state.offerId?.let { offerId ->
    FlexRow({
      classes(DiyStyleSheet.fullWidth)
      style { justifyContent(JustifyContent.Center) }
    }) {
      Div({
        classes(DiyStyleSheet.container, DiyStyleSheet.lightBorder)
        style {
          margin(DiyStyleSheet.Sizes.padding)
          textAlign("center")
        }
      }) {
        if (state.rpcMessage != null) {
          P {
            Text(state.rpcMessage!!)
          }
        } else {
          P({
            style {
              padding(0.px)
              margin(0.px)
              marginBottom(DiyStyleSheet.Sizes.padding)
            }
          }) {
            Text(
              "If you are a buyer working on an offer, your secure link may have expired — " +
                "click the button below to receive a new secure link"
            )
          }
          ActionButton(attrs = {
            onClick { vm.trySend(Inputs.SendSecureLinkByEmail(offerId)) }
          }) {
            Text("Send an updated secure link to my email")
          }
        }
      }
    }
  }
}
