package builders

import com.diyoffer.negotiation.common.bnDivAsPercent
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatPercent
import com.diyoffer.negotiation.common.today
import com.diyoffer.negotiation.model.*
import com.soywiz.kbignum.BigInt
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atTime

data class ListingPriceAndClosingBuilder(
  val currency: Currency,
  val clock: Clock,
  val timeZone: TimeZone,
  val price: Money? = null,
  val closingDate: LocalDate? = null,
  val deposit: Money? = null,
  val withholdOffers: Boolean? = null,
  // TODO change to LocalDateTime
  val withholdDate: LocalDate? = null,
  val errorMessage: String? = null,
) : IBuilder<ListingDetails> {
  companion object {
    val LISTING_PRICE_LOWER_THRESHOLD = BigInt(50_000)
    val LISTING_PRICE_UPPER_THRESHOLD = BigInt(10_000_000)
    val DEPOSIT_PCT_LOWER_THRESHOLD = Percent(2.0)
    val DEPOSIT_PCT_UPPER_THRESHOLD = Percent(10.0)
  }

  override fun hydrate(item: ListingDetails?): IBuilder<ListingDetails> {
    val withholdDate = item?.offerWithholding?.until?.core?.value?.getOrNull()?.date
    return copy(
      price = item?.price?.core?.value?.getOrNull(),
      closingDate = item?.closingDate?.core?.value?.getOrNull(),
      deposit = item?.deposit?.core?.value?.getOrNull(),
      withholdDate = withholdDate,
      withholdOffers = withholdDate != null,
      errorMessage = errorMessage
    )
  }

  override fun build(): BuildResult<ListingDetails> = validateAndBuild {
    ListingDetails(
      price = Auditable.Core(Optional.of(price!!), Clock.System.now().lowRes()),
      closingDate = Auditable.Core(Optional.of(closingDate!!), Clock.System.now().lowRes()),
      deposit = Auditable.Core(Optional.of(deposit!!), Clock.System.now().lowRes()),
      offerWithholding = if (withholdOffers == true) {
        ListingOfferWithholding(
          Auditable.Core(
            withholdDate?.let { Optional.of(it.atTime(hour = 17, minute = 0)) } ?: Optional.absent(),
            Clock.System.now().lowRes(),
          )
        )
      } else {
        null
      },
    )
  }

  @Suppress("ComplexMethod", "LongMethod")
  private fun validateAndBuild(onValid: () -> ListingDetails): BuildResult<ListingDetails> {
    val errors = mutableListOf<String>()
    val warnings = mutableListOf<String>()

    if (price == null) {
      errors.add("Please set a listing price.")
    } else {
      if (price.value >= Int.MAX_VALUE) {
        errors.add("The price is too large.")
      }

      val listingPriceLowerThreshold = Money(LISTING_PRICE_LOWER_THRESHOLD, currency)
      val listingPriceUpperThreshold = Money(LISTING_PRICE_UPPER_THRESHOLD, currency)

      if (price < listingPriceLowerThreshold) {
        warnings.add(
          "Are you sure the listing price is below ${formatCurrency(listingPriceLowerThreshold, currency)}?"
        )
      } else if (price > listingPriceUpperThreshold) {
        warnings.add(
          "Are you sure the listing price is more than ${formatCurrency(listingPriceUpperThreshold, currency)}?"
        )
      }
    }

    if (deposit == null) {
      errors.add("You must define the deposit amount.")
    } else {
      if (deposit.value >= Int.MAX_VALUE) {
        errors.add("The deposit amount is too large.")
      }

      if (price != null) {
        val pct = bnDivAsPercent(deposit.toBigNum(currency), price.toBigNum(currency))
        if (pct < DEPOSIT_PCT_LOWER_THRESHOLD) {
          warnings.add(
            "You are requiring a buyer deposit of only ${formatPercent(pct)} of the listing price. " +
              "Are you sure you want the deposit to be that low?"
          )
        } else if (pct >= DEPOSIT_PCT_UPPER_THRESHOLD) {
          warnings.add(
            "You are requiring a buyer deposit of ${formatPercent(pct)} of the listing price. " +
              "Are you sure you want the deposit to be that high?"
          )
        }
      }
    }

    if (closingDate == null) {
      errors.add("Please specify the closing date.")
    } else if (closingDate <= clock.today(timeZone)) {
      errors.add("Closing date must be later than today.")
    }

    if (errorMessage != null) {
      errors.add(errorMessage)
    }

    if (withholdOffers == true) {
      if (withholdDate == null) {
        errors.add("If you withhold offers, you must provide a date.")
      } else if (withholdDate <= clock.today(timeZone)) {
        errors.add("The withhold date must be set later than today.")
      } else if (closingDate != null && withholdDate > closingDate) {
        errors.add("The withhold date must be before or equal to the closing date.")
      }
    }

    return buildResult(errors, warnings, onValid = onValid)
  }
}
