package com.diyoffer.negotiation.ui.user

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.diyoffer.negotiation.common.services.links.LandingUrl
import com.diyoffer.negotiation.common.services.links.getParty
import com.diyoffer.negotiation.common.services.links.linkFor
import com.diyoffer.negotiation.common.services.links.trimAuth
import com.diyoffer.negotiation.common.services.listings.sortedByClosingDate
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.ui.user.UserContract.Events
import com.diyoffer.negotiation.ui.user.UserContract.Inputs
import com.diyoffer.negotiation.ui.user.UserContract.State
import io.ktor.http.*
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock

typealias UserInputHandlerScope = InputHandlerScope<Inputs, Events, State>

class UserInputHandler(
  private val userRepo: UserRepository,
  private val sellerListingRepo: SellerListingRepository,
  private val clock: Clock,
) : InputHandler<Inputs, Events, State> {
  override suspend fun UserInputHandlerScope.handleInput(input: Inputs) = when (input) {
    is Inputs.InitializeUser -> {
      // Subscribe to user updated coming form auth
      val s = getAndUpdateState { it.copy(initialized = true, landingUrl = LandingUrl(Url(input.url))) }
      if (!s.initialized) {
        observeFlows(
          "UserInputHandler.User",
          userRepo.getUser().filterNotNull().map { Inputs.UserUpdated(it) },
          userRepo.getQueuedPopup().map { Inputs.QueuePopup(it) }
        )
        userRepo.initializeUserAtLoad(input.url)
      } else {
        noOp()
      }
    }

    is Inputs.QueuePopup -> updateState { it.copy(popup = input.popup) }

    is Inputs.ClearPopup -> {
      userRepo.queuePopup(null)
      noOp()
    }

    is Inputs.UserUpdated -> {
      val s = getAndUpdateState {
        it.copy(
          user = input.user,
          listings = Cached.NotLoaded(),
          draftListing = null,
          popup = null,
        )
      }

      val redirectUrl = s.landingUrl?.let { redirectUrl(it, input.user) }

      postEvent(Events.UserLoaded(input.user, redirectUrl))

      if (redirectUrl == null && input.user.role == UserRole.SELLER) {
        observeFlows(
          "UserInputHandler.Listings",
          sellerListingRepo.userListings().map { Inputs.ListingsUpdated(it) }
        )
      } else {
        // cancel observeFlows side jobs, v3 of the ballast api should have an explicit api for this
        sideJob("UserInputHandler.Listings") { }
      }
    }

    is Inputs.ListingsUpdated -> {
      updateState { state ->
        val draftListing = input.listings
          .getCachedOrEmptyList()
          .filter { it.listing is Listing.Draft }
          .sortedByClosingDate(clock.now(), state.user.timeZone())
          .firstOrNull()

        state.copy(listings = input.listings, draftListing = draftListing?.listing as? Listing.Draft)
      }
    }

    is Inputs.SignOut -> {
      userRepo.signOut()
      postInput(Inputs.UserUpdated(SessionUser.NotLoaded))
      postEvent(Events.SignOut)
    }
  }

  /**
   * Evaluate the current url and determine if the user should be rerouted to the canonical URL for his role
   */
  private fun redirectUrl(landingUrl: LandingUrl, user: SessionUser): String? {
    val urlParty = landingUrl.getParty()
    val params = landingUrl.url.parameters
    return when {
      // if we are authorized and our landing url is to /auth, then redirect to the home page instead
      user.role == UserRole.SELLER && landingUrl.url.encodedPath.startsWith("/auth") -> "/"
      user.role == UserRole.SELLER && urlParty == Party.BUYER -> landingUrl.linkFor(Party.SELLER)
      user.role == UserRole.BUYER && urlParty == Party.SELLER -> landingUrl.linkFor(Party.BUYER)
      user.role == UserRole.BUYER && "signature" in params && "random" in params ->
        landingUrl.trimAuth().url.toString()
      else -> null
    }
  }
}
