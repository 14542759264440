package com.diyoffer.negotiation.messages

import com.diyoffer.negotiation.model.*

@Suppress("MaxLineLength")
enum class InfoPopup(
  val title: String,
  val content: Asciidoc,
  val actionLabel: String = "OK, I'm Ready",
  val img: DiyImage? = null,
) {
  NONE(
    title = "",
    content = Asciidoc("")
  ),
  LISTING_PUBLISH_SUCCESS(
    title = "Listing Terms Published Successfully",
    img = DiyImage.SuccessDialogHeader,
    content = Asciidoc(
      text = """
        Congratulations on successfully publishing your listing terms!
        This is one step to getting a successful offer.
        Here is what you need to do next:

        ### 1. Copy your DIYoffer Listing Link

        After you close this dialog box, click the green "Copy Link" button on the left-hand side of the page.

        ### 2. Refer back to your Kijiji - Craigslist - Facebook ads

        In Section 9 of your published terms, you added links to https://www.kijiji.ca/p-post-ad.html?categoryId=35[Kijiji,role=external,window=_blank], https://www.facebook.com/marketplace/create/rental[Facebook Marketplace,role=external,window=_blank], and https://post.craigslist.org/c/search/rea[Craigslist,role=external,window=_blank].
        Now, refer back to the listings and add the property link from DIYoffer Negotiations into the description of your home.
        So it could look like this:
        
        '''
        **3 bedroom 2.5 bath house for sale in a great neighbourhood, close to parks and lots of amenities.**

        **To quickly and easily request a viewing or to submit an offer online go to:**
        **https://buy.diyoffer.com/listing/123-cool-st--toronto**
        
        '''
        **Tip**: You can also send your link by email or post it to your social media.

        ### 3. See and Negotiate Offers

        Interested buyers will click the link and request showings or submit offers.
        You will receive an email each time.

        You can negotiate multiple offers at once, and review the status of each in your dashboard.

        Good luck with your negotiations!!

        ### For Help

        If you need technical assistance at any time, our support support@diyoffer.ca will always be easily accessible to you.
      """.trimIndent(),
    )
  ),
  SELLER_OFFER_COMPLETED(
    title = "Offer Completed",
    img = DiyImage.SuccessDialogHeader,
    content = Asciidoc(
      text = """
        Congratulations on completing your offer through DIY!

        The listing has been marked as sold so prospective buyers cannot submit new offers.

        We hope you enjoyed the experience!
        If so, please share with your friends and family so they can also benefit from stress-free negotiations.

        DIYoffer Team
      """.trimIndent()
    ),
    actionLabel = "Close"
  ),
  OFFER_SUBMIT_SUCCESS(
    title = "You have successfully submitted your offer",
    img = DiyImage.SuccessDialogHeader,
    content = Asciidoc(
      text = """
        Here is what is going to happen next:

        Make sure you keep an eye on your email for any counter-offers from the seller.
        Once all the terms are finalized by the two parties, then, you will need to submit your lawyer and/or REALTOR® information, and you will need to know their email address.

        Then the Term Sheet will be emailed out so the parties can sign the binding contract.

        If you need any further assistance, please feel free to get in touch with DIYoffer at support@diyoffer.ca.

        DIYoffer Team
      """.trimIndent()
    ),
    actionLabel = "Close"
  ),
  BUYER_ACCEPTED_OFFER(
    title = "Congratulations on accepting the offer terms!",
    img = DiyImage.SuccessDialogHeader,
    content = Asciidoc(
      text = """
        Here is what is going to happen next:

        Navigate to your checklist and submit your lawyer and/or REALTOR® information. DIYoffer will then email you and you representative a Term Sheet covering the terms of the offer.

        At this point, parties will sign the actual binding contract, and the offer will be completed.

        If you need any further assistance, please feel free to get in touch with DIYoffer at support@diyoffer.ca.

        DIYoffer Team
      """.trimIndent()
    ),
    actionLabel = "Close"
  ),
  BUYER_VERIFIED_CONTACT(
    title = "",
    content = Asciidoc(
      text = """
        We've successfully validated your email. You may continue to edit this offer. If you want to come back to it later, you must use the secure link that was just sent to your inbox.

        If you need any further assistance, please feel free to get in touch with DIYoffer at support@diyoffer.ca.

        DIYoffer Team
      """.trimIndent()
    ),
    actionLabel = "Close"
  ),
  ;
}
