package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.serdes.*
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

/**
 * All the possible Listing sections. Used for tracking completion at a section level.
 */
@Suppress("MagicNumber")
enum class ListingSection {
  PROPERTY_OWNERS,
  LISTING_DETAILS,
  PROPERTY_DETAILS,
  ASSUMABLE_CONTRACTS,
  FIXTURES_EXCLUDED,
  CHATTELS_INCLUDED,
  SELLER_CONDITIONS,
  BINDING_AGREEMENT_TERMS,
  ;

  companion object {
    fun allExcept(input: List<ListingSection>): List<ListingSection> {
      return (values().toSet() - input.toSet()).toList()
    }
  }
}

fun ListingSection.displayName() = when (this) {
  ListingSection.PROPERTY_OWNERS -> "Property Owner(s)"
  ListingSection.LISTING_DETAILS -> "Price and Closing"
  ListingSection.PROPERTY_DETAILS -> "Property Details"
  ListingSection.ASSUMABLE_CONTRACTS -> "Assumable Contracts"
  ListingSection.FIXTURES_EXCLUDED -> "Fixtures/Exclusions"
  ListingSection.CHATTELS_INCLUDED -> "Chattel/Inclusions"
  ListingSection.SELLER_CONDITIONS -> "Conditions"
  ListingSection.BINDING_AGREEMENT_TERMS -> "Binding Agreement Required Days"
}

@Serializable
sealed class Listing {
  @Serializable(with = UidSerializer::class)
  abstract val _id: Uid<Listing>
  abstract val version: Int
  abstract val currency: Currency
  abstract val state: State
  abstract val events: List<ListingEvent>
  abstract val propertyOwners: ListingPropertyOwners?
  abstract val externalLinks: ListingLinks?
  abstract val details: ListingDetails?
  abstract val propertyDetails: ListingPropertyDetails?
  abstract val assumableContracts: ListingAssumableContracts?
  abstract val fixturesExcluded: ListingFixturesExcluded?
  abstract val chattelsIncluded: ListingChattelsIncluded?
  abstract val sellerConditions: SellerConditions?
  abstract val bindingAgreementTerms: BindingAgreementTerms?
  abstract val marketingInformation: MarketingInformation?
  abstract val permalink: String?
  abstract val zone: TimeZone

  enum class State {
    DRAFT,
    PUBLISHED,

    /** Locked due to an existing offer -- new offers can still be made, and negotiated, but cannot be accepted */
    LOCKED,

    /** The listing was canceled without being completed */
    CANCELED,

    /** The listing was completed i.e. sold */
    COMPLETED,
    ;

    companion object {
      val statesAcceptingOffers = listOf(PUBLISHED, LOCKED)
      val statesAllowingOfferAcceptance = listOf(PUBLISHED)
      val statesAllowingOfferCancellation = listOf(LOCKED)
      val statesAllowingTermSheetGeneration = listOf(LOCKED, /*REMOVE*/ PUBLISHED, COMPLETED)

      val TEST_DRAFT_LISTING_UID = "a0000001-67c9-49f8-9485-f6a80476ed6f".toUid<Listing>()
      val TEST_PUBLISHED_LISTING_UID = "b0000001-67c9-49f8-9485-f6a80476ed6f".toUid<Listing>()
      const val TEST_PUBLISHED_LISTING_PERMALINK = "test-published-listing"
    }
  }

  /**
   * A draft listing differs from a [Listing.Published] in that all the data fields are
   * nullable as they may not yet be created.
   *
   * NOTE there is no permalink property -- this is only available in [Listing.Published].
   */
  @Serializable
  @SerialName("Draft")
  data class Draft(
    @Serializable(with = UidSerializer::class) override val _id: Uid<Listing>,
    override val version: Int,
    override val currency: Currency,
    override val state: State = State.DRAFT,
    override val propertyOwners: ListingPropertyOwners? = null,
    override val permalink: String? = null,
    override val externalLinks: ListingLinks? = null,
    override val details: ListingDetails? = null,
    override val propertyDetails: ListingPropertyDetails? = null,
    override val assumableContracts: ListingAssumableContracts? = null,
    override val fixturesExcluded: ListingFixturesExcluded? = null,
    override val chattelsIncluded: ListingChattelsIncluded? = null,
    override val sellerConditions: SellerConditions? = null,
    override val bindingAgreementTerms: BindingAgreementTerms? = null,
    override val marketingInformation: MarketingInformation? = null,
    override val events: List<ListingEvent> = emptyList(),
    override val zone: TimeZone = TimeZone.of("America/Toronto"),
  ) : Listing() {
    init {
      fun ListingEvent.valid() = this is ListingDraftSavedEvent
      require(events.all(ListingEvent::valid)) {
        "Listing.Draft cannot contain event(s) ${events.filterNot(ListingEvent::valid)}}"
      }
    }
  }

  @Serializable
  @SerialName("Published")
  data class Published(
    @Serializable(with = UidSerializer::class) override val _id: Uid<Listing>,
    override val version: Int,
    override val state: State,
    override val currency: Currency,
    override val propertyOwners: ListingPropertyOwners,
    override val permalink: String,
    override val externalLinks: ListingLinks,
    override val details: ListingDetails,
    override val propertyDetails: ListingPropertyDetails,
    override val assumableContracts: ListingAssumableContracts,
    override val fixturesExcluded: ListingFixturesExcluded,
    override val chattelsIncluded: ListingChattelsIncluded,
    override val sellerConditions: SellerConditions,
    override val bindingAgreementTerms: BindingAgreementTerms,
    override val marketingInformation: MarketingInformation? = null,
    override val events: List<ListingEvent>,
    override val zone: TimeZone = TimeZone.of("America/Toronto"),
  ) : Listing() {
    init {
      require(state != State.DRAFT) { "Published listing cannot have state ${State.DRAFT}." }
    }
  }

  @Suppress("LongParameterList", "ComplexMethod")
  fun baseCopy(
    propertyOwners: ListingPropertyOwners? = null,
    externalLinks: ListingLinks? = null,
    details: ListingDetails? = null,
    propertyDetails: ListingPropertyDetails? = null,
    assumableContracts: ListingAssumableContracts? = null,
    fixturesExcluded: ListingFixturesExcluded? = null,
    chattelsIncluded: ListingChattelsIncluded? = null,
    sellerConditions: SellerConditions? = null,
    bindingAgreementTerms: BindingAgreementTerms? = null,
    marketingInformation: MarketingInformation? = null,
  ) = when (this) {
    is Draft -> copy(
      propertyOwners = propertyOwners ?: this.propertyOwners,
      externalLinks = externalLinks ?: this.externalLinks,
      details = details ?: this.details,
      propertyDetails = propertyDetails ?: this.propertyDetails,
      assumableContracts = assumableContracts ?: this.assumableContracts,
      fixturesExcluded = fixturesExcluded ?: this.fixturesExcluded,
      chattelsIncluded = chattelsIncluded ?: this.chattelsIncluded,
      sellerConditions = sellerConditions ?: this.sellerConditions,
      bindingAgreementTerms = bindingAgreementTerms ?: this.bindingAgreementTerms,
      marketingInformation = marketingInformation ?: this.marketingInformation,
    )
    is Published -> copy(
      propertyOwners = propertyOwners ?: this.propertyOwners,
      externalLinks = externalLinks ?: this.externalLinks,
      details = details ?: this.details,
      propertyDetails = propertyDetails ?: this.propertyDetails,
      assumableContracts = assumableContracts ?: this.assumableContracts,
      fixturesExcluded = fixturesExcluded ?: this.fixturesExcluded,
      chattelsIncluded = chattelsIncluded ?: this.chattelsIncluded,
      sellerConditions = sellerConditions ?: this.sellerConditions,
      bindingAgreementTerms = bindingAgreementTerms ?: this.bindingAgreementTerms,
      marketingInformation = marketingInformation ?: this.marketingInformation,
    )
  }
}

@Serializable
data class ListingPropertyOwners(
  val contacts: List<Contact>,
  val userCertifiedLegalAuthority: Auditable<Boolean>,
)

@Serializable
data class ListingLinks(
  val links: List<Link>,
) {
  @Serializable
  data class Link(
    val provider: ListingProvider,
    val url: String,
  ) {
    enum class ListingProvider(val friendlyName: String, val domain: String, val createListingUrl: String?) {
      KIJIJI("Kijiji", "kijiji", "https://www.kijiji.ca/p-post-ad.html?categoryId=35"),
      FACEBOOK("Facebook", "facebook", "https://www.facebook.com/marketplace/create/rental"),
      CRAIGSLIST("Craig's List", "craigslist", "https://post.craigslist.org/c/search/rea"),
      MLS("MLS", "mls.com", null),
      REALTOR_DOT_CA("Realtor.ca", "realtor.ca", null),
      OTHER("Other", "", null),
      ;
    }
  }
}

@Serializable
data class ListingDetails(
  val price: Auditable<Money>,
  val closingDate: Auditable<LocalDate>,
  val deposit: Auditable<Money>,
  val offerWithholding: ListingOfferWithholding?,
)

@Serializable
data class ListingOfferWithholding(
  val until: Auditable<LocalDateTime>,
)

@Serializable
data class ListingPropertyDetails(
  val address: Address,
  val propertyOwnership: PropertyOwnership,
  val propertyTaxes: PropertyTaxes,
  val lotServices: List<OptionKey>,
  val activeMortgage: Boolean,
)

@Serializable
data class PropertyTaxes(
  val year: Int,
  val amount: Money,
  val rollNumber: String?,
)

/**
 * This is a sealed class rather than a sealed interface due to
 * https://github.com/Kotlin/kotlinx.serialization/issues/1417.
 */
@Serializable
sealed class PropertyOwnership : OptionKey.Predefined() {
  enum class Type(val title: String, val isApartmentStyle: Boolean) {
    FREEHOLD("Freehold", false),
    CONDO_STRATA("Condo / Strata", true),
    TIMESHARE_FRACTIONAL("Timeshare / Fractional", true),
    LEASEHOLD("Leasehold", true),
    ;
    companion object {
      fun Type.taxRollOptionality(): FieldOptionality = when (this) {
        FREEHOLD -> FieldOptionality.REQUIRED
        CONDO_STRATA -> FieldOptionality.REQUIRED
        TIMESHARE_FRACTIONAL -> FieldOptionality.OPTIONAL
        LEASEHOLD -> FieldOptionality.UNAVAILABLE
      }
    }
  }

  @Serializable
  sealed class ApartmentStyle : PropertyOwnership() {
    abstract val parkingSpaces: String?
    abstract val lockers: String?
  }

  abstract val type: Type
  abstract val monthlyFees: Money?

  @Serializable
  @SerialName("freehold")
  data class Freehold(
    override val monthlyFees: Money? = null,
    @Transient
    override val type: Type = Type.FREEHOLD,
  ) : PropertyOwnership()

  @Serializable
  @SerialName("condoStrata")
  data class CondoStrata(
    override val monthlyFees: Money,
    override val parkingSpaces: String?,
    override val lockers: String?,
    @Transient
    override val type: Type = Type.CONDO_STRATA,
  ) : ApartmentStyle()

  @Serializable
  @SerialName("timeshareFractional")
  data class TimeshareFractional(
    override val monthlyFees: Money,
    override val parkingSpaces: String?,
    override val lockers: String?,
    @Transient
    override val type: Type = Type.TIMESHARE_FRACTIONAL,
  ) : ApartmentStyle()

  @Serializable
  @SerialName("leasehold")
  data class Leasehold(
    override val monthlyFees: Money,
    override val parkingSpaces: String?,
    override val lockers: String?,
    @Transient
    override val type: Type = Type.LEASEHOLD,
  ) : ApartmentStyle()
}

@Serializable
data class ListingAssumableContracts(
  val contracts: List<Auditable<AssumableContract>>,
)

@Serializable
data class ListingFixturesExcluded(
  val fixturesExcluded: List<Auditable<FixtureExclusion>>,
)

@Serializable
data class ListingChattelsIncluded(
  val chattelsIncluded: List<Auditable<ChattelInclusion>>,
)

@Serializable
data class SellerConditions(
  val conditions: List<Auditable<Condition>>,
)

@Serializable
data class BindingAgreementTerms(
  val days: Auditable<Days>,
)

@Serializable
data class MarketingInformation(
  val description: String,
  val thingsYouNeedToKnow: List<String> = emptyList(),
  val details: List<ListingLandingDetail> = emptyList(),
)

@Serializable
data class ListingLandingDetail(
  val name: String,
  val description: String,
)
