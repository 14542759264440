@file:Suppress("MagicNumber", "UnusedPrivateMember")

package pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import app.softwork.routingcompose.navigate
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.copperleaf.ballast.repository.cache.isLoading
import com.diyoffer.negotiation.common.formatDateTime
import com.diyoffer.negotiation.common.safeCast
import com.diyoffer.negotiation.messages.DiyImage
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.Inputs
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.options
import com.diyoffer.negotiation.ui.checklist.ChecklistTabEventHandler
import com.diyoffer.negotiation.ui.checklist.ChecklistUI
import common.ActionButton
import common.ConfirmDialog
import common.FlexRow
import common.HelpChip
import common.Icon
import common.IconName
import common.TextField
import common.ValidationMessage
import components.AsciidocContent
import components.Loading
import components.NoItemDisplay
import components.checklist.icon
import components.checklist.toClassName
import components.snackbar.Snackbar
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.MDCIconType
import model.RoutingParams
import model.backRouteParam
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.paddingMd
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.WhatTodoStyleSheet
import style.WhatTodoStyleSheet.checkedTitle
import style.WhatTodoStyleSheet.checklistItem
import style.WhatTodoStyleSheet.container
import style.WhatTodoStyleSheet.gap10
import style.WhatTodoStyleSheet.subTitle
import style.WhatTodoStyleSheet.title
import style.WhatTodoStyleSheet.titleContainer
import vm.checklist.ChecklistTabViewModel
import vm.checklist.ChecklistTabViewModelConfiguration

@Composable
@Suppress("CyclomaticComplexMethod")
fun ChecklistArea(initializer: Inputs = Inputs.InitializeForSeller) {
  Style(WhatTodoStyleSheet)

  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<ChecklistTabViewModelConfiguration>()
  val vm = remember(scope) {
    ChecklistTabViewModel(
      config = vmConfig,
      viewModelCoroutineScope = scope,
      eventHandler = ChecklistTabEventHandler {
        router.navigate(it, RoutingParams.BackRoutes.checklist.backRouteParam())
      }
    )
  }
  val state by vm.observeStates().collectAsState()

  LaunchedEffect(Unit) { vm.send(initializer) }

  state.error?.let { Snackbar(it) { vm.trySend(Inputs.SetError(null)) } }

  val checklistsLoading = when (state.party) {
    Party.BUYER -> state.offerChecklists.isLoading()
    Party.SELLER -> state.offerChecklists.isLoading() || state.listingChecklists.isLoading()
    null -> false
  }

  when {
    checklistsLoading -> Loading("Loading Checklist")
    state.showSuccessImage -> Img(DiyImage.SuccessDialogHeader.src)
    else -> {
      val listingChecklists = state.listingChecklists.getCachedOrEmptyList()
      if (listingChecklists.isNotEmpty()) {
        listingChecklists.map { ChecklistContainer(vm, it) }
      }

      // I don't believe this `select` parameter is ever set anywhere (use a constant in places like this next time!)
      val selected = router.currentPath.parameters?.map?.get("select")?.getOrNull(0)
      val offerChecklists = state.offerChecklists.getCachedOrEmptyList()
      if (offerChecklists.isNotEmpty()) {
        offerChecklists
          .filter { selected == null || selected == it.listingOrOfferId }
          .map { ChecklistContainer(vm, it) }
      }

      if (state.party == Party.SELLER && listingChecklists.isEmpty() && offerChecklists.isEmpty()) {
        NoItemDisplay(
          "Nothing to do right now",
          "Once a listing or offer is created, you'll see a list of things to do here as offers are made and negotiated."
        )
      }
    }
  }
}

@Composable
private fun ChecklistContainer(vm: ChecklistTabViewModel, checklistUI: ChecklistUI) {
  Div({
    id("checklist-${checklistUI.listingOrOfferId}")
    classes(DiyStyleSheet.mainForm)
    style {
      marginBottom(paddingMd)
      property("z-index", DiyStyleSheet.ZIndex.FORM)
    }
  }) {
    Div({
      classes(flex, flexColumn, container)
    }) {
      Div({
        classes(flex, alignItemsCenter, titleContainer)
      }) {
        Icon(IconName.CHECK_LIST) {
          style {
            width(24.px)
            height(24.px)
          }
        }
        Span({
          classes(title)
        }) {
          Text(
            "Checklist for ${if (checklistUI is ChecklistUI.OfferChecklistUI) "offer" else "terms"} " +
              "(${checklistUI.address ?: "Draft"})"
          )
        }
      }
      ChecklistItem(vm, checklistUI)
    }
  }
}

@Composable
private fun ChecklistItem(
  vm: ChecklistTabViewModel,
  ui: ChecklistUI,
) {
  val checklist = ui.checklist
  Div({
    classes("checklist-area")
    classes(flex, flexColumn, checklistItem)
  }) {
    Div({
      classes(flex, gap10, alignItemsCenter)
    }) {
      checklist.status.icon { style { fontSize(20.px) } }
      Span({
        if (checklist.status == ChecklistStatus.COMPLETE) classes(checkedTitle)
      }) {
        AsciidocContent(checklist.summary)
      }
    }

    if (checklist.items.isNotEmpty()) {
      Ul {
        checklist.items.map {
          Li {
            FlexRow({
              style {
                padding(0.px)
              }
            }) {
              it.status.icon()
              when (it) {
                is ChecklistItemMarkup -> AsciidocContent(it.text)
                else -> Text("Not implemented")
              }
            }
            ChecklistActionArea(vm, ui, it.actions)
          }
        }
        if ((ui as? ChecklistUI.OfferChecklistUI)?.withheld == true) {
          FlexRow({
            style {
              padding(0.px)
            }
          }) {
            MDCIconSpan(MDCIcon.CalendarToday, MDCIconType.TwoTone) {
              classes(DiyStyleSheet.TwoToneColorFilters.YELLOW.styleClass)
            }
            Text("Offer withheld until ${formatDateTime(ui.withheldUntil!!)}")
          }
        }
      }
    }
    ValidationMessage(ui.error)
    ChecklistActionArea(vm, ui, ui.checklist.actions)
  }
}

@Composable
fun ChecklistActionArea(
  vm: ChecklistTabViewModel,
  ui: ChecklistUI,
  actions: List<ChecklistAction>,
) {
  actions.map {
    when (it.options.type) {
      ChecklistTabContract.ChecklistActionType.BUTTON -> ChecklistActionButton(vm, it)
      ChecklistTabContract.ChecklistActionType.FORM -> ChecklistActionForm(vm, ui, it)
    }
  }
}

@Composable
fun ChecklistActionButton(vm: ChecklistTabViewModel, action: ChecklistAction) {
  val (showDialog, setShowDialog) = remember { mutableStateOf(false) }
  action.options.validationMessage?.let { validationMessage ->
    ConfirmDialog(
      showDialog,
      "Confirmation for \"${action.options.label}\"",
      requireReason = action.options.provideReason,
      onConfirm = {
        vm.trySend(Inputs.SetReason(it))
        vm.trySend(Inputs.ActionClicked(action))
        setShowDialog(false)
      },
      onCancel = { setShowDialog(false) }
    ) {
      AsciidocContent(validationMessage)
    }
  }

  FlexRow({ style { marginBottom(0.px) } }) {
    ActionButton(
      attrs = {
        classes(action.toClassName())
        style { width(150.px) }
        onClick {
          if (action.options.validationMessage != null) {
            setShowDialog(true)
          } else {
            vm.trySend(Inputs.ActionClicked(action))
          }
        }
      }
    ) {
      Text(action.options.label)
    }
    action.options.popupRef?.let { HelpChip(it, 20.px) }
  }
}

@Composable
fun ChecklistActionForm(
  vm: ChecklistTabViewModel,
  ui: ChecklistUI,
  action: ChecklistAction,
) {
  when (action) {
    is ChecklistAction.LegalContactForm -> {
      ui.safeCast<ChecklistUI.OfferChecklistUI>()?.let { LawyerInformation(vm, it, action) }
    }

    else -> Unit
  }
}

@Composable
private fun LawyerInformation(
  vm: ChecklistTabViewModel,
  ui: ChecklistUI.OfferChecklistUI,
  action: ChecklistAction.LegalContactForm,
) {
  Div({
    classes(flex, flexColumn, subTitle)
    style {
      marginLeft(24.px)
      width(300.px)
    }
  }) {
    TextField(opts = {
      label = "Name"
      value = ui.legalName
      onModified = { vm.trySend(Inputs.UpdateLegalContact(ui.offerId, name = it, email = ui.legalEmail)) }
    })

    TextField(opts = {
      label = "Email"
      value = ui.legalEmail
      onModified = { vm.trySend(Inputs.UpdateLegalContact(ui.offerId, name = ui.legalName, email = it)) }
    })

    Div({ style { width(120.px) } }) {
      ActionButton(
        disabled = ui.error != null,
        attrs = { onClick { vm.trySend(Inputs.ActionClicked(action)) } }
      ) {
        Text("Save")
      }
    }
  }
}
