package components.listing

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import app.softwork.routingcompose.navigate
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.Inputs
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.SideBarListingUI
import com.diyoffer.negotiation.ui.listing.SideBarListingsEventHandler
import com.diyoffer.negotiation.ui.offer.TagColor
import common.ActionButton
import common.Button
import common.ConfirmDialog
import common.FlexRow
import common.Icon
import common.IconName
import common.Tag
import components.snackbar.Snackbar
import dev.petuska.kmdc.circular.progress.MDCCircularProgress
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.MDCIconType
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import model.backRouteParam
import model.routeData
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.DiyStyleSheet.onLightRed
import style.DiyStyleSheet.onLightYellow
import style.GridStyleSheet.alignItemsStart
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.GridStyleSheet.justifyContentBetween
import style.GridStyleSheet.justifyContentCenter
import style.WelcomeCardStyleSheet
import style.WelcomeCardStyleSheet.sideBarContainer
import style.WelcomeCardStyleSheet.sideBarListedDate
import style.WelcomeCardStyleSheet.sideBarListingAddress
import style.WelcomeCardStyleSheet.sideBarListingInfo
import style.WelcomeCardStyleSheet.sideBarListingItem
import style.WelcomeCardStyleSheet.sideBarListingItemExpand
import style.WelcomeCardStyleSheet.sideBarListingStreet
import style.WelcomeCardStyleSheet.sideBarListings
import style.WelcomeCardStyleSheet.sideBarLocation
import style.WelcomeCardStyleSheet.sideBarTitle
import vm.listing.SideBarListingsViewModel
import vm.listing.SideBarListingsViewModelConfiguration

@Composable
@Suppress("LongMethod")
fun SideBarListings() {
  Style(WelcomeCardStyleSheet)

  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<SideBarListingsViewModelConfiguration>()
// todo loading state
  val vm = remember(scope) {
    SideBarListingsViewModel(
      config = vmConfig,
      eventHandler = SideBarListingsEventHandler(
        onNavigateToListingEditScreen = { listingId, returnRouteData ->
          router.navigate(
            "/listing/edit/$listingId",
            returnRouteData.backRouteParam(),
          )
        },
        onNavigateToListingDetailsScreen = { permalink, returnRouteData ->
          router.navigate(
            permalink,
            returnRouteData.backRouteParam(),
          )
        }
      ),
      viewModelCoroutineScope = scope
    )
  }
  val state by vm.observeStates().collectAsState()

  LaunchedEffect(Unit) {
    vm.send(Inputs.FetchListing)
  }

  LaunchedEffect(router.currentPath) {
    vm.send(Inputs.ReturnRouteData(router.currentPath.routeData()))
  }

  if (state.error != null) {
    Snackbar(state.error) { vm.trySend(Inputs.ClearError) }
  }

  Div({
    classes(sideBarContainer)
  }) {
    Div({ classes(sideBarTitle) }) {
      Span {
        Text("CURRENT TERMS")
      }
    }
    Div({ classes(sideBarListings) }) {
      if (state.loadingState.isLoading()) {
        MDCCircularProgress()
      } else if (state.sideBarListings.isEmpty()) {
        Div({ style { property("margin", "0 auto") } }) {
          ActionButton(attrs = {
            style { padding(14.px, 24.px) }
            onClick { router.navigate("/listing/create", hide = true) }
          }) {
            Text("Create listing terms")
          }
        }
      } else {
        state.sideBarListings.map { model ->
          SideBarListing(vm, model)
        }
      }
    }
  }
}

@Composable
private fun SideBarListing(vm: SideBarListingsViewModel, model: SideBarListingUI) {
  require(model.uid != null)
  Div(attrs = {
    classes(sideBarListingItem)
  }) {
    Div({
      classes(sideBarListingInfo)
      onClick {
        vm.trySend(Inputs.ExpandClicked(model.uid!!, !model.expanded))
      }
      if (!model.expanded) {
        style {
          property("border-top-color", "#E1E7ED")
          property("border-left-color", "#E1E7ED")
          property("border-right-color", "#E1E7ED")
        }
      }
    }) {
      ListingAddress(model)
      if (model.expanded) {
        ListingPrice(model)
        ListingButton(model, vm)
      }
    }
    Div({
      classes(sideBarListingItemExpand, "pointer")
      if (!model.expanded) {
        style {
          property("border", "1px solid #E1E7ED")
        }
      }
      onClick {
        vm.trySend(Inputs.ExpandClicked(model.uid!!, !model.expanded))
      }
    }) {
      Icon(if (model.expanded) IconName.ARROW_UP else IconName.ARROW_DOWN)
    }
  }
}

@Composable
private fun ListingAddress(model: SideBarListingUI) {
  Div({ classes(flex, justifyContentBetween, alignItemsStart) }) {
    Div({ classes(sideBarListingAddress) }) {
      model.listedDateLabel?.let {
        Span({ classes(sideBarListedDate) }) {
          Text(it)
        }
      }
      model.streetLabel?.let {
        Span({ classes(sideBarListingStreet) }) {
          Text(it)
        }
      }
      Div({ classes(sideBarLocation) }) {
        model.city?.let { Tag(it, IconName.LOCATION_BLUE, color = TagColor.BLUE) }
        model.provinceState?.let {
          Span({
            style {
              fontWeight(DiyStyleSheet.Weights.semiBold)
              color(DiyStyleSheet.Colors.blue)
            }
          }) {
            Text(it.name)
          }
        }
      }
    }
    Icon(if (model.isDraft) IconName.CIRCLE_GREY else IconName.CHECKED_CIRCLE)
  }
}

@Composable
private fun ListingPrice(model: SideBarListingUI) {
  if (model.expanded) {
    Div({
      classes(flex)
      style {
        gap(48.px)
        marginTop(24.px)
      }
    }) {
      Div({
        classes(flex, flexColumn)
        style { gap(8.px) }
      }) {
        Span({
          style { color(DiyStyleSheet.Colors.darkGrey) }
        }) {
          Text("Listing price")
        }
        model.priceLabel?.let {
          Span({
            style {
              color(DiyStyleSheet.Colors.darkBlue)
              fontSize(28.px)
              fontWeight(
                DiyStyleSheet.Weights.darkNormal
              )
            }
          }) {
            Text(it)
          }
        }
      }
      Div({
        classes(flex, flexColumn)
        style { gap(8.px) }
      }) {
        Span({
          style { color(DiyStyleSheet.Colors.darkGrey) }
        }) {
          Text("Offers")
        }
        Span({
          style {
            color(DiyStyleSheet.Colors.darkBlue)
            fontSize(28.px)
            fontWeight(
              DiyStyleSheet.Weights.darkNormal
            )
          }
        }) {
          Text(if ((model.offerCount ?: 0) > 0) model.offerCount.toString() else "--")
        }
      }
    }
  }
}

@Suppress("LongMethod")
@Composable
private fun ListingButton(model: SideBarListingUI, vm: SideBarListingsViewModel) {
  val (cancelRequested, setCancelRequested) = remember { mutableStateOf(false) }
  val scope = rememberCoroutineScope()

  model.uid?.let { listingId ->
    Div({
      classes(flex, flexColumn, justifyContentCenter)
      style {
        gap(16.px)
        marginTop(24.px)
      }
    }) {
      // You can view the public listing if there is a public url
      model.publicUrl?.let { url ->
        ActionButton(attrs = {
          style { padding(4.px) }
          onClick {
            it.stopPropagation()
            scope.launch {
              window.navigator.clipboard.writeText(url.toString()).await()
              window.alert("The public facing link to this listing was copied to your clipboard.")
            }
          }
        }) {
          FlexRow({
            style {
              justifyContent(JustifyContent.Center)
              gap(8.px)
              marginBottom(0.px)
            }
          }) {
            MDCIconSpan(MDCIcon.Link, MDCIconType.Outlined) { style { color(DiyStyleSheet.Colors.white) } }
            Text("Copy Link")
          }
        }
      }
      model.uid?.toString()?.let {
        Button(attrs = {
          classes(onLightYellow)
          onClick {
            it.stopPropagation()
            vm.trySend(Inputs.ViewListing(listingId, true))
          }
        }) {
          Text(
            when {
              model.isDraft -> "Edit Draft Listing Terms"
              model.offerCount == 0 -> "Edit Published Listing Terms"
              else -> "View Listing Terms"
            }
          )
        }
      }
      if (model.cancellable) {
        Button(attrs = {
          classes(onLightRed)
          onClick {
            it.stopPropagation()
            setCancelRequested(true)
          }
        }) {
          Text("Cancel Terms")
        }
      }
    }
    ConfirmDialog(
      opened = cancelRequested,
      title = "Are you sure you want to cancel these listing terms?",
      onCancel = { setCancelRequested(false) },
      onConfirm = { vm.trySend(Inputs.CancellationConfirmed(listingId)) }
    ) {
      P {
        Text(
          "This operation will permanently remove the terms of your listing from DIY Negotiations " +
            "and you won't be able to access them again."
        )
      }
      if (!model.isDraft) {
        P {
          Text(" All interested buyers will be notified that this listing is no longer available.")
        }
      }
    }
  }
}
