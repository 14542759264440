package com.diyoffer.negotiation.ui.offer

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.cache.getCachedOrNull
import com.copperleaf.ballast.repository.cache.isLoading
import com.diyoffer.negotiation.common.services.offers.negotiationStageOf
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.messages.InfoPopup
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.ILinksRpcService
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract.Events
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract.Inputs
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract.State
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.datetime.Clock
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime

/**
 * This allows to interact with an offer on the OfferEditPage. Buyer will initially create a Draft, but
 * Buyer and Seller will then be interacting with Offer.Published to counter. The offer are available on
 * /offer/edit/{uid}
 */
class OfferEditScreenInputHandler(
  private val buyerOfferRepo: BuyerOfferRepository,
  private val sellerListingRepo: SellerListingRepository,
  private val sellerOfferRepo: SellerOfferRepository,
  private val linkRpcService: ILinksRpcService,
  private val userRepo: UserRepository,
  private val clock: Clock,
) : InputHandler<Inputs, Events, State> {
  @Suppress("ComplexMethod", "NestedBlockDepth", "LongMethod")
  override suspend fun InputHandlerScope<Inputs, Events, State>.handleInput(input: Inputs): Unit = when (input) {
    is Inputs.FetchEditOffer -> {
      val s = getAndUpdateState {
        it.copy(
          offerId = input.offerId,
          loadingState = LoadingState.FETCHING,
          initialized = true
        )
      }
      if (!s.initialized) {
        observeFlows(
          "OfferEditScreenInputHandler",
          userRepo.getUser().filterNotNull().map { Inputs.UserUpdated(it) },
          sellerOfferRepo.listings()
            .combine(userRepo.getUser()) { listings, user -> Inputs.ListingsReceived(listings, user) },
        )
      } else {
        noOp()
      }
    }

    is Inputs.UserUpdated -> {
      val s = updateStateAndGet { it.copy(sessionUser = input.user) }
      fun cancelObserveFlowsSideJobs() {
        // cancel observeFlows side jobs, v3 of the ballast api should have an explicit api for this
        sideJob("OfferEditScreenInputHandler.BUYER") { }
        sideJob("OfferEditScreenInputHandler.SELLER") { }
      }
      when {
        input.user.role == UserRole.BUYER && s.offerId != null -> observeFlows(
          "OfferEditScreenInputHandler.BUYER",
          // the buyerOfferRepo should combine the offer and related listing into one flow, we shouldn't have
          //  to deal with this here -- see ChecklistRepositoryInputHandler for some logic that can be moved
          //  upstream -- not done yet to avoid a refactor here
          buyerOfferRepo.fetchOffer(s.offerId).filter { !it.isLoading() }.map {
            Inputs.EditOfferUpdated(Party.BUYER, it.getCachedOrNull())
          },
          buyerOfferRepo.relatedListing().filter { !it.isLoading() }
            .map { Inputs.BuyerRelatedListingUpdated(it.getCachedOrNull()) }
        )

        input.user.role == UserRole.SELLER && s.offerId != null -> observeFlows(
          "OfferEditScreenInputHandler.SELLER",
          sellerOfferRepo.listingOffers().mapNotNull { offerCached ->
            when (offerCached) {
              is Cached.NotLoaded,
              is Cached.Fetching,
              -> null
              is Cached.FetchingFailed ->
                Inputs.SetError(error = "Error loading offer. ${CommonMessages.contactAdministrator}")
              is Cached.Value ->
                Inputs.EditOfferUpdated(Party.SELLER, offerCached.value.find { it.first._id == s.offerId })
            }
          },
        )

        s.offerId == null -> {
          updateState { it.copy(loadingState = LoadingState.FETCHING) }
          cancelObserveFlowsSideJobs()
        }
        else -> {
          updateState { it.copy(loadingState = LoadingState.UNAUTHORIZED) }
          cancelObserveFlowsSideJobs()
        }
      }
    }

    is Inputs.EditOfferUpdated -> {
      val s = updateStateAndGet { it.copy(party = input.party) }
      when {
        input.offer != null -> postInput(Inputs.DraftOfferUpdated(input.offer.first, input.offer.second))
        s.offerId != null -> updateState { it.copy(loadingState = LoadingState.UNAUTHORIZED) }
        else -> updateState { it.copy(loadingState = LoadingState.FETCHING) }
      }
    }

    is Inputs.ListingsReceived -> updateState { state ->
      state.copy(
        listings = input.listings,
        sessionUser = input.user,
      )
    }

    is Inputs.BuyerRelatedListingUpdated -> updateState { state ->
      val listing = input.listingRes?.listing
      val withholdingDateTime = listing?.details?.offerWithholding?.until?.core?.valueOrNull()
      val offerWithheld = withholdingDateTime?.let {
        clock.now() < it.toInstant(state.sessionUser.timeZone())
      } ?: false

      state.copy(
        relatedListing = listing as? Listing.Published,
        relatedListingWithholdingDateTime = withholdingDateTime,
        offerWithheld = offerWithheld,
        otherAcceptedOfferExpiryTime = input.listingRes?.otherAcceptedOfferExpiryTime?.let {
          if (it.first !== state.offerId) it.second else null
        },
        // Amend canSubmit with newly received withholding which is received after the offer
        canSubmit = state.canSubmit && !offerWithheld
      )
    }

    is Inputs.DraftOfferUpdated -> {
      val s = getCurrentState()
      val stage = negotiationStageOf(input.offer)
      val hasPen = when (s.party) {
        Party.SELLER -> stage == NegotiationStage.SELLER_COUNTERING
        Party.BUYER -> stage == NegotiationStage.BUYER_DRAFTING_OFFER || stage == NegotiationStage.BUYER_COUNTERING
        else -> false
      }
      updateState { state ->
        state.copy(
          offer = input.offer,
          negotiationStage = stage,
          contacts = input.contacts ?: s.contacts,
          loadingState = LoadingState.READY,
          hasPen = hasPen,
          offerExpiryTime = input.offer.expiry?.instant?.instant?.toLocalDateTime(state.sessionUser.timeZone()),
          canSubmit = hasPen &&
            state.party != null &&
            input.offer.canSubmit(state.party) &&
            (input.contacts?.buyers?.contacts?.any { it.anyVerifiedEmailMethods() } == true)
        )
      }
      if (s.party == Party.SELLER) {
        s.listings.getCachedOrNull()?.find { it.listing._id == input.offer.onListing }?.let {
          postInput(Inputs.BuyerRelatedListingUpdated(it))
        } ?: Unit
      } else {
        Unit
      }
    }

    is Inputs.SaveOffer -> {
      val s = getCurrentState()
      if (s.offer != input.offer) {
        saveOffer(saveHandler = {
          when (s.party) {
            Party.BUYER -> buyerOfferRepo.saveOffer(input.offer) to null
            Party.SELLER -> sellerOfferRepo.saveOffer(input.offer) to null
            else -> OfferSaveResult.Unauthenticated to null
          }
        })
      } else {
        noOp()
      }
    }

    is Inputs.OfferActionClicked -> {
      val s = getCurrentState()
      require(s.offer != null && s.offerId != null)
      saveOffer(input.action, saveHandler = {
        when {
          s.party == Party.BUYER && input.action == OfferActionType.ACCEPT ->
            buyerOfferRepo.acceptOffer(s.offerId) to InfoPopup.BUYER_ACCEPTED_OFFER

          s.party == Party.BUYER && input.action == OfferActionType.PUBLISH ->
            buyerOfferRepo.publishOffer(s.offer) to
              // Only prompt "Congrats" message on first publish
              (if (s.negotiationStage == NegotiationStage.BUYER_DRAFTING_OFFER) InfoPopup.OFFER_SUBMIT_SUCCESS else null)

          s.party == Party.BUYER && input.action == OfferActionType.REJECT ->
            buyerOfferRepo.rejectOffer(s.offerId) to null

          s.party == Party.SELLER && input.action == OfferActionType.ACCEPT ->
            sellerOfferRepo.acceptOffer(s.offerId) to null

          s.party == Party.SELLER && input.action == OfferActionType.PUBLISH ->
            sellerOfferRepo.publishOffer(s.offer) to null

          s.party == Party.SELLER && input.action == OfferActionType.REJECT ->
            sellerOfferRepo.rejectOffer(s.offerId) to null

          else -> OfferSaveResult.Unauthenticated to null
        }
      })
    }

    is Inputs.SendSecureLinkByEmail -> tryRpc(onException = { _, e ->
      updateState {
        it.copy(
          rpcMessage = "Could not send a secure link. ${CommonMessages.contactAdministrator(e)}"
        )
      }
    }) {
      when (val res = linkRpcService.sendBuyerOfferLink(UidValue(input.offerId))) {
        is SendBuyerOfferLinkResult.Success -> updateState {
          it.copy(
            rpcMessage = "We just emailed the secured link to the buyer contact listed on the offer. " +
              "Please check your email and use the link therein to access your offer."
          )
        }

        else -> updateState {
          it.copy(
            rpcMessage = res.message()
          )
        }
      }
    }

    is Inputs.SetError -> {
      updateState { it.copy(error = input.error, loadingState = LoadingState.ERROR) }
    }
  }

  // TODO this code is essentially the same as SellerOfferTabInputHandler withRpc, consider refactoring
  private suspend fun InputHandlerScope<Inputs, Events, State>.saveOffer(
    actionType: OfferActionType? = null,
    saveHandler: suspend () -> Pair<OfferSaveResult, InfoPopup?>,
  ) {
    val s = getAndUpdateState { it.copy(loadingState = LoadingState.FETCHING) }
    fun userErrorMsg(e: Exception? = null) =
      "An error occurred while saving your offer. ${CommonMessages.contactAdministrator(e)}"
    tryRpc(onException = { _, e -> postInput(Inputs.SetError(userErrorMsg(e))) }) {
      val r = saveHandler()
      when (val offerSaveResult = r.first) {
        is OfferSaveResult.Success -> {
          if (offerSaveResult.offer.state == Offer.State.DRAFT) {
            postInput(Inputs.DraftOfferUpdated(offerSaveResult.offer, s.contacts))
          } else {
            if (actionType == OfferActionType.ACCEPT) {
              sellerListingRepo.invalidateCacheAndFetchListings()
              sellerOfferRepo.invalidateCacheAndRefreshOffers()
            }

            updateState { it.copy(loadingState = LoadingState.READY) }
            userRepo.queuePopup(r.second)
            postEvent(Events.OnNavigate(s.navButton.data.route))
          }
        }
        is OfferSaveResult.InvalidOffer -> {
          postInput(
            Inputs.SetError(
              "The offer has the following invalid fields: " +
                "${offerSaveResult.invalidFields.joinToString(", ") { it.field }}." +
                " ${CommonMessages.contactAdministrator}"
            )
          )
        }
        is OfferSaveResult.NoChange -> {
          updateState { it.copy(loadingState = LoadingState.READY) }
          userRepo.queuePopup(r.second)
          postEvent(Events.OnNavigate(s.navButton.data.route))
        }
        is OfferSaveResult.ListingInvalidState -> {
          if (actionType == OfferActionType.ACCEPT && offerSaveResult.state == Listing.State.LOCKED) {
            postInput(
              Inputs.SetError(
                "The listing is locked. Another offer may already have been accepted."
              )
            )
            sellerListingRepo.invalidateCacheAndFetchListings()
            sellerOfferRepo.invalidateCacheAndRefreshOffers()
          } else {
            Logger.e(offerSaveResult.message)
            postInput(Inputs.SetError(userErrorMsg()))
          }
        }
        else -> {
          Logger.e(offerSaveResult.message)
          postInput(Inputs.SetError(userErrorMsg()))
        }
      }
    }
  }

  @Suppress("ComplexMethod")
  private fun Offer.canSubmit(forParty: Party): Boolean {
    val stage = negotiationStageOf(this)

    // To publish an offer, all terms must have a negotiated state
    return price?.price?.canSubmit(forParty, stage) == true &&
      price?.deposit?.canSubmit(forParty, stage) == true &&
      closing?.date?.canSubmit(forParty, stage) == true &&
      assumableContracts?.contracts?.all { it.canSubmit(forParty, stage) } == true &&
      fixturesExcluded?.fixturesExcluded?.all { it.canSubmit(forParty, stage) } == true &&
      chattelsIncluded?.chattelsIncluded?.all { it.canSubmit(forParty, stage) } == true &&
      additionalRequest?.additionalRequests?.all { it.canSubmit(forParty, stage) } == true &&
      bindingAgreementTerms?.days?.canSubmit(forParty, stage) == true &&
      sellerConditions?.conditions?.all { it.canSubmit(forParty, stage) } == true &&
      buyerInformation != null &&
      buyerConditions?.conditions?.all { it.canSubmit(forParty, stage) } == true &&
      listingDetailsAcknowledged &&
      expiry?.core?.duration != null &&
      expiry?.core?.setBy == forParty
  }
}
