package com.diyoffer.negotiation

import kotlin.Long
import kotlin.String

public object BuildConfig {
  public const val APP_NAME: String = "shared"

  public const val BUILD_VERSION: String = "1.0-SNAPSHOT"

  public const val GIT_VERSION_DESCRIBE: String = "3e9fca4"

  public const val GIT_VERSION: String = "3e9fca40769a7de0858f95d3e237dbbc52bd6989"

  public const val BUILD_TIME: Long = 1700152373290L
}
