package forms

import androidx.compose.runtime.Composable
import builders.ListingPropertyOwnersBuilder
import com.diyoffer.negotiation.common.removeAt
import com.diyoffer.negotiation.common.replaceAt
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.FlexColumn
import common.FlexRow
import common.RadioButton
import common.Row
import common.TextField
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import kotlinx.datetime.Clock
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet.greyText
import style.DiyStyleSheet.withGreenLeftBorder

@Suppress("LongMethod")
@Composable
fun ListingOwnersEdit(
  builder: ListingPropertyOwnersBuilder,
  setBuilder: (ListingPropertyOwnersBuilder) -> Unit,
) {
  Row({ classes(greyText) }) {
    Text(
      "Includes individuals listed on Title as well as a Spouse/Common-law Partner not listed on Title."
    )
  }

  builder.contacts?.mapIndexed { idx, contact ->
    if (idx > 0) Hr()
    FlexRow({
      style {
        justifyContent(JustifyContent.SpaceBetween)
        alignItems(AlignItems.FlexStart)
      }
    }) {
      FlexColumn({ classes(withGreenLeftBorder) }) {
        Row {
          TextField(opts = {
            label = "Full Name"
            value = contact.name
            onModified = {
              setBuilder(
                builder.copy(
                  contacts = builder.contacts!!.replaceAt(
                    idx,
                    builder.contacts!![idx].copy(name = it ?: "")
                  )
                )
              )
            }
          })
        }
        Row({ style { marginBottom(0.px) } }) {
          TextField(opts = {
            label = "Email"
            value = contact.firstEmailOrNull()
            onModified = {
              setBuilder(
                builder.copy(
                  contacts = builder.contacts!!.replaceAt(
                    idx,
                    builder.contacts!![idx].copy(
                      methods = listOf(
                        ContactMethod.Email(
                          email = it ?: "",
                          verified = Auditable.Core(Optional.of(false), Clock.System.now())
                        )
                      )
                    )
                  )
                )
              )
            }
          })
        }
      }
      MDCIconSpan(icon = MDCIcon.Delete) {
        style { cursor("pointer") }
        onClick { setBuilder(builder.copy(contacts = builder.contacts?.removeAt(idx))) }
      }
    }
  }

  AddMoreContactButton {
    val listContact = builder.contacts ?: listOf()
    setBuilder(
      builder.copy(
        contacts = listContact + listOf(
          Contact(
            name = "",
            methods = listOf(
              ContactMethod.Email("", Auditable.Core(Optional.of(false), Clock.System.now())),
            ),
          )
        )
      )
    )
  }

  FlexRow {
    val additionalText = if (builder.contacts != null && builder.contacts!!.size > 1) "one of " else ""
    RadioButton({
      label = "I certify that I am ${additionalText}the property owner listed above, " +
        "or that I have full legal authority to represent the property owners " +
        "e.g. Power of attorney, seller agent, or direct family member of the seller."
      value = builder.userCertifiedLegalAuthority
      onModified = { setBuilder(builder.copy(userCertifiedLegalAuthority = it)) }
    })
  }
}

@Composable
fun ListingOwnersDisplay(item: ListingPropertyOwners) {
  item.contacts.map {
    FlatList(
      it.name,
      it.methods.joinToString(", ") { method ->
        when (method) {
          is ContactMethod.Email -> "Email: ${method.email}"
          is ContactMethod.Sms -> "Sms: ${method.telephone}"
        }
      },
    )
  }
}
