package com.diyoffer.negotiation.environment

import io.ktor.http.*

const val NAKED_PROD_HOST = "diyoffer.com"
const val NAKED_TEST_HOST = "test.diyoffer.com"

val productionHosts = setOf(
  NAKED_PROD_HOST,
  "buy.diyoffer.com",
  "sell.diyoffer.com",
)

val testHosts = setOf(
  NAKED_TEST_HOST,
  "buy-test.diyoffer.com",
  "sell-test.diyoffer.com",
)

fun URLBuilder.isHosted() = isHosted(host)

fun URLBuilder.isNakedProd() = isNakedProd(host)

fun URLBuilder.isNakedTest() = isNakedTest(host)

fun Url.isHosted() = isHosted(host)

fun Url.isNakedProd() = isNakedProd(host)

fun Url.isNakedTest() = isNakedTest(host)

fun isHosted(domain: String) = domain.endsWith(".diyoffer.com")

fun isNakedProd(domain: String) = domain == NAKED_PROD_HOST

fun isNakedTest(domain: String) = domain == NAKED_TEST_HOST
