package forms

import androidx.compose.runtime.Composable
import builders.ListingPropertyDetailsBuilder
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.PropertyOwnership.Type.Companion.taxRollOptionality
import common.FlatList
import common.FlexRow
import common.HelpChip
import common.MoneyField
import common.NumberField
import common.RadioButton
import common.Row
import common.TextField
import components.AddressInput
import components.DiySelect
import org.jetbrains.compose.web.css.px

@Suppress("LongMethod")
@Composable
fun ListingPropertyDetailsEdit(
  readonly: Boolean,
  builder: ListingPropertyDetailsBuilder,
  setBuilder: (ListingPropertyDetailsBuilder) -> Unit,
) {
  AddressInput(
    disabled = readonly,
    builder = builder.address,
    setBuilder = { setBuilder(builder.copy(address = it)) }
  )

  Row {
    DiySelect(
      label = "Property ownership",
      disabled = readonly,
      items = PropertyOwnership.Type.values().toList(),
      initialSelected = builder.propertyOwnershipType,
      renderer = { it.title },
    ) {
      setBuilder(builder.copy(propertyOwnershipType = it))
    }
  }

  FlexRow {
    MoneyField(opts = {
      label = "Monthly fees (if applicable)"
      value = builder.monthlyFees
      disabled = readonly
      onModified = { setBuilder(builder.copy(monthlyFees = it)) }
    }, currency = builder.jurisdiction.country.currency)
    if (!readonly) {
      HelpChip("listing-monthly-fees", 22.px)
    }
  }

  FlexRow {
    MoneyField(opts = {
      label = "Taxes paid last year"
      value = builder.taxAmount
      disabled = readonly
      onModified = { setBuilder(builder.copy(taxAmount = it)) }
    }, currency = builder.jurisdiction.currency)

    NumberField(opts = {
      label = "Year these taxes were paid"
      value = builder.taxYear
      disabled = readonly
      onModified = { setBuilder(builder.copy(taxYear = it)) }
    })

    TextField(opts = {
      label = "Tax roll number"
      value = if (readonly) {
        if (builder.rollNumber != null) "<at offer acceptance>" else "<not available>"
      } else {
        builder.rollNumber
      }
      disabled = readonly || builder.propertyOwnershipType?.taxRollOptionality() == FieldOptionality.UNAVAILABLE
      onModified = { setBuilder(builder.copy(rollNumber = it)) }
    })
    if (!readonly) {
      HelpChip("listing-property-taxes", 22.px)
    }
  }

  if (builder.propertyOwnershipType?.isApartmentStyle == true) {
    FlexRow {
      TextField(opts = {
        label = "Available parking spot(s)"
        value = builder.parkingSpace
        disabled = readonly
        onModified = { setBuilder(builder.copy(parkingSpace = it)) }
      })

      TextField(opts = {
        label = "Available locker(s)"
        value = builder.lockers
        disabled = readonly
        onModified = { setBuilder(builder.copy(lockers = it)) }
      })
      if (!readonly) {
        HelpChip("listing-parking-lockers", 22.px)
      }
    }
  }

  RadioButton({
    label = "Is there an active mortgage on the property"
    value = builder.activeMortgage
    disabled = readonly
    onModified = { setBuilder(builder.copy(activeMortgage = it)) }
  })
}

@Composable
fun ListingPropertyDetailsDisplay(item: ListingPropertyDetails, currency: Currency, forParty: Party) {
  FlatList("${item.propertyOwnership.type.title} located at ${item.address.longSummary()}")
  FlatList(
    "Taxes of ${formatCurrency(item.propertyTaxes.amount, currency)} paid in ${item.propertyTaxes.year}"
  )
  if (forParty == Party.SELLER) {
    val rollNumber = item.propertyTaxes.rollNumber
    if (rollNumber != null) {
      FlatList(
        "Roll number: ${item.propertyTaxes.rollNumber} (not shown to potential buyers until after offer acceptance)"
      )
    }
  }
  (item.propertyOwnership.monthlyFees)?.let {
    FlatList(
      "Monthly fees of ${formatCurrency(it, currency)}",
    )
  }
  (item.propertyOwnership as? PropertyOwnership.ApartmentStyle)?.let {
    FlatList(
      it.parkingSpaces?.let { s -> "Parking Space: $s" } ?: "No parking space",
      it.lockers?.let { s -> "Locker: $s" } ?: "No locker",
    )
  }
  FlatList(
    "${if (item.activeMortgage) "With" else "Without"} an active mortgage"
  )
}
