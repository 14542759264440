package com.diyoffer.negotiation.auth

import com.diyoffer.negotiation.environment.Env
import com.diyoffer.negotiation.environment.environment
import com.diyoffer.negotiation.environment.isHosted
import dev.gitlive.firebase.FirebaseOptions
import kotlinx.browser.window

/**
 * Provides the Firebase options for the current environment. This potentially uses the window.location and is
 * therefore browser specific.
 */
class FirebaseAuthOptionsProvider {
  val firebaseOptions = when (environment) {
    Env.DEV,
    Env.TEST,
    -> FirebaseOptions(
      apiKey = "AIzaSyD2u0z7PH3Vlh1RemjJL9i7CSj0Xtrph34",
      authDomain = if (window.location.isHosted()) {
        "auth-test.diyoffer.com"
      } else {
        "diyop-test-1.firebaseapp.com"
      },
      projectId = "diyop-test-1",
      storageBucket = "diyop-test-1.appspot.com",
      gcmSenderId = "61879826950",
      applicationId = "1:61879826950:web:95150a20d850e5346c6187",
    )
    Env.PROD,
    -> FirebaseOptions(
      apiKey = "AIzaSyB58cWWtF1nLBRyK46EAxXEpWLZjWnTwOA",
      authDomain = "auth.diyoffer.com",
      projectId = "diyop-1",
      storageBucket = "diyop-1.appspot.com",
      gcmSenderId = "32882340506",
      applicationId = "1:32882340506:web:35145f3ac4122d5f60a523",
      gaTrackingId = "G-WLKYNZP7VW",
    )
  }
}
