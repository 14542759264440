@file:Suppress("MagicNumber")

package pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatDate
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.checklist.ChecklistDetailContract.Inputs
import com.diyoffer.negotiation.ui.checklist.ChecklistDetailContract.State
import com.diyoffer.negotiation.ui.checklist.ChecklistDetailEventHandler
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract
import com.diyoffer.negotiation.ui.offer.TagColor
import com.diyoffer.negotiation.ui.offer.getExpiryString
import com.diyoffer.negotiation.ui.offer.statusChip
import com.diyoffer.negotiation.ui.state.LoadingState
import common.IconName
import common.MessageInline
import common.Tag
import components.WithLabeledLoadingOverlay
import components.offer.OfferActionButtons
import components.offer.OfferNegotiationSummary
import components.snackbar.Snackbar
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import layout.FormContainer
import layout.Header
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberFactory
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.alignItemsStart
import style.GridStyleSheet.col3
import style.GridStyleSheet.col9
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.GridStyleSheet.justifyContentBetween
import style.GridStyleSheet.row
import vm.checklist.ChecklistDetailViewModel
import vm.checklist.ChecklistDetailVmConfig
import vm.checklist.ChecklistDetailVmConfigParams

@Suppress("UnusedPrivateMember")
@Composable
fun BuyerChecklistPage(offerId: Uid<Offer>) {
  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfigFactory by rememberFactory<ChecklistDetailVmConfigParams, ChecklistDetailVmConfig>()
  val vm = remember(scope) {
    ChecklistDetailViewModel(
      config = vmConfigFactory(
        ChecklistDetailVmConfigParams(initialInputs = { Inputs.InitializeForBuyer(offerId) })
      ),
      viewModelCoroutineScope = scope,
      eventHandler = ChecklistDetailEventHandler {
        router.navigate(it)
      }
    )
  }
  val state by vm.observeStates().collectAsState()

  if (state.loadingState == LoadingState.UNAUTHORIZED) {
    // For now, forward the user to the edit view which has a better handler for resending the secured link
    // At some point, we could refactor the handler (ie: SendNewLink)
    router.navigate("/offer/edit/$offerId")
    return
  }

  Snackbar(state.error) { vm.trySend(Inputs.SetError(null)) }

  Header { }

  FormContainer {
    ChecklistArea(ChecklistTabContract.Inputs.InitializeForBuyer(offerId))
    if (state.listing != null) ListingDetail(state)
    Hr {
      classes(DiyStyleSheet.hrDivider)
      style {
        property("margin", "20px 0px 20px")
        width(720.px)
      }
    }
    state.offer?.let {
      OfferDetail(vm, state)
    }
  }
}

@Composable
private fun ListingDetail(state: State) {
  val listing = state.listing ?: return

  Div({
    classes(flex, flexColumn, DiyStyleSheet.mainForm)
    style {
      marginTop(20.px)
      padding(24.px)
      backgroundColor(DiyStyleSheet.Colors.lightDark)
    }
  }) {
    Div({
      classes(flex, justifyContentBetween, alignItemsStart)
      style {
        marginBottom(34.px)
      }
    }) {
      ListingAddress(listing, state.sessionUser.timeZone())
    }

    Div({
      classes(flex)
      style { gap(48.px) }
    }) {
      if (listing.state == Listing.State.COMPLETED) {
        state.offer?.price?.price?.currentValue?.getOrNull()?.let {
          ListingPrice("Offer price", it, listing.currency)
        }
      } else {
        ListingPrice("Listing price", listing.details.price.core.value.get(), listing.currency)
        ListingPrice("Deposit required", listing.details.deposit.core.value.get(), listing.currency)
      }
    }
  }
}

@Composable
private fun ListingAddress(listing: Listing.Published, timeZone: TimeZone) {
  Div({
    classes(flex, flexColumn)
  }) {
    Span({
      style {
        color(DiyStyleSheet.Colors.darkGrey)
        fontSize(12.px)
        fontWeight(DiyStyleSheet.Weights.semiBold)
        marginBottom(5.px)
      }
    }) {
      val localDate = listing.events.first().timestamp.instant.toLocalDateTime(timeZone)
      Text("Listed on ${formatDate(localDate.date)}")
    }
    Span({
      style {
        color(DiyStyleSheet.Colors.darkBlue)
        fontSize(16.px)
        fontWeight(DiyStyleSheet.Weights.bold)
        marginBottom(12.px)
      }
    }) {
      Text(listing.propertyDetails.address.shortestSummary())
    }
    Div({
      classes(flex, alignItemsCenter)
      style { gap(8.px) }
    }) {
      Tag(listing.propertyDetails.address.city, IconName.LOCATION_LIGHT_BLUE, color = TagColor.BLUE)
      Span({
        style {
          fontWeight(DiyStyleSheet.Weights.semiBold)
          color(DiyStyleSheet.Colors.blue)
        }
      }) {
        Text(listing.propertyDetails.address.provinceState.name)
      }
    }
  }
}

@Composable
private fun ListingPrice(label: String, price: Money, currency: Currency) {
  Div({
    classes(flex, flexColumn)
    style { gap(8.px) }
  }) {
    Span({
      style { color(DiyStyleSheet.Colors.darkGrey) }
    }) {
      Text(label)
    }
    Span({
      style {
        fontSize(32.px)
        fontWeight(DiyStyleSheet.Weights.darkNormal)
        color(DiyStyleSheet.Colors.darkBlue)
      }
    }) {
      Text(formatCurrency(price, currency))
    }
  }
}

@Composable
private fun OfferDetail(vm: ChecklistDetailViewModel, state: State) {
  val offer = state.offer ?: return
  val listing = state.listing ?: return
  val seller = state.listing?.propertyOwners?.contacts?.first()?.name ?: ""
  val timeZone = state.sessionUser.timeZone()

  WithLabeledLoadingOverlay(loadingLabel = if (state.loadingState == LoadingState.FETCHING) "Saving offer" else null) {
    Div({
      classes(row, alignItemsStart, DiyStyleSheet.mainForm)
    }) {
      Div({
        classes(col9, flex, flexColumn)
      }) {
        OfferDetailInfoChip(state)
        ListingDate(offer, seller, timeZone)
        OfferStateTag(state, timeZone)
        Div({
          classes(flex, flexColumn)
          style { marginBottom(24.px) }
        }) {
          OfferNegotiationSummary(state.counterRejectList)
        }
        state.offer?.buyerInformation?.description?.core?.value?.getOrNull()?.let {
          Note(it)
        }
      }

      Div({
        classes(flex, flexColumn, col3)
        style { gap(12.px) }
      }) {
        OfferActionButtons(
          Party.BUYER,
          listing,
          offer,
          state.counterRejectList.isEmpty(),
        ) {
          vm.trySend(Inputs.Clicked(it))
        }
      }
    }
  }
}

@Composable
private fun OfferDetailInfoChip(state: State) {
  state.offerStatusLabel?.let { statusLabel ->
    Div({
      classes(flex)
      style {
        gap(5.px)
        marginBottom(24.px)
        fontSize(18.px)
      }
    }) {
      val (icon, color) = state.offer!!.state.icon()
      MessageInline(icon = icon, twoToneIconColorFilters = color) { Span { Text(statusLabel) } }
    }
  }
}

@Composable
private fun ListingDate(offer: Offer, seller: String, timeZone: TimeZone) {
  Div({
    classes(flex)
    style {
      marginBottom(24.px)
      gap(48.px)
    }
  }) {
    ListingDateItem(
      "OFFER DATE",
      formatDate(offer.events.first().timestamp.instant.toLocalDateTime(timeZone).date)
    )
    offer.getExpiryString(timeZone)?.let { ListingDateItem("DEADLINE", it) }
    ListingDateItem("SELLER", seller)
  }
}

@Composable
private fun ListingDateItem(label: String, value: String) {
  Div({
    classes(flex, flexColumn)
    style { gap(5.px) }
  }) {
    Span({
      style { color(Color("#969696")) }
    }) {
      Text(label)
    }
    Span({
      style { color(DiyStyleSheet.Colors.black) }
    }) {
      Text(value)
    }
  }
}

@Composable
fun OfferStateTag(state: State, timeZone: TimeZone) {
  val clock by rememberInstance<Clock>()

  state.offer?.let { offer ->
    val tag = offer.statusChip(state.withheldUntil, clock, timeZone)

    Tag(
      name = tag.first,
      color = tag.second,
    ) {
      style {
        marginBottom(24.px)
        property("width", "fit-content")
      }
    }
  }
}

@Composable
private fun Note(message: String) {
  Div({
    classes(flex, flexColumn)
    style {
      gap(8.px)
      padding(24.px)
      backgroundColor(DiyStyleSheet.Colors.lightDark)
    }
  }) {
    Span {
      Text("You")
    }
    Span({ style { color(DiyStyleSheet.Colors.darkGrey) } }) {
      Text(message)
    }
  }
}
