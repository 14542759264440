package forms

import androidx.compose.runtime.Composable
import builders.AssumableContractBuilder
import builders.AssumableContractListBuilder
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.Row
import components.OptionList
import kotlinx.datetime.TimeZone
import org.jetbrains.compose.web.css.paddingBottom
import style.DiyStyleSheet.Sizes.paddingXs

@Composable
fun ListingAssumableContractListEdit(
  builder: AssumableContractListBuilder,
  currency: Currency,
  timeZone: TimeZone,
  setBuilder: (AssumableContractListBuilder) -> Unit,
) {
  OptionList(
    itemBuilder = AssumableContractBuilder(timeZone, currency),
    opts = {
      value = builder.assumableContractList
      onModified = {
        setBuilder(builder.copy(assumableContractList = it))
      }
    },
    displayOpts = {
      subtitleLabel = "Click on Add a contract to disclose an assumable contract"
      addButtonLabel = "Add a Contract"
      listSectionLabel = "ListSelectionLabel"
    },
    displayContent = { AssumableContractDisplay(it, builder.currency) },
    editContent = { s, sb -> ListingAssumableContractEdit(builder.currency, s as AssumableContractBuilder, sb) }
  )
}

@Composable
fun ListingAssumableContractListDisplay(item: ListingAssumableContracts, currency: Currency) {
  item.contracts.let { contractList ->
    if (contractList.isEmpty()) {
      FlatList("No Assumable Contract. ${CommonMessages.clickCardToAddMore}")
    } else {
      contractList.mapIndexed { idx, contract ->
        contract.core.value.getOrNull()?.let {
          Row({ style { if (idx < contractList.size - 1) paddingBottom(paddingXs) } }) {
            AssumableContractDisplay(it, currency)
          }
        }
      }
    }
  }
}
