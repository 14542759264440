@file:Suppress("MagicNumber")

package pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.landing.GetAlertContract
import com.diyoffer.negotiation.ui.landing.GetAlertEventHandler
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract.State
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenEventHandler
import com.diyoffer.negotiation.ui.state.LoadingState
import common.ActionButton
import common.CancelButton
import common.ErrorMessage
import common.ErrorMessageContainer
import common.FlexColumn
import common.FlexRow
import common.Icon
import common.IconName
import common.Tag
import common.displayOverlay
import components.ChangeLoginStateButton
import components.DescriptionDisplay
import components.GetAlertDialog
import components.LinkDisplay
import components.Loading
import components.PriceDisplayColumn
import components.ReturnButton
import components.YourInformationSidebar
import kotlinx.browser.window
import layout.Header
import model.returnMetadataFromBackRoute
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexBasis
import org.jetbrains.compose.web.css.flexWrap
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.GridStyleSheet.alignItemsStart
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.GridStyleSheet.flexGrow
import style.GridStyleSheet.justifyContentBetween
import vm.landing.GetAlertViewModel
import vm.landing.GetAlertViewModelConfiguration
import vm.listing.ListingLandingScreenViewModel
import vm.listing.ListingLandingScreenViewModelConfiguration
import vm.login.UserViewModel

@Composable
@Suppress("LongMethod")
fun ListingLandingPage(permalink: String, userVm: UserViewModel, user: SessionUser, backRoute: String?) {
  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<ListingLandingScreenViewModelConfiguration>()
  val vm = remember(scope) {
    ListingLandingScreenViewModel(
      scope,
      vmConfig,
      ListingLandingScreenEventHandler()
    )
  }

  val state by vm.observeStates().collectAsState()

  LaunchedEffect(Unit) {
    vm.send(Inputs.FetchListing(permalink))
  }

  val returnMetadata = returnMetadataFromBackRoute(backRoute)

  Header {
    Button({
      classes(DiyStyleSheet.button, DiyStyleSheet.headerButtonLink)
      onClick { router.navigate("/home") }
    }) {
      Text("Home")
    }

    ChangeLoginStateButton(userVm, user)

    if (user.role == UserRole.SELLER) {
      ReturnButton(returnMetadata)
    } else {
      ActionButton(attrs = {
        style { padding(14.px, 24.px) }
        onClick {
          displayOverlay(true)
        }
      }) {
        Text("Make an Offer")
      }
    }
  }

  ErrorMessageContainer {
    when (state.loadingState) {
      LoadingState.FETCHING, LoadingState.NOT_LOADED -> Loading("Listing Loading")
      LoadingState.ERROR -> ErrorMessage(state.error)
      else -> ListingLandingPageView(state)
    }
  }
}

@Suppress("LongMethod")
@Composable
private fun ListingLandingPageView(state: State) {
  Div({
    classes(flex, justifyContentBetween)
    style { gap(64.px) }
  }) {
    Div({
      classes(flexColumn, flexGrow)
    }) {
      Div({ classes(flex, justifyContentBetween, alignItemsStart) }) {
        Div({ classes(flex, flexColumn) }) {
          state.name?.let { H2 { Text(it) } }
          state.address?.let { Address(it) }
        }
      }
      Price(state)
      state.description?.let {
        DescriptionDisplay("Description", it) {
          style { marginTop(48.px) }
        }
      }
      if (!state.links.isNullOrEmpty()) {
        Links(state.links!!)
      }
      state.details?.let {
        Details(it)
      }
      state.thingsYouNeedToKnow?.let {
        ThingYouNeedToKnow(it)
      }
    }

    YourInformationSidebar(state)
  }
}

@Composable
fun Address(address: Address) {
  FlexRow {
    Tag(
      address.city,
      IconName.LOCATION_YELLOW
    )
    Span({
      style {
        color(DiyStyleSheet.Colors.yellow)
        fontWeight(500)
      }
    }) {
      Text(address.provinceState.name)
    }
  }
}

@Composable
fun Price(state: State) {
  Div({
    style {
      marginTop(64.px)
      padding(24.px)
      backgroundColor(DiyStyleSheet.Colors.lightDark)
    }
  }) {
    FlexRow {
      if (state.price != null && state.currency != null) {
        PriceDisplayColumn("Listing price", state.price!!, state.currency!!)
      }
      if (state.deposit != null && state.currency != null && !state.isCompleted) {
        PriceDisplayColumn("Deposit required", state.deposit!!, state.currency!!)
      }
    }
    if (state.permalink != null && !state.isCompleted) {
      LinkDisplay("Copy URL", state.permalink!!)
    }
  }
}

@Composable
fun Links(links: List<ListingLinks.Link>) {
  FlexColumn({
    style {
      marginTop(48.px)
      gap(24.px)
    }
  }) {
    Span({
      style { fontWeight(500) }
    }) {
      Text("Review the Property")
    }
    FlexColumn({
      style { gap(16.px) }
    }) {
      links.forEach {
        LinkDisplay(it.url.split("?")[0], it.url, false, it.provider)
      }
    }
  }
}

@Composable
fun Details(details: List<ListingLandingDetail>) {
  if (details.isEmpty()) return
  FlexColumn({
    style {
      marginTop(48.px)
      gap(24.px)
    }
  }) {
    Span({
      style { fontWeight(500) }
    }) {
      Text("Details")
    }
    Div({
      style {
        display(DisplayStyle.Flex)
        flexWrap(FlexWrap.Wrap)
        gap(12.px)
      }
    }) {
      details.forEach {
        DetailItem(it.name, it.description)
      }
    }
  }
}

@Composable
fun DetailItem(name: String, description: String) {
  Div({
    style {
      display(DisplayStyle.Flex)
      alignItems(AlignItems.FlexStart)
      gap(12.px)
      flexBasis("30%")
      marginBottom(20.px)
    }
  }) {
    Icon(IconName.BULLET_POINT_BLUE)
    DescriptionDisplay(name, description)
  }
}

@Composable
fun ThingYouNeedToKnow(thingsYouNeedToKNow: List<String>) {
  if (thingsYouNeedToKNow.isEmpty()) return
  FlexColumn({
    style {
      marginTop(48.px)
      gap(24.px)
    }
  }) {
    Span({
      style { fontWeight(500) }
    }) {
      Text("Think you need to know")
    }
    Div({
      style {
        display(DisplayStyle.Flex)
        flexWrap(FlexWrap.Wrap)
        gap(12.px)
      }
    }) {
      thingsYouNeedToKNow.forEach {
        ThinkYouNeedToKnowItem(it)
      }
    }
  }
}

@Composable
fun ThinkYouNeedToKnowItem(description: String) {
  Div({
    style {
      display(DisplayStyle.Flex)
      alignItems(AlignItems.Center)
      flexBasis("48%")
      gap(12.px)
      marginBottom(4.px)
    }
  }) {
    Icon(IconName.BULLET_POINT_BLUE)
    Span({
      style {
        color(DiyStyleSheet.Colors.darkGrey)
      }
    }) {
      Text(description)
    }
  }
}

@Suppress("UnusedPrivateMember")
@Composable
private fun GetAlertButton() {
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<GetAlertViewModelConfiguration>()
  val vm = remember(scope) {
    GetAlertViewModel(
      scope,
      vmConfig,
      GetAlertEventHandler(
        onError = { window.alert(it) } // Use https://github.com/innertech/diyoffer/issues/190 when merged in
      )
    )
  }
  val state = vm.observeStates().collectAsState()

  CancelButton({
    style { margin(18.px, 0.px) }
    onClick { vm.trySend(GetAlertContract.Inputs.PopupOpen) }
  }) {
    Icon(IconName.BELL_RED) { style { marginRight(5.px) } }
    Text("Get alert")
  }
  GetAlertDialog(state.value, vm)
}
