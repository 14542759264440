package forms

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import builders.MoneyBuilder
import builders.NegotiatedTermBuilder
import builders.OfferPriceBuilder
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatPercent
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.offer.HST
import com.diyoffer.negotiation.ui.offer.ONE_HUNDRED
import com.diyoffer.negotiation.ui.offer.calculateSummaryByOfferAmount
import com.diyoffer.negotiation.ui.offer.effectivePrice
import common.GridLayout
import common.Icon
import common.IconName
import common.MoneyField
import components.NegotiatedTermDisplay
import components.NegotiatedTermEdit
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.padding
import style.DiyStyleSheet.fieldLabel12
import style.DiyStyleSheet.fieldLabel20
import style.GridStyleSheet.col12
import style.GridStyleSheet.col4
import style.GridStyleSheet.col8
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.GridStyleSheet.justifyContentBetween
import style.GridStyleSheet.justifyContentEnd
import style.GridStyleSheet.mb16
import style.GridStyleSheet.textRight

@Composable
fun OfferPriceEdit(
  offer: Offer,
  jurisdiction: Jurisdiction?,
  builder: OfferPriceBuilder,
  setBuilder: (OfferPriceBuilder) -> Unit,
) {
  builder.price?.let { priceBuilder ->
    GridLayout(
      rightPanelContent = {
        NegotiatedTermEdit(
          builder = priceBuilder,
          setBuilder = { setBuilder(builder.copy(price = it)) }
        )
      }
    ) {
      OfferPriceNegotiate(offer, jurisdiction, priceBuilder) { setBuilder(builder.copy(price = it)) }
    }
  }

  builder.deposit?.let { depositBuilder ->
    builder.price?.let { Hr { style { marginBottom(padding) } } }
    GridLayout(
      rightPanelContent = {
        NegotiatedTermEdit(
          builder = depositBuilder,
          setBuilder = { setBuilder(builder.copy(deposit = it)) }
        )
      }
    ) {
      OfferDepositNegotiate(offer, depositBuilder) { setBuilder(builder.copy(deposit = it)) }
    }
  }
}

enum class OfferPriceEditFocus {
  EDIT_OFFER_PRICE,
  EDIT_EFFECTIVE_OFFER_PRICE,
}

@Composable
@Suppress("LongMethod")
private fun OfferPriceNegotiate(
  offer: Offer,
  jurisdiction: Jurisdiction?,
  priceBuilder: NegotiatedTermBuilder<Money>,
  setPriceBuilder: (NegotiatedTermBuilder<Money>) -> Unit,
) {
  val currency = offer.currency
  val commission = offer.buyerAgent?.commission?.valueOrNull()?.commission?.value?.let { it / ONE_HUNDRED }
  val listingPrice = priceBuilder.getBaselineValue()
  val (offerAmount, setOfferAmount) = remember { mutableStateOf(priceBuilder.getEffectiveValue()) }
  val editMode = priceBuilder.state == NegotiatedTerm.State.COUNTERED
  val (focus, setFocus) = remember { mutableStateOf(OfferPriceEditFocus.EDIT_OFFER_PRICE) }

  LaunchedEffect(priceBuilder) {
    setOfferAmount(priceBuilder.getEffectiveValue())
  }

  MoneyEditArea(
    baseAmount = listingPrice,
    amount = offerAmount,
    editMode = editMode,
    currency = offer.currency,
    label = "Offer Price",
    subTitle = if (listingPrice != offerAmount) {
      "(Listing Price is ${formatCurrency(listingPrice, currency)} ${currency.name})"
    } else {
      null
    },
    builder = priceBuilder,
    setBuilder = setPriceBuilder
  )

  if (commission != null && commission > 0.0) {
    val summary = calculateSummaryByOfferAmount(commission, offerAmount, jurisdiction)

    CommissionView(commission, summary.buyerCommissionFee, currency)
    if (jurisdiction?.provinceState == ProvinceState.ON) {
      HstView(summary.hst, currency)
      Divided(false)
      SubTotalView(summary.subTotal, currency)
    }

    Divided()

    Div({
      classes(flex, mb16)
      style { color(DiyStyleSheet.Colors.black) }
    }) {
      Div({ classes(col8) }) {
        Span({ style { fontSize(16.px) } }) {
          Text("Your Effective Offer Price:")
        }
      }
      Div({
        classes(flex, col4, textRight)
        style { fontSize(22.px) }
        if (editMode) onClick { setFocus(OfferPriceEditFocus.EDIT_EFFECTIVE_OFFER_PRICE) }
      }) {
        Icon(IconName.EQUAL_CIRCLE) { style { width(14.px) } }
        if (
          editMode &&
          (focus == OfferPriceEditFocus.EDIT_EFFECTIVE_OFFER_PRICE || focus == OfferPriceEditFocus.EDIT_OFFER_PRICE)
        ) {
          MoneyField(currency, {
            value = summary.effectiveOfferPrice
            onModified = {
              it?.let {
                setPriceBuilder(
                  priceBuilder.copy(
                    counterValueBuilder = MoneyBuilder(calculateOfferAmount(commission, it, jurisdiction))
                  )
                )
              }
            }
          })
        } else {
          Text("${formatCurrency(summary.effectiveOfferPrice, currency)} ${currency.name}")
        }
      }
    }
  }
}

@Composable
fun OfferDepositNegotiate(
  offer: Offer,
  depositBuilder: NegotiatedTermBuilder<Money>,
  setDepositBuilder: (NegotiatedTermBuilder<Money>) -> Unit,
) {
  val currency = offer.currency
  val listingDeposit = depositBuilder.getBaselineValue()
  val (depositAmount, setDepositAmount) = remember { mutableStateOf(depositBuilder.getEffectiveValue()) }

  LaunchedEffect(depositBuilder) {
    setDepositAmount(depositBuilder.getEffectiveValue())
  }

  MoneyEditArea(
    baseAmount = listingDeposit,
    amount = depositAmount,
    editMode = depositBuilder.state == NegotiatedTerm.State.COUNTERED,
    currency = offer.currency,
    label = "Deposit Amount",
    subTitle = if (listingDeposit != depositAmount) {
      "(Listing Deposit is ${formatCurrency(listingDeposit, currency)} ${currency.name})"
    } else {
      null
    },
    builder = depositBuilder,
    setBuilder = setDepositBuilder
  )
}

@Suppress("LongParameterList")
@Composable
private fun MoneyEditArea(
  baseAmount: Money,
  amount: Money,
  editMode: Boolean,
  currency: Currency,
  label: String,
  subTitle: String?,
  builder: NegotiatedTermBuilder<Money>,
  setBuilder: (NegotiatedTermBuilder<Money>) -> Unit,
) {
  val (focus, setFocus) = remember { mutableStateOf(OfferPriceEditFocus.EDIT_OFFER_PRICE) }

  Div({
    classes(flex, mb16)
    style { color(DiyStyleSheet.Colors.black) }
    if (editMode) onClick { setFocus(OfferPriceEditFocus.EDIT_OFFER_PRICE) }
  }) {
    Div({
      classes(flex, flexColumn, col8)
    }) {
      Span({ classes(fieldLabel20) }) { Text(label) }
      subTitle?.let {
        Span({
          classes(fieldLabel12)
          style { marginBottom(32.px) }
        }) { Text(it) }
      }
    }
    Div({
      classes(flex, col4, textRight)
      style { fontSize(22.px) }
      if (editMode) onClick { setFocus(OfferPriceEditFocus.EDIT_OFFER_PRICE) }
    }) {
      if (editMode && focus == OfferPriceEditFocus.EDIT_OFFER_PRICE) {
        MoneyField(currency, {
          value = amount
          onModified = {
            val state = if (it == baseAmount) NegotiatedTerm.State.ACCEPTED else builder.state
            setBuilder(builder.copy(counterValueBuilder = MoneyBuilder(it), state = state))
          }
        })
      } else {
        Text("${formatCurrency(amount, currency)} ${currency.name}")
      }
    }
  }
}

@Composable
private fun CommissionView(commission: Double, buyerCommissionFee: Money, currency: Currency) {
  Div({
    classes(flex, mb16)
    style { color(DiyStyleSheet.Colors.black) }
  }) {
    Div({
      classes(flex, flexColumn, col8)
    }) {
      Span {
        Text("Buyer Agent Commission Fee:\t\t")
        Span({
          style { color(DiyStyleSheet.Colors.yellow) }
        }) {
          Text(formatPercent(Percent(commission * ONE_HUNDRED)))
        }
      }
      Span({
        style {
          fontSize(12.px)
          color(DiyStyleSheet.Colors.grey)
        }
      }) {
        Text("You can edit this information in the\t\t")
        Span({
          style {
            fontSize(12.px)
            color(DiyStyleSheet.Colors.blue)
            fontWeight(
              DiyStyleSheet.Weights.darkNormal
            )
          }
        }) {
          Text("previous step.")
        }
      }
    }
    Div({
      classes(flex, col4, justifyContentEnd)
    }) {
      Text("${formatCurrency(buyerCommissionFee, currency = currency)} ${currency.name}")
    }
  }
}

@Composable
private fun HstView(hst: Money, currency: Currency) {
  Div({
    classes(flex)
    style { color(DiyStyleSheet.Colors.black) }
  }) {
    Div({
      classes(flex, flexColumn, col8)
    }) {
      Text("13% HST on Commission")
    }
    Div({
      classes(flex, col4, justifyContentEnd)
    }) {
      Text("${formatCurrency(hst, currency)} ${currency.name}")
    }
  }
}

@Composable
private fun SubTotalView(subTotal: Money, currency: Currency) {
  Div({
    classes(flex)
    style { color(DiyStyleSheet.Colors.black) }
  }) {
    Div({ classes(col8) }) {
      Text("Seller's Commission Fees and Taxes")
    }
    Div({ classes(flex, col4, justifyContentBetween) }) {
      Icon(IconName.MINUS_CIRCLE)
      Text("${formatCurrency(subTotal, currency)} ${currency.name}")
    }
  }
}

@Composable
private fun Divided(full: Boolean = true) {
  if (!full) {
    Div({
      classes(flex)
    }) {
      Div({ classes(col8) }) {}
      Div({ classes(col4) }) {
        Hr()
      }
    }
  } else {
    Div({
      classes(flex)
    }) {
      Div({ classes(col12) }) {
        Hr()
      }
    }
  }
}

@Composable
fun OfferPriceDisplay(offer: Offer, jurisdiction: Jurisdiction?) {
  val effectivePrice = offer.effectivePrice(jurisdiction)
  offer.price?.price?.currentValue?.getOrNull()?.let { price ->
    val effectivePriceStr = if (effectivePrice != null && effectivePrice != price) {
      " (Effective Price: ${formatCurrency(effectivePrice, offer.currency)})"
    } else {
      ""
    }
    NegotiatedTermDisplay(
      "Price: ${formatCurrency(price, offer.currency)}$effectivePriceStr",
      offer.price!!.price.state
    )
  }

  offer.price?.deposit?.currentValue?.getOrNull()?.let { deposit ->
    NegotiatedTermDisplay(
      "Deposit: ${formatCurrency(deposit, offer.currency)}",
      offer.price!!.deposit.state
    )
  }
}

private fun calculateOfferAmount(commissionFee: Double, effectiveOfferAmount: Money, jurisdiction: Jurisdiction?) =
  if (jurisdiction?.provinceState == ProvinceState.ON) {
    effectiveOfferAmount / (1 - commissionFee * (1 + HST))
  } else {
    effectiveOfferAmount / (1 - commissionFee)
  }
