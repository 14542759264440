package components

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.Inputs
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.OfferContactUI
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.VerifyingState
import common.ActionButton
import common.Checkbox
import common.FlexColumn
import common.FlexRow
import common.Icon
import common.IconName
import common.TextField
import common.VerifyTextField
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.lineHeight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.flex
import vm.offer.OfferContactsViewModel

@Composable
fun EmailContactEdit(
  index: Int,
  vm: OfferContactsViewModel,
  contactUI: OfferContactUI,
) {
  EmailContactView(index, vm, contactUI)
  if (contactUI.verifiedState != VerifyingState.VERIFIED) {
    VerifyButton(index, vm, contactUI)
  }
}

@Composable
private fun EmailContactView(index: Int, vm: OfferContactsViewModel, contactUI: OfferContactUI) {
  FlexRow {
    Div({
      style {
        width(80.px)
        lineHeight(100.percent)
        property("margin", "auto 0px")
      }
    }) {
      Checkbox(
        checked = true,
      ) {
        Text("Email")
      }
    }

    FlexColumn {
      VerifyTextField(
        opts = {
          label = "Email"
          value = contactUI.email
          onModified = { vm.trySend(Inputs.UpdateEmail(index, it)) }
        },
        verified = contactUI.verifiedState == VerifyingState.VERIFIED
      )
    }
  }
}

@Composable
private fun VerifyButton(contactIdx: Int, vm: OfferContactsViewModel, contactUI: OfferContactUI) {
  FlexRow {
    Div({ style { width(80.px) } })
    Div {
      FlexRow({ style { gap(5.px) } }) {
        Icon(IconName.INFO)
        Span({
          style {
            color(DiyStyleSheet.Colors.darkGrey)
            fontSize(12.px)
          }
        }) {
          Text(
            "In order to submit an offer, your email must be verified."
          )
        }
      }
      if (contactUI.verifiedState == VerifyingState.VERIFYING) {
        FlexColumn { VerifyingEmailInput(contactIdx, vm, contactUI) }
      }
      Div({
        classes(flex, alignItemsCenter)
        style { gap(16.px) }
      }) {
        ActionButton(
          disabled = contactUI.error != null,
          attrs = {
            onClick {
              it.stopPropagation()
              vm.trySend(Inputs.VerifyClicked(contactIdx))
            }
          }
        ) {
          Text(if (contactUI.verifiedState == VerifyingState.UNVERIFIED) "Send Code" else "Verify")
        }
        if (contactUI.verifiedState == VerifyingState.VERIFYING) {
          Span {
            Text("Don’t see the code? ")
            A(attrs = {
              onClick { vm.trySend(Inputs.ResendClicked(contactIdx)) }
            }) {
              Text("Resend")
            }
          }
        }
      }
    }
  }
}

@Composable
private fun VerifyingEmailInput(index: Int, vm: OfferContactsViewModel, contactUI: OfferContactUI) {
  FlexRow {
    Span({ style { color(DiyStyleSheet.Colors.black) } }) {
      Text("We have sent the verification code to your email. Please enter below:")
    }
  }
  FlexRow({ style { width(400.px) } }) {
    TextField(opts = {
      label = "Code"
      value = contactUI.code
      onModified = {
        vm.trySend(Inputs.UpdateCodeChar(index, it))
      }
    })
  }
}
